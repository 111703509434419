export class User{
    constructor(
        public _id: string,
        public name: string,
        public surname: string,
        public email: string,
        public password: string,
        public role: string,
        public reset_password: Boolean,
        public token: String,
        public img: String,
        public phone: String,
        public createdAt: String,
        public status: String
    ){}
}