import {Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GLOBAL } from '../../../../../services/global';
import { Company } from '../models/company';

@Injectable()
export class CompanyService{
    public url:string;
    public identity:any;
    public token:any;
 

    constructor(public _http: HttpClient){
        this.url = GLOBAL.url;
    }


    getCompanies(token:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

                                       
        return this._http.get(this.url+'contact-companies/', {headers: headers});
    }

    getCompany(addressType:any, token:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

                                       
        return this._http.get(this.url+'contact-company/'+addressType, {headers: headers});
    }

    saveCompany(addressType:any,token:any ): Observable<any>{
        
        let params = JSON.stringify(addressType);
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                       .set('Authorization',token);

        return this._http.post(this.url+'contact-company',params, {headers:headers});
    }

    updateCompany(addressType:any,token:any ): Observable<any>{
        
        let params = JSON.stringify(addressType);
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                       .set('Authorization',token);

        return this._http.put(this.url+'contact-company/'+addressType._id,params, {headers:headers});
    }

    deleteCompany(addressType:any,token:any ): Observable<any>{
        
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                        .set('Authorization',token);

        return this._http.delete(this.url+'contact-company/'+addressType, {headers:headers});
    }

    activeCompany(addressType:any,token:any ): Observable<any>{
        
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                       .set('Authorization',token);

        return this._http.post(this.url+'contact-company'+addressType._id, {headers:headers});
    }

}