export class PropertyModel{
    constructor(
        public _id: String,
        public propertyID: String,
        public modelSrc: String,
        public imgThumbnail:String,
        public modelName: String,
        public modelDesc: String,
        public modelPrice: Number,
        public modelDorms: Number,
        public modelBaths: Number,
        public modelParkingSlots: Number,
        public modelDate: String,
        public modelVisibility: Number,
        public status: String
    ){}
}