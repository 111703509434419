import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { User } from '../../../../users/models/user';
import { UserService } from '../../../../users/services/user.service';
import { Property } from '../../../models/property';
import { PropertyService } from  '../../../services/property.service'
import { PropertyTypeService } from  '../../../../catalogues/components/propertyTypes/services/propertyTypes.service';
import { PropertyType } from  '../../../../catalogues/components/propertyTypes/models/propertyType'
import { GLOBAL } from '../../../../../services/global';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

import { PropertySearchService } from '../../../services/property-search.service';
// import { PropertyToSearch } from '../../../models/propertyToSearch';
import { PropertyCatalogueState } from '../../../models/propertyCatalogueState';
import { PropertyCatalogueType } from '../../../models/propertyCatalogueType';
import { PropertyCatalogueLocation } from '../../../models/propertyCatalogueLocation';

@Component({
  selector: 'app-properties-archive',
  templateUrl: './properties-archive.component.html',
  styleUrls: ['./properties-archive.component.scss'],
  providers: [UserService, PropertyService, PropertyTypeService,PropertySearchService]
})
export class PropertiesArchiveComponent implements OnInit {
  @ViewChild('closebutton2') closebutton2;
    @ViewChild('closebutton3') closebutton3;

    @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
//   dtOptions: DataTables.Settings = {};
  isDtInitialized:boolean = false;
  dtTrigger: Subject<any> = new Subject();

  public title: string;
    public propertyToUpdate: User;
    public propertyToDelete: User;
    public data: Property[];
    public propertyTypes: PropertyType[];
    public users: User[];
    public url: String;
    public identity;
    public token;
    public statusCreate: string;
    public statusEdit: string;
    public statusDelete: string;
    public statusGetProperties: string;
    public statusGetProperty: string;

    // public propertyToSearch: PropertyToSearch;
    public propertyCatalogueStates: PropertyCatalogueState[];
    public propertyCatalogueTypes: PropertyCatalogueType[];
    public propertyCatalogueLocations: PropertyCatalogueLocation[];
    public statusGetStates: string;
    public statusGetLocations: string;
    public statusGetCatalogueTypes: string;
    public statusGetSearchProperties: string;

    constructor(
      private _route: ActivatedRoute,
      private _router: Router,
      private _userService: UserService,
      private _propertyService: PropertyService,
      private _propertyTypeService: PropertyTypeService,
      private _PropertySearchService: PropertySearchService,
  ){
      this.title = 'Listado de Usuarios';
      this.url = GLOBAL.url;
      this.identity = this._userService.getIdentity();
      this.token = this._userService.getToken();
      // this.propertyToSearch = new PropertyToSearch(0, '', '', '');
  }

  ngOnInit(): void {
    this.getProperties(false);
    this.getPropertyTypes();
    this.getLocations();
    this.getStates();
    this.getTypesCatalogue();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

getProperties(updated:boolean){
    this._propertyService.getProperties(this.token).subscribe(
        response => {
            if(!response.data){
                this.statusGetProperties = 'error';
            }else{
                this.data = response.data.filter(
                  (p) =>
                    p.propertyStatus &&
                    p.propertyStatus.name &&
                    ['alquilado', 'vendido', 'fuerademercado'].includes(
                      p.propertyStatus.name
                    )
                );
                 
                if(updated){
                    this.rerender();
                    this.ngAfterViewInit();
                    setTimeout(()=>{                          
                        $('#datatableexample').DataTable( {
                          dom: 'lpftrip',
                          pagingType: 'full_numbers',
                          pageLength: 5,
                          processing: true,
                          searching: false,
                          lengthMenu : [5, 10, 25],
                          language: {
                            processing: "Procesando...",
                            search: "Buscar:",
                            lengthMenu: "Mostrar _MENU_ &eacute;l&eacute;elementos",
                            info: "Mostrando desde _START_ al _END_ de _TOTAL_ elementos",
                            infoEmpty: "Mostrando ningún elemento.",
                            infoFiltered: "(filtrado _MAX_ elementos total)",
                            infoPostFix: "",
                            loadingRecords: "Cargando registros...",
                            zeroRecords: "No se encontraron registros",
                            emptyTable: "No hay datos disponibles en la tabla",
                            paginate: {
                              first: "Primero",
                              previous: "Anterior",
                              next: "Siguiente",
                              last: "Último"
                            },
                            aria: {
                              sortAscending: ": Activar para ordenar la tabla en orden ascendente",
                              sortDescending: ": Activar para ordenar la tabla en orden descendente"
                            }}
                      } );
                      }, 1);
                }else{
                    setTimeout(()=>{                          
                        $('#datatableexample').DataTable( {
                          dom: 'lpftrip',
                          pagingType: 'full_numbers',
                          pageLength: 20,
                          processing: true,
                          lengthMenu : [20, 50, 100],
                          language: {
                            processing: "Procesando...",
                            search: "Buscar:",
                            lengthMenu: "Mostrar _MENU_ elementos",
                            info: "Mostrando desde _START_ al _END_ de _TOTAL_ elementos",
                            infoEmpty: "Mostrando ningún elemento.",
                            infoFiltered: "(filtrado _MAX_ elementos total)",
                            infoPostFix: "",
                            loadingRecords: "Cargando registros...",
                            zeroRecords: "No se encontraron registros",
                            emptyTable: "No hay datos disponibles en la tabla",
                            paginate: {
                              first: "Primero",
                              previous: "Anterior",
                              next: "Siguiente",
                              last: "Último"
                            },
                            aria: {
                              sortAscending: ": Activar para ordenar la tabla en orden ascendente",
                              sortDescending: ": Activar para ordenar la tabla en orden descendente"
                            }}
                      } );
                      }, 1);
                }
                
                
                    

                //   this.rerender();
                //  this.ngOnInit();

                //   if (this.isDtInitialized) {
                //     this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                //         dtInstance.destroy();
                //         this.dtTrigger.next();
                //     });
                // } else {
                //     this.isDtInitialized = true;
                //     this.dtTrigger.next();
                // }

            }
        },
        error => {
            var errorMessage = <any>error;
            //console.log(errorMessage);

            if(errorMessage != null){
                this.statusGetProperties = 'error';
            }
        }
    );
}

getPropertyTypes(){
    this._propertyTypeService.getPropertyTypes(this.token).subscribe(
        response => {
            if(!response.data){
                this.statusGetProperties = 'error';
            }else{
                this.propertyTypes = response.data;
                //console.log(this.propertyTypes);
            }
        },
        error => {
            var errorMessage = <any>error;
            //console.log(errorMessage);

            if(errorMessage != null){
                this.statusGetProperties = 'error';
            }
        }
    );
}

getStates() {
    this._PropertySearchService.getPropretiesStates(this.token).subscribe(
      (response) => {
        if (!Array.isArray(response)) {
          this.statusGetStates = 'error';
        } else {
          this.propertyCatalogueStates = response;
          this.statusGetStates = '';
        }
      },
      (error) => {
        var errorMessage = <any>error;
        console.log(errorMessage);

        if (errorMessage != null) {
          this.statusGetStates = 'error';
        }
      }
    );
}

getTypesCatalogue() {
    this._PropertySearchService.getPropretiesTypes(this.token).subscribe(
      (response) => {
        if (!Array.isArray(response)) {
          this.statusGetCatalogueTypes = 'error';
        } else {
          this.propertyCatalogueTypes = response;
          this.statusGetCatalogueTypes = '';
        }
      },
      (error) => {
        var errorMessage = <any>error;
        console.log(errorMessage);

        if (errorMessage != null) {
          this.statusGetCatalogueTypes = 'error';
        }
      }
    );
}

getLocations() {
    this._PropertySearchService.getPropretiesLocations(this.token).subscribe(
      (response) => {
        if (!Array.isArray(response)) {
          this.statusGetLocations = 'error';
        } else {
          this.propertyCatalogueLocations = response;
          this.statusGetLocations = '';
        }
      },
      (error) => {
        var errorMessage = <any>error;
        console.log(errorMessage);

        if (errorMessage != null) {
          this.statusGetLocations = 'error';
        }
      }
    );
  }

  getProperty(id:String){
    //console.log("Ediiiiit");
    this._router.navigate(['/property/'+id]);
    // this._propertyService.getProperty(id, this.token).subscribe(
    //     response => {
    //         if(!response.data){
    //             this.statusGetProperty = 'error';
    //         }else{
        
    //             this.propertyToUpdate = response.data;
    //             console.log(this.propertyToUpdate);
               
    //         }
    //     },
    //     error => {
    //         var errorMessage = <any>error;
    //         console.log(errorMessage);
    
    //         if(errorMessage != null){
    //             this.statusGetProperty = 'error';
    //         }
    //     }
    // );

}

getPropertyToDelete(id:String){
    this._propertyService.getProperty(id, this.token).subscribe(
        response => {
            if(!response.data){
                this.statusGetProperty = 'error';
            }else{
        
                this.propertyToDelete = response.data;
                //console.log(this.propertyToDelete);                   
            }
        },
        error => {
            var errorMessage = <any>error;
            //console.log(errorMessage);
    
            if(errorMessage != null){
                this.statusGetProperty = 'error';
            }
        }
    );

}

updateUser(){
    this.closebutton2.nativeElement.click();
    this._propertyService.updateProperty(this.propertyToUpdate, this.token).subscribe(
        response => {
            if(!response.data){
                this.statusEdit = 'error';
            }else{
                this.getProperties(true);
                this.statusEdit = 'success';
                this.showAlertEdit = true;
                //console.log(this.propertyToUpdate);
            }
        },
        error => {
            var errorMessage = <any>error;
           // console.log(errorMessage);
    
            if(errorMessage != null){
                this.statusEdit = 'error';
            }
        }
    );
   
}


deleteProperty(){
    this.closebutton3.nativeElement.click();
    this._propertyService.deleteProperty(this.propertyToDelete._id, this.token).subscribe(
        response => {
           // this.identity = response.data;
           // console.log(response);
            if(!response.data){
                this.statusDelete = 'error';
            }else{
              //console.log("response");
              //console.log(response);
              this.getProperties(true);
              this.statusDelete = 'success';
              this.showAlertDelete = true;
              //
                //this.rerender();
                //this.ngAfterViewInit(); 
                //this.ngOnInit
                //this.getProperties(true);
                // this._router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                //     this._router.navigate(['properties']);
                //   }); 
             
            }
  
        },error => {
            var errorMessage = <any>error;
            //console.log(errorMessage);
  
            if(errorMessage != null){
                this.statusDelete = 'error';
            }
        }
    );
    
}


public showAlertCreate: Boolean
closeCrear(){
    this.showAlertCreate = false;
}

public showAlertEdit: Boolean
closeEdit(){
    this.showAlertEdit = false;
}

public showAlertDelete: Boolean
closeDelete(){
    this.showAlertDelete = false;
}



// SearchProperties(){
//     this.statusGetSearchProperties = '';
//     this._PropertySearchService.getSearchProperties(this.propertyToSearch,this.token).subscribe(
//         (response) => {
//             console.log(response);
//           if (!('properties' in response)) {
//             this.statusGetSearchProperties = 'error';
//           } else {
//             this.data = response.properties;
//             this.statusGetSearchProperties =this.data.length>0?'': 'error';     
//           }
//         },
//         (error) => {
//           var errorMessage = <any>error;
//           console.log(errorMessage);
  
//           if (errorMessage != null) {
//             this.statusGetSearchProperties = 'error';
//           }
//         }
//       );
    
// }


}
