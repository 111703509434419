<body class="bg-gradient-primary">

    <div class="container">

        <!-- Outer Row -->
        <div class="row justify-content-center">

            <div class="col-xl-10 col-lg-12 col-md-9">

                <div class="card o-hidden border-0 shadow-lg my-5">
                    <div class="card-body p-0">
                        <!-- Nested Row within Card Body -->
                        <div class="row">
                            <div class="col-lg-6 d-none d-lg-block bg-password-image"></div>
                            <div class="col-lg-6">
                                <div class="p-5">
                                    <div class="text-center">
                                        <img style="margin-bottom:20px;" width="200" src="assets/img/logo-odt.png" />
                                        <h1 class="h4 text-gray-900 mb-2">Olvid&eacute; mi contrase&ntilde;a</h1>
                                        <p class="mb-4">Ingresa tu correo electr&oacute;nico a continuaci&oacute;n.</p>

                                        <div class="alert alert-danger" role="alert" *ngIf="status == 'error'">El correo electr&oacute;nico no existe.</div>
                                    </div>
                                    <form #forgotForm="ngForm" #forgotForm="ngForm" (ngSubmit)="onSubmit(forgotForm)" class="user">
                                        <div class="form-group">
                                            <input 
                                                type="email" 
                                                name="email"
                                                #email="ngModel"
                                                [(ngModel)]="user.email"
                                                class="form-control form-control-user"
                                                id="inputEmail" 
                                                aria-describedby="emailHelp"
                                                placeholder="Ingresa tu correo electr&oacute;nico...">
                                        </div>
                                        <!-- <a href="login.html" class="btn btn-primary btn-user btn-block">
                                            Recuperar Contrase&ntilde;a
                                        </a> -->
                                        <input type="submit" [disabled]="!forgotForm.form.valid" class="btn btn-primary btn-user btn-block" value="Recuperar Contrase&ntilde;a">
                                    </form>
                                    <hr>
                                    <div class="text-center">
                                        ¿Ya tienes una cuenta? <a routerLink="/login" class="small">Inicia sesi&oacute;n aqu&iacute;</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>
</body>