export class Property{
    constructor(
        public _id: String,
        public createdAt: String,
        public updatedAt: String,
        public propertyModule: Number,
        public contact: String,
        public propertyFeaturedImage: String, //Nuevo campo ----->>>>> Imagen
        public propertyTitle: String, //Nuevo campo
        public propertySlug: String, //Nuevo campo
        public propertyDesc: String, //Nuevo campo
        public propertyYoutube: String, //Nuevo campo
        public propertyPresentation: String, //Nuevo campo ->>>>>>>>>Archivo
        public propertyRentPrice: Number, //Nuevo campo
        public propertySalePrice: Number, //Nuevo campo
        public propertyMinPrice: Number, //Nuevo campo
        public propertyMaxPrice: Number, //Nuevo campo
        public propertyIusi: Number, //Nuevo campo
        public propertyIusiType:Number, // Nuevo campo
        public propertyMaintenance: Number, //Nuevo campo
        public propertyComision: Number, //Nuevo campo
        public propertyTax: Boolean, //Nuevo campo
        public propertyOwner: String, //Nuevo campo
        public propertyOperation: Number, //Nuevo campo
        public propertyVisibility: Number, //Nuevo campo
        public propertyFeatured: Boolean, //Nuevo campo
        public propertyType: any,
        public propertyAmenity: String, //Nuevo campo
        //public propertyState: String, //Nuevo campo
        public propertyState: String, //Nuevo campo
        public propertyBedrooms: Number,
        public propertyBathrooms: Number,
        public propertyArea: Number,
        public propertyAreaType: Number,
        public propertyLocation: String, //Nuevo campo
        //public propertyState: String, //Nuevo campo
        //public propertyProject: string, //Nuevo campo
        //public propertyStatus: String, //Nuevo campo
        //public status: String
        public levels: Number,//Nuevo campo
        public propertyConstructionArea: Number, //Nuevo campo
        public propertyConstructionAreaType: Number, //Nuevo campo
        public parkinslots: Number, //Nuevo campo
        public typeofownership: Number, //Nuevo campo
        public maintenanceCurrency: Number, //Nuevo campo
        public iusiCurrency: Number, //Nuevo campo
        public propertyThumbnailImage: String,
        public propertyTagline:String, //Nuevo campo
        public code:String
    ){}
}