<!-- Begin Page Content -->
<!-- <div class="container-fluid"> -->

    
  
      <!-- DataTales Example -->
      <div class="card shadow mb-4">
          <div class="card-header py-3">
              <div class="row">
                  <div class="col-md-12">
                      <div class="alert alert-success" role="alert" *ngIf="statusCreate == 'success'">Se ha creado la propiedad satisfactoriamente.</div>
                      <div class="alert alert-success" role="alert" *ngIf="statusEdit == 'success'">Se ha editado la propiedad satisfactoriamente.</div>
                      <div class="alert alert-success" role="alert" *ngIf="statusDelete == 'success'">Se ha eliminado la propiedad satisfactoriamente.</div>
                      <div class="alert alert-danger" role="alert" *ngIf="statusCreate == 'error' && showAlertCreate">No se ha podido crear la propiedad, intenta m&aacute;s tarde. <button type="button" class="close" (click)="closeCrear()" aria-label="Close" ><span aria-hidden="true">&times;</span></button></div>
                      <div class="alert alert-danger" role="alert"  *ngIf="statusEdit == 'error' && showAlertEdit">No se ha podido editar la propiedad, intenta m&aacute;s tarde. <button type="button" class="close" (click)="closeEdit()" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
                      <div class="alert alert-danger" role="alert" *ngIf="statusDelete == 'error' && showAlertDelete">No se ha podido eliminar la propiedad, intenta m&aacute;s tarde. <button type="button" class="close" (click)="closeDelete()" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
                      <div class="alert alert-danger" role="alert" *ngIf="statusGetSearchProperties == 'error'">En este momento no hay propiedades que correspondan a tus criterios de b&uacute;squeda.</div>
                  </div>
              </div>
  
              <div class="row">
                  <div class="col-md-10">
                      <h6 class="m-0 font-weight-bold text-primary">Listado de Propiedades</h6>
                      <p>Todas las propiedades archivadas.</p>
                  </div>
  
              </div>
          </div>
          <div class="card-body">
              <div class="table-responsive">
                  <table class="table table-bordered" id="datatableexample" width="100%" cellspacing="0">
                      <thead>
                          <tr>
                              <!-- <th>Imagen</th> -->
                              <th>M&oacute;dulo</th>
                              <th>C&oacute;digo</th>
                              <th>T&iacute;tulo</th>
                              <th>Precio</th>
                              <th>Operaci&oacute;n</th>
                              <th>Tipo</th>
                              <th>Ingresado por Agente</th>
                              <th>Estatus</th>
                              <th>Visibilidad</th>
                              <th style="width:80px;"></th>
                          </tr>
                      </thead>
                      <tfoot>
                          <tr>
                              <!-- <th>Imagen</th> -->
                              <th>M&oacute;dulo</th>
                              <th>C&oacute;digo</th>
                              <th>T&iacute;tulo</th>
                              <th>Precio</th>
                              <th>Operaci&oacute;n</th>
                              <th>Tipo</th>
                              <th>Ingresado por Agente</th>
                              <th>Estatus</th>
                              <th>Visibilidad</th>
                              <th style="width:80px;"></th>
                          </tr>
                      </tfoot>
                      <tbody>
                          <tr *ngFor="let property of data">
                              <!-- <td><img width="100" src="https://olgadetorrebiarte.com/wp-content/uploads/2019/04/2-1-835x467.jpg"><hr></td> -->
                              <!-- <td *ngIf="property.propertyThumbnailImage"><img width="100" src="{{ url +'get-thumbnail-property/' + property.propertyThumbnailImage }}"><hr></td>                              
                              <td *ngIf="property.propertyFeaturedImage && !property.propertyThumbnailImage"><img width="100" src="{{ url +'get-image-property/' + property.propertyFeaturedImage }}"><hr></td>
                              <td *ngIf="!property.propertyFeaturedImage && !property.propertyThumbnailImage"><img width="100" src="https://olgadetorrebiarte.com/wp-content/uploads/2019/04/2-1-835x467.jpg"><hr></td> -->
                              <td *ngIf="property.propertyModule==1">Propiedad</td>
                              <td *ngIf="property.propertyModule==2">Proyecto Nuevo</td>
                              <td><span class="badge badge-success">{{property.code?property.code:""}}</span></td>
                              <td *ngIf="property.propertyModule!=2 && property.propertyModule!=1">{{" "}}</td>
                              <td>{{ property.propertyTitle}}</td>
                              <td><b>Precio Renta</b>: ${{property.propertyRentPrice?property.propertyRentPrice:""}}<br/><br/><b>Precio Venta</b>: ${{property.propertySalePrice?property.propertySalePrice:""}}<br/><br/><b>Precio Mínimo</b>: ${{property.propertyMinPrice?property.propertyMinPrice:""}}<br/><br/><b>Precio Máximo</b>: ${{property.propertyMaxPrice?property.propertyMaxPrice:""}}</td>
                              <td *ngIf="property.propertyOperation==1">Venta</td>
                              <td *ngIf="property.propertyOperation==2">Renta</td>
                              <td *ngIf="property.propertyOperation==3">Venta y Renta</td>
                              <td>{{ property.propertyType.label}}</td>
                              <td *ngIf="property.propertyOwner">{{ property.propertyOwner.name +" "+ property.propertyOwner.surname}}</td>
                              <td *ngIf="!property.propertyOwner">{{property.propertyOwnerName?property.propertyOwnerName:"Inmobiliaria Olga de Torrebiarte"}}</td>
                              <td><span class="badge badge-success">{{ property.propertyStatus?property.propertyStatus.label:""}}</span></td>
                              <!-- <td *ngIf="!property.propertyOwner"></td> -->
                              <td *ngIf="property.propertyVisibility=='2'"><span class="badge badge-secondary">Privado</span></td>
                              <td *ngIf="property.propertyVisibility=='1'"><span class="badge badge-primary">Público</span></td>
                              <td>
                                  <!-- <a class="btn btn-primary" role="button" aria-pressed="true" (click)="(getProperty._id)"><i class="fas fa-edit"></i></a> -->
                                  <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#editUser" (click)="getProperty(property._id)"><i class="fas fa-edit"></i></button>
  
                                  <button type="button" class="btn btn-danger" data-toggle="modal" data-target="#deleteProperty" (click)="getPropertyToDelete(property._id)"><i class="far fa-trash-alt"></i></button>
                                  <!-- Modal -->
                                  <div class="modal fade" id="deleteProperty" tabindex="-1" role="dialog" aria-labelledby="deletePropertyLabel" aria-hidden="true">
                                      <div class="modal-dialog" role="document">
                                        <div class="modal-content">
                                          <div class="modal-header">
                                            <h5 class="modal-title" id="deletePropertyLabel">Eliminar Propiedad</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                              <span aria-hidden="true">&times;</span>
                                            </button>
                                          </div>
                                          <div class="modal-body">
                                            <p>¿Estás seguro de eliminar esta propiedad?</p>
                                          </div>
                                          <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" (click)="deleteProperty()">Eliminar</button>
                                            <button type="button" class="btn btn-primary" #closebutton3  data-dismiss="modal">Salir</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                              </td>
                      </tbody>
                  </table>
              </div>
          </div>
      </div>
  
  <!-- </div> -->
  <!-- /.container-fluid -->
