import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from '../../../../users/services/user.service';
import { Features } from '../models/features';
import { FeaturesService } from '../services/features.service';
import { GLOBAL } from '../../../../../services/global';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss'],
  providers: [UserService, FeaturesService],
})
export class FeaturesComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  //   dtOptions: DataTables.Settings = {};
  isDtInitialized: boolean = false;
  dtTrigger: Subject<any> = new Subject();

  public title: string;
  public feature: Features;
  public featureToUpdate: Features;
  public featureToDelete: Features;
  public featureToActivate: Features;
  public data: Features[];
  public url: String;
  public identity;
  public token;
  public statusCreate: string;
  public statusEdit: string;
  public statusDelete: string;
  public statusActivated: string;
  public statusGetFeatures: string;
  public statusGetFeature: string;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _featureService: FeaturesService
  ) {
    this.title = 'Listado de Usuarios';
    this.url = GLOBAL.url;
    this.feature = new Features('', '', '', '');
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
  }

  ngOnInit() {
    this.getFeatures(false);
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again 
      this.dtTrigger.next();
    });
  }

  getFeatures(updated: boolean) {
    this._featureService.getFeatures(this.token).subscribe(
      (response) => {
        if (!response.data) {
          this.statusGetFeatures = 'error';
        } else {
          this.data = response.data;
          //console.log(this.data);

          if (updated) {
            // this.rerender();
            // this.ngAfterViewInit();
            var table = $('#datatableexample').DataTable()
            table.destroy();
            setTimeout(() => {
              $('#datatableexample').DataTable({
                pagingType: 'full_numbers',
                dom: 'lpftrip',
                pageLength: 20,
                processing: true,
                lengthMenu: [25, 50, 100],
                order: [[1, 'desc']],
                language: {
                  processing: 'Procesando...',
                  search: 'Buscar:',
                  lengthMenu: 'Mostrar _MENU_ &eacute;l&eacute;ments',
                  info: 'Mostrando desde _START_ al _END_ de _TOTAL_ elementos',
                  infoEmpty: 'Mostrando ningún elemento.',
                  infoFiltered: '(filtrado _MAX_ elementos total)',
                  infoPostFix: '',
                  loadingRecords: 'Cargando registros...',
                  zeroRecords: 'No se encontraron registros',
                  emptyTable: 'No hay datos disponibles en la tabla',
                  paginate: {
                    first: 'Primero',
                    previous: 'Anterior',
                    next: 'Siguiente',
                    last: 'Último',
                  },
                  aria: {
                    sortAscending:
                      ': Activar para ordenar la tabla en orden ascendente',
                    sortDescending:
                      ': Activar para ordenar la tabla en orden descendente',
                  },
                },
              });
            }, 1);
            //this.ngOnInit();
          } else {
            setTimeout(() => {
              $('#datatableexample').DataTable({
                pagingType: 'full_numbers',
                dom: 'lpftrip',
                pageLength: 20,
                processing: true,
                lengthMenu: [5,20,50],
                order: [[1, 'desc']],
                language: {
                  processing: 'Procesando...',
                  search: 'Buscar:',
                  lengthMenu: 'Mostrar _MENU_ &eacute;l&eacute;ments',
                  info: 'Mostrando desde _START_ al _END_ de _TOTAL_ elementos',
                  infoEmpty: 'Mostrando ningún elemento.',
                  infoFiltered: '(filtrado _MAX_ elementos total)',
                  infoPostFix: '',
                  loadingRecords: 'Cargando registros...',
                  zeroRecords: 'No se encontraron registros',
                  emptyTable: 'No hay datos disponibles en la tabla',
                  paginate: {
                    first: 'Primero',
                    previous: 'Anterior',
                    next: 'Siguiente',
                    last: 'Último',
                  },
                  aria: {
                    sortAscending:
                      ': Activar para ordenar la tabla en orden ascendente',
                    sortDescending:
                      ': Activar para ordenar la tabla en orden descendente',
                  },
                },
              });
            }, 1);
          }
        }
      },
      (error) => {
        var errorMessage = <any>error;
        //console.log(errorMessage);

        if (errorMessage != null) {
          this.statusGetFeatures = 'error';
        }
      }
    );
  }

  onSubmit(saveForm: any) {
    this.closebutton.nativeElement.click();
    //console.log('enviando');
    this._featureService.saveFeature(this.feature, this.token).subscribe(
      (response) => {
        //console.log(response);
        if (!response.data) {
          saveForm.reset();
          this.statusCreate = 'error';
          this.showAlertCreate = true;
          //console.log('Error al guardar el token');
        } else {
          this.statusCreate = 'success';
          this.showAlertCreateS = true;
          saveForm.reset();
          //console.log('response');
          //console.log(response);
          this.getFeatures(true);
          // this._router
          //   .navigateByUrl('/RefreshComponent', { skipLocationChange: true })
          //   .then(() => {
          //     this._router.navigate(['features']);
          //   });
        }
      },
      (error) => {
        var errorMessage = <any>error;
        //console.log(errorMessage);

        if (errorMessage != null) {
          this.statusCreate = 'error';
        }
      }
    );
  }

  getFeature(idUser: String) {
    this._featureService.getFeature(idUser, this.token).subscribe(
      (response) => {
        if (!response.data) {
          this.statusGetFeature = 'error';
        } else {
          this.featureToUpdate = response.data;
          //console.log(this.featureToUpdate);
        }
      },
      (error) => {
        var errorMessage = <any>error;
        //console.log(errorMessage);

        if (errorMessage != null) {
          this.statusGetFeature = 'error';
        }
      }
    );
  }

  getFeatureToDelete(idUser: String) {
    this._featureService.getFeature(idUser, this.token).subscribe(
      (response) => {
        if (!response.data) {
          this.statusGetFeature = 'error';
        } else {
          this.featureToDelete = response.data;
          //console.log(this.featureToDelete);
        }
      },
      (error) => {
        var errorMessage = <any>error;
        //console.log(errorMessage);

        if (errorMessage != null) {
          this.statusGetFeature = 'error';
        }
      }
    );
  }

  updateFeature() {
    this.closebutton2.nativeElement.click();
    this._featureService
      .updateFeature(this.featureToUpdate, this.token)
      .subscribe(
        (response) => {
          if (!response.data) {
            this.statusEdit = 'error';
            this.showAlertEdit = true;
          } else {
            this.statusEdit = 'success';
            this.showAlertEditS = true;
            this.getFeatures(true);
            //console.log(this.featureToUpdate);
            // this._router
            //   .navigateByUrl('/RefreshComponent', { skipLocationChange: true })
            //   .then(() => {
            //     this._router.navigate(['features']);
            //   });
          }
        },
        (error) => {
          var errorMessage = <any>error;
          //console.log(errorMessage);

          if (errorMessage != null) {
            this.statusEdit = 'error';
          }
        }
      );
  }

  deleteFeature() {
    this.closebutton3.nativeElement.click();
    this._featureService
      .deleteFeature(this.featureToDelete._id, this.token)
      .subscribe(
        (response) => {
          // this.identity = response.data;
          //console.log(response);
          if (!response.data) {
            this.statusDelete = 'error';
            this.showAlertDelete = true;
          } else {
            //console.log('response');
            //console.log(response);
            this.statusDelete = 'success';
            this.showAlertDeleteS = true;
            this.getFeatures(true);
            // this._router
            //   .navigateByUrl('/RefreshComponent', { skipLocationChange: true })
            //   .then(() => {
            //     this._router.navigate(['features']);
            //   });
          }
        },
        (error) => {
          var errorMessage = <any>error;
          //console.log(errorMessage);

          if (errorMessage != null) {
            this.statusDelete = 'error';
          }
        }
      );
  }

  getFeatureToActivate(idUser: String) {
    this._featureService.getFeature(idUser, this.token).subscribe(
      (response) => {
        if (!response.data) {
          this.statusGetFeature = 'error';
        } else {
          this.featureToActivate = response.data;
          //console.log(this.featureToActivate);
        }
      },
      (error) => {
        var errorMessage = <any>error;
        //console.log(errorMessage);

        if (errorMessage != null) {
          this.statusGetFeature = 'error';
        }
      }
    );
  }
  
  activeFeature() {
    this.closebutton3.nativeElement.click();
    this._featureService
      .activeFeature({_id:this.featureToActivate._id}, this.token)
      .subscribe(
        (response) => {
          // this.identity = response.data;
          //console.log(response);
          if (!response.data) {
            this.statusActivated = 'error';
          } else {
            //console.log('response');
            //console.log(response);
            this.statusActivated = 'success';
            //this.getfeatures();
            this._router
              .navigateByUrl('/RefreshComponent', { skipLocationChange: true })
              .then(() => {
                this._router.navigate(['features']);
              });
          }
        },
        (error) => {
          var errorMessage = <any>error;
          //console.log(errorMessage);

          if (errorMessage != null) {
            this.statusActivated = 'error';
          }
        }
      );
  }

  public showAlertCreateS: Boolean
    closeCrearS(){
        this.showAlertCreateS = false;
    }

    public showAlertCreate: Boolean
    closeCrear(){
        this.showAlertCreate = false;
    }

    public showAlertEditS: Boolean
    closeEditS(){
        this.showAlertEditS = false;
    }

    public showAlertEdit: Boolean
    closeEdit(){
        this.showAlertEdit = false;
    }


    public showAlertDeleteS: Boolean
    closeDeleteS(){
        this.showAlertDeleteS = false;
    }

    public showAlertDelete: Boolean
    closeDelete(){
        this.showAlertDelete = false;
    }
}
