import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { User } from '../../users/models/user';
import { UserService } from '../../users/services/user.service';
import { Property } from '../models/property';
import { PropertyService } from  '../services/property.service'
import { PropertyTypeService } from  '../../catalogues/components/propertyTypes/services/propertyTypes.service';
import { PropertyType } from  '../../catalogues/components/propertyTypes/models/propertyType'
import { GLOBAL } from '../../../services/global';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

import { PropertySearchService } from '../services/property-search.service';
import { PropertyToSearch } from '../models/propertyToSearch';
import { PropertyCatalogueState } from '../models/propertyCatalogueState';
import { PropertyCatalogueType } from '../models/propertyCatalogueType';
import { PropertyCatalogueLocation } from '../models/propertyCatalogueLocation';

import { Departament } from '../../catalogues/components/depatament/models/departament';
import { DepartamentService } from '../../catalogues/components/depatament/services/department.service'




@Component({
    selector: 'property',
    templateUrl: './property.component.html',
    styleUrls: ['./property.component.scss'],
    providers: [UserService, PropertyService, PropertyTypeService,PropertySearchService,DepartamentService]
})

export class PropertyComponent implements OnInit{
    @ViewChild('closebutton') closebutton;
    @ViewChild('closebutton2') closebutton2;
    @ViewChild('closebutton3') closebutton3;

    @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
//   dtOptions: DataTables.Settings = {};
  isDtInitialized:boolean = false;
  dtTrigger: Subject<any> = new Subject();

    public title: string;
    public property: Property;
    public propertyToUpdate: User;
    public propertyToDelete: User;
    public data: Property[];
    public propertyTypes: PropertyType[];
    public users: User[];
    public url: String;
    public identity;
    public token;
    public statusCreate: string;
    public statusEdit: string;
    public statusDelete: string;
    public statusGetProperties: string;
    public statusGetProperty: string;

    public propertyToSearch: PropertyToSearch;
    public propertyCatalogueStates: PropertyCatalogueState[];
    public propertyCatalogueTypes: PropertyCatalogueType[];
    public propertyCatalogueLocations: PropertyCatalogueLocation[];
    public statusGetStates: string;
    public statusGetLocations: string;
    public statusGetCatalogueTypes: string;
    public statusGetSearchProperties: string;

    public departments: Departament[];


    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _userService: UserService,
        private _propertyService: PropertyService,
        private _propertyTypeService: PropertyTypeService,
        private _PropertySearchService: PropertySearchService,
        private _departamentStatusService: DepartamentService
    ){
        this.title = 'Listado de Usuarios';
        this.url = GLOBAL.url;
        this.property = new Property("","","",1,"","","","","","","",0,0,0,0,0,0,0,0,false,"",1,1,false,"604adb147671b0001418866c","","",0,0,0,0,"",0,0,0,0,0,0,0,"","","");
        this.identity = this._userService.getIdentity();
        this.token = this._userService.getToken();
        this.propertyToSearch = new PropertyToSearch(0, '', '', '','','',0,0);
    }

    ngOnInit(){
        this.getProperties(false);
        this.getPropertyTypes();
        this.getLocations();
        this.getStates();
        this.getTypesCatalogue();
        this.getDepartments();

        // this.dtOptions = {
        //     pagingType: 'full_numbers',
        //     pageLength: 5,
        //   lengthMenu : [5, 10, 25],
        //     processing: true
        // };
      
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next();
      }
    
      ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.dtTrigger.unsubscribe();
      }
    
      rerender(): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          // Destroy the table first
          dtInstance.destroy();
          // Call the dtTrigger to rerender again
          this.dtTrigger.next();
        });
      }

      getDepartments(){
        this._departamentStatusService.getDepartaments(this.token).subscribe(
            response => {
                if(!response.data){
                    // this.status = 'error';
                }else{
                    this.departments = response.data;
                    //console.log('depatamentos', this.departments);
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    // this.status = 'error';
                }
            }
        );
    }

    getDepartmentLabel(state){
      return this.departments.find(d=>d._id==state).label
    }

    getProperties(updated:boolean){
        this._propertyService.getProperties(this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetProperties = 'error';
                }else{
                    this.data = response.data.filter(
                      (p) =>
                        !(p.propertyStatus &&
                        p.propertyStatus.name &&
                        ['alquilado', 'vendido', 'fuerademercado'].includes(
                          p.propertyStatus.name
                        ))
                    );
                     console.log(this.data);
                    if(updated){
                        // this.rerender();
                        // this.ngAfterViewInit();
                        var table = $('#datatableexample').DataTable();
                        table.destroy();
                        setTimeout(()=>{                          
                            $('#datatableexample').DataTable( {
                              dom: 'lpftrip',
                              destroy: true,
                              pagingType: 'full_numbers',
                              pageLength: 20,
                              processing: true,
                              lengthMenu : [20, 50, 100],
                              language: {
                                processing: "Procesando...",
                                search: "Buscar:",
                                lengthMenu: "Mostrar _MENU_ &eacute;l&eacute;elementos",
                                info: "Mostrando desde _START_ al _END_ de _TOTAL_ elementos",
                                infoEmpty: "Mostrando ningún elemento.",
                                infoFiltered: "(filtrado _MAX_ elementos total)",
                                infoPostFix: "",
                                loadingRecords: "Cargando registros...",
                                zeroRecords: "No se encontraron registros",
                                emptyTable: "No hay datos disponibles en la tabla",
                                paginate: {
                                  first: "Primero",
                                  previous: "Anterior",
                                  next: "Siguiente",
                                  last: "Último"
                                },
                                aria: {
                                  sortAscending: ": Activar para ordenar la tabla en orden ascendente",
                                  sortDescending: ": Activar para ordenar la tabla en orden descendente"
                                }}
                          } );
                          }, 1);
                    }else{
                        setTimeout(()=>{                          
                            $('#datatableexample').DataTable( {
                              dom: 'lpftrip',
                              pagingType: 'full_numbers',
                              pageLength: 20,
                              processing: true,
                              lengthMenu : [20, 50, 100],
                              language: {
                                processing: "Procesando...",
                                search: "Buscar:",
                                lengthMenu: "Mostrar _MENU_ elementos",
                                info: "Mostrando desde _START_ al _END_ de _TOTAL_ elementos",
                                infoEmpty: "Mostrando ningún elemento.",
                                infoFiltered: "(filtrado _MAX_ elementos total)",
                                infoPostFix: "",
                                loadingRecords: "Cargando registros...",
                                zeroRecords: "No se encontraron registros",
                                emptyTable: "No hay datos disponibles en la tabla",
                                paginate: {
                                  first: "Primero",
                                  previous: "Anterior",
                                  next: "Siguiente",
                                  last: "Último"
                                },
                                aria: {
                                  sortAscending: ": Activar para ordenar la tabla en orden ascendente",
                                  sortDescending: ": Activar para ordenar la tabla en orden descendente"
                                }}
                          } );
                          }, 1);
                    }
                    
                    
                        

                    //   this.rerender();
                    //  this.ngOnInit();

                    //   if (this.isDtInitialized) {
                    //     this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    //         dtInstance.destroy();
                    //         this.dtTrigger.next();
                    //     });
                    // } else {
                    //     this.isDtInitialized = true;
                    //     this.dtTrigger.next();
                    // }

                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetProperties = 'error';
                }
            }
        );
    }

    getPropertyTypes(){
        this._propertyTypeService.getPropertyTypes(this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetProperties = 'error';
                }else{
                    this.propertyTypes = response.data;
                    //console.log(this.propertyTypes);
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetProperties = 'error';
                }
            }
        );
    }
    
    getStates() {
        this._PropertySearchService.getPropretiesStates(this.token).subscribe(
          (response) => {
            if (!Array.isArray(response)) {
              this.statusGetStates = 'error';
            } else {
              this.propertyCatalogueStates = response;
              this.statusGetStates = '';
            }
          },
          (error) => {
            var errorMessage = <any>error;
            console.log(errorMessage);
    
            if (errorMessage != null) {
              this.statusGetStates = 'error';
            }
          }
        );
    }

    getTypesCatalogue() {
        this._PropertySearchService.getPropretiesTypes(this.token).subscribe(
          (response) => {
            if (!Array.isArray(response)) {
              this.statusGetCatalogueTypes = 'error';
            } else {
              this.propertyCatalogueTypes = response;
              this.statusGetCatalogueTypes = '';
            }
          },
          (error) => {
            var errorMessage = <any>error;
            console.log(errorMessage);
    
            if (errorMessage != null) {
              this.statusGetCatalogueTypes = 'error';
            }
          }
        );
    }

    getLocations() {
        this._PropertySearchService.getPropretiesLocations(this.token).subscribe(
          (response) => {
            if (!Array.isArray(response)) {
              this.statusGetLocations = 'error';
            } else {
              this.propertyCatalogueLocations = response;
              this.statusGetLocations = '';
            }
          },
          (error) => {
            var errorMessage = <any>error;
            console.log(errorMessage);
    
            if (errorMessage != null) {
              this.statusGetLocations = 'error';
            }
          }
        );
      }

    onSubmit(saveForm:any) {
        this.closebutton.nativeElement.click();
        //console.log(this.property);
        this._propertyService.saveProperty(this.property, this.token).subscribe(
            response => {
               // console.log(response);
                if(!response.data){
                    saveForm.reset();
                    this.statusCreate = 'error';
                    this.showAlertCreate = true;
                    // console.log('Error al guardar el token');
                }else{
                  this.statusCreate = 'success';
                  saveForm.reset();
                  //console.log("response");
                  //console.log(response);
                //   this.getProperties();
                this._router.navigate(['/property/'+response.data._id]);
                }

                //SUBIDA DE IMAGEN
                if(this.filesToUpload && this.filesToUpload.length)
                {
                    //console.log(response.data._id);
                    this._propertyService.makeFileRequest(this.url+'upload-image-porperty/'+response.data._id,[], this.filesToUpload, this.token, 'img')
                                    .then((result: any) =>{
                                        //console.log(result);
                                            // this.property.propertyFeaturedImage = result.property.propertyFeaturedImage;
                                            // ----------------thumbnail----------------------------

                                        this._propertyService.makeFileRequest(this.url+'upload-thumbnail-porperty/'+response.data._id,[], this.filesToUpload, this.token, 'img')
                                                    .then((result: any) =>{
                                                      this.getProperties(true);
                                                      this.statusCreate = 'success';
                                                    });
                                            
                                            
                                            //localStorage.setItem('identity', JSON.stringify(this.user));
                                    });
                }else{
                    //localStorage.setItem('identity', JSON.stringify(this.user));
                    //this._router.navigate(['/home']);
                }
  
            },error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
  
                if(errorMessage != null){
                    this.statusCreate = 'error';
                }
            }
        );
    }

    public filesToUpload: Array<File>;
    fileChangeEvent(fileInput: any){
        this.filesToUpload = <Array<File>>fileInput.target.files;
        //console.log(this.filesToUpload)
    }

    getProperty(id:String){
        //console.log("Ediiiiit");
        this._router.navigate(['/property/'+id]);
        // this._propertyService.getProperty(id, this.token).subscribe(
        //     response => {
        //         if(!response.data){
        //             this.statusGetProperty = 'error';
        //         }else{
            
        //             this.propertyToUpdate = response.data;
        //             console.log(this.propertyToUpdate);
                   
        //         }
        //     },
        //     error => {
        //         var errorMessage = <any>error;
        //         console.log(errorMessage);
        
        //         if(errorMessage != null){
        //             this.statusGetProperty = 'error';
        //         }
        //     }
        // );

    }

    viewProperty(id:String){
      this._router.navigate(['/view-property/'+id]);
    }

    getPropertyToDelete(id:String){
        this._propertyService.getProperty(id, this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetProperty = 'error';
                }else{
            
                    this.propertyToDelete = response.data;
                    //console.log(this.propertyToDelete);                   
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
        
                if(errorMessage != null){
                    this.statusGetProperty = 'error';
                }
            }
        );

    }

    updateUser(){
        this.closebutton2.nativeElement.click();
        this._propertyService.updateProperty(this.propertyToUpdate, this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusEdit = 'error';
                }else{
                    this.getProperties(true);
                    this.statusEdit = 'success';
                    this.showAlertEdit = true;
                    //console.log(this.propertyToUpdate);
                }
            },
            error => {
                var errorMessage = <any>error;
               // console.log(errorMessage);
        
                if(errorMessage != null){
                    this.statusEdit = 'error';
                }
            }
        );
       
    }
  

    deleteProperty(){
        this.closebutton3.nativeElement.click();
        this._propertyService.deleteProperty(this.propertyToDelete._id, this.token).subscribe(
            response => {
               // this.identity = response.data;
               // console.log(response);
                if(!response.data){
                    this.statusDelete = 'error';
                }else{
                  //console.log("response");
                  //console.log(response);
                  this.getProperties(true);
                  this.statusDelete = 'success';
                  this.showAlertDelete = true;
                  //
                    //this.rerender();
                    //this.ngAfterViewInit(); 
                    //this.ngOnInit
                    //this.getProperties(true);
                    // this._router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                    //     this._router.navigate(['properties']);
                    //   }); 
                 
                }
      
            },error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
      
                if(errorMessage != null){
                    this.statusDelete = 'error';
                }
            }
        );
        
    }


    public showAlertCreate: Boolean
    closeCrear(){
        this.showAlertCreate = false;
    }

    public showAlertEdit: Boolean
    closeEdit(){
        this.showAlertEdit = false;
    }

    public showAlertDelete: Boolean
    closeDelete(){
        this.showAlertDelete = false;
    }

    cleanSearch(){
      this.propertyToSearch=new PropertyToSearch(0, '', '', '','','',0,0);
      this.getProperties(true);

    }

    SearchProperties(){
        this.statusGetSearchProperties = '';
        this._PropertySearchService.getSearchProperties(this.propertyToSearch,this.token).subscribe(
            (response) => {
                // console.log(response);
              if (!('properties' in response)) {
                this.statusGetSearchProperties = 'error';
              } else {
                this.data = response.properties.filter(
                  (p) =>
                    !(p.propertyStatus &&
                    p.propertyStatus.name &&
                    ['alquilado', 'vendido', 'fuerademercado'].includes(
                      p.propertyStatus.name
                    ))
                );

                //console.log(this.data);
                this.statusGetSearchProperties =this.data.length>0?'': 'error';
                $('#datatableexample').DataTable().destroy();
                this.ngAfterViewInit();
                setTimeout(()=>{                          
                  $('#datatableexample').DataTable( {
                    dom: 'lpftrip',
                    destroy: true,
                    pagingType: 'full_numbers',
                    pageLength: 20,
                    processing: true,
                    lengthMenu : [20, 50, 100],
                    language: {
                      processing: "Procesando...",
                      search: "Buscar:",
                      lengthMenu: "Mostrar _MENU_ elementos",
                      info: "Mostrando desde _START_ al _END_ de _TOTAL_ elementos",
                      infoEmpty: "Mostrando ningún elemento.",
                      infoFiltered: "(filtrado _MAX_ elementos total)",
                      infoPostFix: "",
                      loadingRecords: "Cargando registros...",
                      zeroRecords: "No se encontraron registros",
                      emptyTable: "No hay datos disponibles en la tabla",
                      paginate: {
                        first: "Primero",
                        previous: "Anterior",
                        next: "Siguiente",
                        last: "Último"
                      },
                      aria: {
                        sortAscending: ": Activar para ordenar la tabla en orden ascendente",
                        sortDescending: ": Activar para ordenar la tabla en orden descendente"
                      }}
                } );
                }, 1);
                          
              }
            },
            (error) => {
              var errorMessage = <any>error;
              console.log(errorMessage);
      
              if (errorMessage != null) {
                this.statusGetSearchProperties = 'error';
              }
            }
          );
        
    }

}
