import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { User } from '../../../models/user';
import { UserService } from '../../../services/user.service';
import { GLOBAL } from '../../../../../services/global';

@Component({
    selector: 'forgotPassword',
    templateUrl: './forgotPassword.component.html',
    styleUrls: ['./forgotPassword.component.scss'],
    providers: [UserService]
})

export class ForgotPassword implements OnInit{
    public title: string;
    public user: User;
    public url: String;
    public identity;
    public token;
    public status: string;

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _userService: UserService
    ){
        this.title = 'Recudperar contraseña';
        this.url = GLOBAL.url;
        this.user = new User("","","","","","",false,"","","","","");
        this.identity = this._userService.getIdentity();
        this.token = this._userService.getToken();
    }

    ngOnInit(){

    }

    onSubmit(loginForm){
        this._userService.forgotPassword(this.user).subscribe(
            response => {
                this.identity = response.data;
                console.log(response);
                if(!this.identity || !this.identity.email){
                    this.status = 'error';
                }else{
    
                    this._router.navigate(['/forgot-password-step-2']);
    
                }
    
            },error => {
                var errorMessage = <any>error;
                console.log(errorMessage);
    
                if(errorMessage != null){
                    this.status = 'error';
                }
            }
        );
    }
}
