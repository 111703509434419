export class PhotoProperty{
    constructor(
        public _id: String,
        public propertyID: String,
        public imageName: String,
        public imageSrc: String,
        public imgThumbnail:String,
        public imageDate: String,
        public imageVisibility: Number,
        public status: String
    ){}
}