import {Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GLOBAL } from '../../../../../services/global';
import { PropertyProject } from '../models/project';

@Injectable()
export class PropertyProjectService{
    public url:string;
    public identity:any;
    public token:any;
 

    constructor(public _http: HttpClient){
        this.url = GLOBAL.url;
    }


    getPropertyProjects(token:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

                                       
        return this._http.get(this.url+'property-projects/', {headers: headers});
    }

    getPropertyProject(addressType:any, token:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

                                       
        return this._http.get(this.url+'property-project/'+addressType, {headers: headers});
    }

    getProjectStat(object:any, token:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

                                       
        return this._http.get(this.url+'project-count/'+object, {headers: headers});
    }

    savePropertyProject(addressType:any,token:any ): Observable<any>{
        
        let params = JSON.stringify(addressType);
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                       .set('Authorization',token);

        return this._http.post(this.url+'property-project',params, {headers:headers});
    }

    updatePropertyProject(addressType:any,token:any ): Observable<any>{
        
        let params = JSON.stringify(addressType);
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                       .set('Authorization',token);

        return this._http.put(this.url+'property-project/'+addressType._id,params, {headers:headers});
    }

    deletePropertyProject(addressType:any,token:any ): Observable<any>{
        
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                        .set('Authorization',token);

        return this._http.delete(this.url+'property-project/'+addressType, {headers:headers});
    }

    activePropertyProject(addressType:any,token:any ): Observable<any>{
        
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                       .set('Authorization',token);

        return this._http.post(this.url+'property-project'+addressType._id, {headers:headers});
    }

}