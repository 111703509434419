import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from '../../../../users/services/user.service';
import { PropertyProject } from '../models/project';
import { PropertyProjectService } from '../services/project.service'
import { GLOBAL } from '../../../../../services/global';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
    selector: 'project',
    templateUrl: './project.component.html',
    styleUrls: ['./project.component.scss'],
    providers: [UserService, PropertyProjectService]
})

export class PropertyProjectComponent implements OnInit{
    @ViewChild('closebutton') closebutton;
    @ViewChild('closebutton2') closebutton2;
    @ViewChild('closebutton3') closebutton3;

    @ViewChild(DataTableDirective, {static: false})
    dtElement: DataTableDirective;
//   dtOptions: DataTables.Settings = {};
    isDtInitialized:boolean = false;
    dtTrigger: Subject<any> = new Subject();


    public title: string;
    public propertyProject: PropertyProject;
    public projectToUpdate: PropertyProject;
    public projectToDelete: PropertyProject;
    public propertyProjects: PropertyProject[];
    public url: String;
    public identity;
    public token;
    public statusCreate: string;
    public statusEdit: string;
    public statusDelete: string;
    public statusGetPropertyProjects: string;
    public statusGetPropertyProject: string;
    public contador;


    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _userService: UserService,
        private _propertyProjectService: PropertyProjectService,
    ){
        this.title = 'Listado de Usuarios';
        this.url = GLOBAL.url;
        this.propertyProject = new PropertyProject("","","","");
        this.identity = this._userService.getIdentity();
        this.token = this._userService.getToken();
    }

    ngOnInit(){
        this.getPropertys(false);
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next();
      }
    
      ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.dtTrigger.unsubscribe();
      }
    
      rerender(): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          // Destroy the table first
          dtInstance.destroy();
          // Call the dtTrigger to rerender again
          this.dtTrigger.next();
        });
      }

    getPropertys(updated:boolean){
        this._propertyProjectService.getPropertyProjects(this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetPropertyProjects = 'error';
                }else{
                    this.propertyProjects = response.data;
                    // console.log(this.statusGetPropertyProjects);

                    if(updated){
                        // this.rerender();
                        // this.ngAfterViewInit();
                        var table = $('#datatableexample').DataTable()
                        table.destroy();
                        setTimeout(()=>{                          
                            $('#datatableexample').DataTable( {
                              dom: 'lpftrip',
                              pagingType: 'full_numbers',
                              pageLength: 20,
                              processing: true,
                              lengthMenu : [20, 50, 100],
                              order:[[1,"desc"]],
                              language: {
                                processing: "Procesando...",
                                search: "Buscar:",
                                lengthMenu: "Mostrar _MENU_ &eacute;l&eacute;ments",
                                info: "Mostrando desde _START_ al _END_ de _TOTAL_ elementos",
                                infoEmpty: "Mostrando ningún elemento.",
                                infoFiltered: "(filtrado _MAX_ elementos total)",
                                infoPostFix: "",
                                loadingRecords: "Cargando registros...",
                                zeroRecords: "No se encontraron registros",
                                emptyTable: "No hay datos disponibles en la tabla",
                                paginate: {
                                  first: "Primero",
                                  previous: "Anterior",
                                  next: "Siguiente",
                                  last: "Último"
                                },
                                aria: {
                                  sortAscending: ": Activar para ordenar la tabla en orden ascendente",
                                  sortDescending: ": Activar para ordenar la tabla en orden descendente"
                                }}
                          } );
                          }, 1);
                          //this.ngOnInit();
                    }else{
                        setTimeout(()=>{                          
                            $('#datatableexample').DataTable( {
                              dom: 'lpftrip',
                              pagingType: 'full_numbers',
                              pageLength: 20,
                              processing: true,
                              lengthMenu : [20, 25, 100],
                              order:[[1,"desc"]],
                              language: {
                                processing: "Procesando...",
                                search: "Buscar:",
                                lengthMenu: "Mostrar _MENU_ elementos",
                                info: "Mostrando desde _START_ al _END_ de _TOTAL_ elementos",
                                infoEmpty: "Mostrando ningún elemento.",
                                infoFiltered: "(filtrado _MAX_ elementos total)",
                                infoPostFix: "",
                                loadingRecords: "Cargando registros...",
                                zeroRecords: "No se encontraron registros",
                                emptyTable: "No hay datos disponibles en la tabla",
                                paginate: {
                                  first: "Primero",
                                  previous: "Anterior",
                                  next: "Siguiente",
                                  last: "Último"
                                },
                                aria: {
                                  sortAscending: ": Activar para ordenar la tabla en orden ascendente",
                                  sortDescending: ": Activar para ordenar la tabla en orden descendente"
                                }}
                          } );
                          }, 1);
                    }
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetPropertyProjects = 'error';
                }
            }
        );
    }

    getContactStat(idUser:String){
        this._propertyProjectService.getProjectStat(idUser, this.token).subscribe(
            response => {
                // console.log(response);
                //console.log(idUser);
                if(!response.stat){
                    this.statusGetPropertyProjects = 'error';
                if(response.stat == 0){
                    this.contador = response.stat;
                    //console.log(this.contador);
                }
                }else{
                    this.contador = response.stat;
                    //console.log(this.contador);
                }
            },
            error => {
                var errorMessage = <any>error;
               // console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetPropertyProjects = 'error';
                }
            }
        );
    }
    

    onSubmit(saveForm:any) {
        this.closebutton.nativeElement.click();
        //console.log("enviando");
        this._propertyProjectService.savePropertyProject(this.propertyProject, this.token).subscribe(
            response => {
                //console.log(response);
                if(!response.data){
                    saveForm.reset();
                    this.statusCreate = 'error';
                    this.showAlertCreate = true;
                    // //console.log('Error al guardar el token');
                }else{
                  this.statusCreate = 'success';
                  this.showAlertCreateS = true;
                  saveForm.reset();
                  //console.log("response");
                  //console.log(response);
                  this.getPropertys(true);
                //   this._router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                //     this._router.navigate(['property-projects']);
                //   }); 
                }
  
            },error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
  
                if(errorMessage != null){
                    this.statusCreate = 'error';
                }
            }
        );
    }

    getProperty(idUser:String){
        this._propertyProjectService.getPropertyProject(idUser, this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetPropertyProject = 'error';
                }else{
            
                    this.projectToUpdate = response.data;
                    //console.log(this.projectToUpdate);
                   
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
        
                if(errorMessage != null){
                    this.statusGetPropertyProject = 'error';
                }
            }
        );

    }

    getPropertyToDelete(idUser:String){
        this._propertyProjectService.getPropertyProject(idUser, this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetPropertyProject = 'error';
                }else{
            
                    this.projectToDelete = response.data;
                    //console.log(this.projectToDelete);                   
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
        
                if(errorMessage != null){
                    this.statusGetPropertyProject = 'error';
                }
            }
        );

    }

    updatePorperty(){
        this.closebutton2.nativeElement.click();
        this._propertyProjectService.updatePropertyProject(this.projectToUpdate, this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusEdit = 'error';
                    this.showAlertEdit = true;
                }else{
                    
                    this.statusEdit = 'success';
                    this.showAlertEditS = true;
                    //console.log(this.projectToUpdate);
                    this.getPropertys(true);
                    // this._router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                    //     this._router.navigate(['property-projects']);
                    //   }); 
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
        
                if(errorMessage != null){
                    this.statusEdit = 'error';
                }
            }
        );
       
    }
  

    deleteProperty(){
        this.closebutton3.nativeElement.click();
        this._propertyProjectService.deletePropertyProject(this.projectToDelete._id, this.token).subscribe(
            response => {
               // this.identity = response.data;
                //console.log(response);
                if(!response.data){
                    this.statusDelete = 'error';
                    this.showAlertDelete = true;
                }else{
                  //console.log("response");
                  //console.log(response);
                  this.statusDelete = 'success';
                  this.showAlertDeleteS = true;
                  this.getPropertys(true);
                //   this._router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                //     this._router.navigate(['property-projects']);
                //   }); 
                }
      
            },error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
      
                if(errorMessage != null){
                    this.statusDelete = 'error';
                }
            }
        );
        
    }

    public showAlertCreateS: Boolean
    closeCrearS(){
        this.showAlertCreateS = false;
    }

    public showAlertCreate: Boolean
    closeCrear(){
        this.showAlertCreate = false;
    }

    public showAlertEditS: Boolean
    closeEditS(){
        this.showAlertEditS = false;
    }

    public showAlertEdit: Boolean
    closeEdit(){
        this.showAlertEdit = false;
    }


    public showAlertDeleteS: Boolean
    closeDeleteS(){
        this.showAlertDeleteS = false;
    }

    public showAlertDelete: Boolean
    closeDelete(){
        this.showAlertDelete = false;
    }


}
