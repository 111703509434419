import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GLOBAL } from '../../../services/global';

@Injectable({
  providedIn: 'root',
})
export class PropertyFeaturesService {
  public url: string;
  public identity: any;
  public token: any;

  constructor(public _http: HttpClient) {
    this.url = GLOBAL.url;
  }

  getPropretyFeatures(token: any): Observable<any> {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', token);

    return this._http.get(this.url + 'property-features/', {
      headers: headers,
    });
  }

  getPropretyFeature(object: any, token: any): Observable<any> {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', token);

    return this._http.get(this.url + 'property-feature/' + object, {
      headers: headers,
    });
  }

  getPropretyFeaturebyProperty(object: any, token: any): Observable<any> {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', token);

    return this._http.get(this.url + 'feature-property/' + object, {
      headers: headers,
    });
  }

  savePropretyFeature(object: any, token: any): Observable<any> {
    let params = JSON.stringify(object);
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', token);

    return this._http.post(this.url + 'property-feature', params, {
      headers: headers,
    });
  }

  updatePropretyFeature(object: any, token: any): Observable<any> {
    let params = JSON.stringify(object);
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', token);

    return this._http.put(this.url + 'property-feature/' + object._id, params, {
      headers: headers,
    });
  }

  deletePropretyFeature(object: any, token: any): Observable<any> {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', token);

    return this._http.delete(this.url + 'property-feature/' + object, {
      headers: headers,
    });
  }

  activePropretyFeature(object: any, token: any): Observable<any> {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', token);

    return this._http.post(this.url + 'property-feature' + object._id, {
      headers: headers,
    });
  }
}
