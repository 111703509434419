import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { User } from '../../../../users/models/user';
import { UserService } from '../../../../users/services/user.service';

import { GLOBAL } from '../../../../../services/global';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

import { SendProperty } from '../../../models/sendProperties';
import { SendPropertiesService } from '../../../services/send-properties.service';

@Component({
  selector: 'app-send-properties',
  templateUrl: './send-properties.component.html',
  styleUrls: ['./send-properties.component.scss'],
  providers: [UserService, SendPropertiesService]
})
export class SendPropertiesComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  //   dtOptions: DataTables.Settings = {};
  isDtInitialized: boolean = false;
  dtTrigger: Subject<any> = new Subject();

  public statusEdit: string;
  public statusDelete: string;
  public statusCreate: string;
  public statusGetUsers: string;
  public showAlertEdit: Boolean;
  public showAlertDelete: Boolean;
  public showAlertCreate: Boolean;

  public data: SendProperty[];

  public invite: SendProperty;
  public inviteToUpdate: SendProperty;
  public inviteToDelete: SendProperty;

  public identity;
  public token;

  constructor(
    private _userService: UserService,
    private _inviteService: SendPropertiesService,
    private _route: ActivatedRoute,
    private _router: Router
  ) {
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.invite = new SendProperty('', '', '','', '', '', '', '', '');
    this.invite.user = this.identity._id;
  }

  ngOnInit(): void {
    this.getInvites(false);
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  getInvites(updated: boolean) {
    this._inviteService.getInvites(this.token).subscribe(
      (response) => {
        if (!response.data) {
          this.statusGetUsers = 'error';
        } else {
          this.data = response.data;
          // console.log(this.data);
          if (updated) {
            // this.rerender();
            // this.ngAfterViewInit();

            var table = $('#datatableexample').DataTable();
            table.destroy();
            setTimeout(() => {
              $('#datatableexample').DataTable({
                dom: 'lpftrip',
                pagingType: 'full_numbers',
                pageLength: 20,
                processing: true,
                lengthMenu: [20, 50, 100],
                order: [[1, 'desc']],
                language: {
                  processing: 'Procesando...',
                  search: 'Buscar:',
                  lengthMenu: 'Mostrar _MENU_ &eacute;l&eacute;ments',
                  info: 'Mostrando desde _START_ al _END_ de _TOTAL_ elementos',
                  infoEmpty: 'Mostrando ningún elemento.',
                  infoFiltered: '(filtrado _MAX_ elementos total)',
                  infoPostFix: '',
                  loadingRecords: 'Cargando registros...',
                  zeroRecords: 'No se encontraron registros',
                  emptyTable: 'No hay datos disponibles en la tabla',
                  paginate: {
                    first: 'Primero',
                    previous: 'Anterior',
                    next: 'Siguiente',
                    last: 'Último',
                  },
                  aria: {
                    sortAscending:
                      ': Activar para ordenar la tabla en orden ascendente',
                    sortDescending:
                      ': Activar para ordenar la tabla en orden descendente',
                  },
                },
              });
            }, 1);
            //this.ngOnInit();
          } else {
            setTimeout(() => {
              $('#datatableexample').DataTable({
                dom: 'lpftrip',
                pagingType: 'full_numbers',
                pageLength: 20,
                processing: true,
                lengthMenu: [20, 50, 100],
                order: [[1, 'desc']],
                language: {
                  processing: 'Procesando...',
                  search: 'Buscar:',
                  lengthMenu: 'Mostrar _MENU_ Elementos',
                  info: 'Mostrando desde _START_ al _END_ de _TOTAL_ elementos',
                  infoEmpty: 'Mostrando ningún elemento.',
                  infoFiltered: '(filtrado _MAX_ elementos total)',
                  infoPostFix: '',
                  loadingRecords: 'Cargando registros...',
                  zeroRecords: 'No se encontraron registros',
                  emptyTable: 'No hay datos disponibles en la tabla',
                  paginate: {
                    first: 'Primero',
                    previous: 'Anterior',
                    next: 'Siguiente',
                    last: 'Último',
                  },
                  aria: {
                    sortAscending:
                      ': Activar para ordenar la tabla en orden ascendente',
                    sortDescending:
                      ': Activar para ordenar la tabla en orden descendente',
                  },
                },
              });
            }, 1);
          }
        }
      },
      (error) => {
        var errorMessage = <any>error;
        // console.log(errorMessage);

        if (errorMessage != null) {
          this.statusGetUsers = 'error';
        }
      }
    );
  }

  onSubmit(invitationForm: any) {
    this.closebutton.nativeElement.click();
    console.log('enviando');

    this._inviteService.saveInvite(this.token, this.invite).subscribe(
      (response) => {
        if (!response.data) {
          invitationForm.reset();
          this.statusCreate = 'error';
          this.showAlertCreate = true;
        } else {
          this.statusCreate = 'success';
          this.showAlertCreate = true;
          invitationForm.reset();
          // this.getInvites(true);
          this._router.navigate(['/shared-properties/' + response.data._id]);
        }
      },
      (error) => {
        var errorMessage = <any>error;

        if (errorMessage != null) {
          this.statusCreate = 'error';
          this.showAlertCreate = true;
        }
      }
    );
  }

  getInvite(idUser: String) {
    console.log(idUser);

    this._router.navigate(['/shared-properties/' + idUser]);
    // this._inviteService.getInvite(this.token, idUser).subscribe(
    //   (response) => {
    //     if (!response.data) {
    //       this.statusEdit = 'error';
    //     } else {
    //       this.inviteToUpdate = response.data;
    //     }
    //   },
    //   (error) => {
    //     var errorMessage = <any>error;
    //     //console.log(errorMessage);

    //     if (errorMessage != null) {
    //       this.statusEdit = 'error';
    //     }
    //   }
    // );
  }

  getInviteToDelete(idUser: String) {
    this._inviteService.getInvite(this.token, idUser).subscribe(
      (response) => {
        if (!response.data) {
          this.statusDelete = 'error';
        } else {
          this.inviteToDelete = response.data;
        }
      },
      (error) => {
        var errorMessage = <any>error;

        if (errorMessage != null) {
          this.statusDelete = 'error';
        }
      }
    );
  }

  deleteInvite() {
    this.closebutton2.nativeElement.click();
    this._inviteService
      .deleteInvite(this.token, this.inviteToDelete._id)
      .subscribe(
        (response) => {
          if (!response.data) {
            this.statusDelete = 'error';
            this.showAlertDelete = true;
          } else {
            this.statusDelete = 'success';
            this.showAlertDelete = true;

            this.getInvites(true);
          }
        },
        (error) => {
          var errorMessage = <any>error;
          if (errorMessage != null) {
            this.statusDelete = 'error';
            this.showAlertDelete = true;
          }
        }
      );
  }

  closeCreateS() {
    this.showAlertCreate = false;
  }
  closeEditS() {
    this.showAlertEdit = false;
  }
  closeDeleteS() {
    this.showAlertDelete = false;
  }
}
