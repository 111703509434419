import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GLOBAL } from '../../../services/global';

@Injectable({
  providedIn: 'root'
})
export class SharedPropertiesService {
  public url: string;
  public identity: any;
  public token: any;

  constructor(public _http: HttpClient) {
    this.url = GLOBAL.url;
  }

  getInviteProps(token: any,user:any): Observable<any> {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', token);

    return this._http.get(this.url + 'salers-shareProperties/?invitation='+user, {
      headers: headers,
    });
  }

  saveInviteProp(token: any,object: any ): Observable<any> {
    let params = JSON.stringify(object);
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', token);

    return this._http.post(this.url + 'salers-shareProperty', params, {
      headers: headers,
    });
  }


  deleteInviteProp(token: any,object: any ): Observable<any> {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', token);

    return this._http.delete(this.url + 'salers-shareProperty/' + object, {
      headers: headers,
    });
  }
}
