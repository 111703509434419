<!-- Begin Page Content -->
<!-- <div class="container-fluid"> -->

    <!-- DataTales Example -->
    <div class="card shadow mb-4">
        <div class="card-header py-3">
            <div class="row">
                <div class="col-md-12">
                    <div class="alert alert-success" role="alert" *ngIf="statusInvitation == 'success' && showAlertCreateS">Se ha enviado la invitaci&oacute;n al usuario satisfactoriamente. <button type="button" class="close" (click)="closeCrearS()" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
                    <div class="alert alert-success" role="alert" *ngIf="statusUserEdit == 'success' && showAlertEditS">Se ha editado el usuario satisfactoriamente. <button type="button" class="close" (click)="closeEditS()" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
                    <div class="alert alert-success" role="alert" *ngIf="statusUserDelete == 'success' && showAlertDeleteS">Se ha eliminado el usuario satisfactoriamente. <button type="button" class="close" (click)="closeDeleteS()" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
                    <div class="alert alert-danger" role="alert" *ngIf="statusInvitation == 'error' && showAlertCreate">No se ha podido invitar al usuario, intenta m&aacute;s tarde. <button type="button" class="close" (click)="closeCrear()" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
                    <div class="alert alert-danger" role="alert" *ngIf="statusUserEdit == 'error' && showAlertEdit">No se ha podido editar al usuario, intenta m&aacute;s tarde. <button type="button" class="close" (click)="closeEdit()" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
                    <div class="alert alert-danger" role="alert" *ngIf="statusUserDelete == 'error' && showAlertDelete">No se ha podido eliminar al usuario, intenta m&aacute;s tarde. <button type="button" class="close" (click)="closeDelete()" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-10">
                    <h6 class="m-0 font-weight-bold text-primary">Listado de Usuarios</h6>
                    <p>Todos los usuarios del sistema.</p>
                </div>

                <div class="col-md-2 float-right">

                    <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#inviteUser">
                      <i class="fas fa-user"></i> Invitar Usuario
                    </button>

                    <!-- Modal -->
                    <div class="modal fade" id="inviteUser" tabindex="-1" role="dialog" aria-labelledby="inviteUserLabel" aria-hidden="true">
                      <div class="modal-dialog" role="document">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="inviteUserLabel">Invitar Usuario</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <form #invitationForm="ngForm" #invitationForm="ngForm" (ngSubmit)="onSubmit(invitationForm)" >
                              <div class="form-group">
                                <label for="recipient-name" class="col-form-label">Correo electr&oacute;nico:</label>
                                <input 
                                  type="text" 
                                  name="email"
                                  #email="ngModel"
                                  [(ngModel)]="invitation.email"
                                  class="form-control" 
                                  id="recipient-name">
                              </div>
                              <div class="form-group">
                                <label for="message-text" class="col-form-label">Rol del Usuario:</label>
                                <select 
                                  *ngIf="roles"   
                                  class="form-control"
                                  name="role"
                                  #role="ngModel"
                                  [(ngModel)]="invitation.role"
                                >
                                    <option *ngFor="let role of roles" value="{{role._id}}">{{role.name}}</option>
                                </select>
                              </div>
                              <div class="modal-footer">
                                <button type="button" #closebutton class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                                <button type="submit"class="btn btn-primary" id="closeModal">Enviar Invitaci&oacute;n</button>
                              </div>
                            </form>
                          </div>
                         
                        </div>
                      </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-bordered" id="datatableexample" width="100%" cellspacing="0">
                    <thead>
                        <tr>
                            <th>Foto</th>
                            <th>Nombre</th>
                            <th>Tel&eacute;fono</th>
                            <th>Rol</th>
                            <th>Fecha</th>
                            <th>Estatus</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tfoot>
                        <tr>
                            <th>Foto</th>
                            <th>Nombre</th>
                            <th>Tel&eacute;fono</th>
                            <th>Rol</th>
                            <th>Fecha</th>
                            <th>Estatus</th>
                            <th></th>
                        </tr>
                    </tfoot>
                    <tbody>
                        <tr *ngFor="let user of data">
                            <td *ngIf="user.img"><img width="32" src="{{ url +'get-image-user/' + user.img }}" class="rounded-circle"></td>
                            <td *ngIf="!user.img"><img width="32" src="/assets/img/undraw_profile.svg" class="rounded-circle"></td>
                            <td>{{user.name}} {{user.surname}}</td>
                            <td>{{ user.phone}}</td>
                            <td>{{ user.role.name}}</td>
                            <td>{{ (user.createdAt | amFromUnix) | amLocale:'es' | amTimeAgo}}</td>
                            <td *ngIf="user.status =='ACTIVE'"><span class="badge badge-success">Activo</span></td>
                            <td *ngIf="user.status =='INACTIVE'"><span class="badge badge-secondary">Inactivo</span></td>
                            <td>
                                <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#editUser" (click)="getUser(user._id)"><i class="fas fa-edit"></i></button>

                                <!-- Modal -->
                                <div class="modal fade" id="editUser" tabindex="-1" role="dialog" aria-labelledby="editUserLabel" aria-hidden="true">
                                  <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h5 class="modal-title" id="editUserLabel">Editar Usuario</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div class="modal-body">
                                        <form *ngIf="userToUpdate">
                                            <div class="form-group">
                                                <label for="userImage">Imagen:<p class="text-secondary">(La imagen debe ser de 400x400 pixeles, en formato JPG, no mayor a 100kb)</p>  </label>
                                                <input 
                                                  type="file" 
                                                  (change)="fileChangeEvent($event)"
                                                  class="form-control-file" 
                                                  id="userImage">
                                            </div>

                                          <div class="form-group">
                                            <label for="userEmail" class="col-form-label">Correo electr&oacute;nico:</label>
                                            <input 
                                              type="text" 
                                              class="form-control" 
                                              id="userEmail"
                                              name="email"
                                              #email="ngModel"
                                              [(ngModel)]="userToUpdate.email"
                                            >
                                          </div>

                                          <div class="form-group">
                                            <label for="userName" class="col-form-label">Nombre:</label>
                                            <input 
                                              type="text" 
                                              class="form-control" 
                                              id="userName"
                                              name="name"
                                              #name="ngModel"
                                              [(ngModel)]="userToUpdate.name"
                                            >
                                          </div>

                                          <div class="form-group">
                                            <label for="userLastName" class="col-form-label">Apellido:</label>
                                            <input 
                                              type="text" 
                                              class="form-control" 
                                              id="userLastName"
                                              name="surname"
                                              #surname="ngModel"
                                              [(ngModel)]="userToUpdate.surname"
                                            >
                                          </div>

                                          <div class="form-group">
                                            <label for="userPhone" class="col-form-label">Tel&eacute;fono:</label>
                                            <input 
                                              type="text" 
                                              class="form-control" 
                                              id="userPhone"
                                              name="phone"
                                              #phone="ngModel"
                                              [(ngModel)]="userToUpdate.phone"
                                            >
                                          </div>

                                          <div class="form-group">
                                            <label for="userRol" class="col-form-label">Selecciona el Rol:</label>
                                            <select 
                                              class="form-control" 
                                              id="userRol"name="role"
                                              #role="ngModel"
                                              [(ngModel)]="userToUpdate.role._id"
                                            >
                                              <option  *ngFor="let role of roles" value="{{role._id}}">
                                                {{role.name}}
                                              </option>
                                            </select>
                                          </div>
                                        </form>
                                      </div>
                                      <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" #closebutton2  data-dismiss="modal">Cerrar</button>
                                        <button type="button" class="btn btn-primary"  (click)="updateUser()" data-dismiss="modal">Guardar Cambios</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <button type="button" class="btn btn-danger" data-toggle="modal" data-target="#deleteUser" (click)="getUserToDelete(user._id)"><i class="far fa-trash-alt"></i></button>

                                <!-- Modal -->
                                <div class="modal fade" id="deleteUser" tabindex="-1" role="dialog" aria-labelledby="deleteUserLabel" aria-hidden="true">
                                  <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h5 class="modal-title" id="deleteUserLabel">Eliminar Usuario</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div class="modal-body">
                                        <p>¿Estás seguro de eliminar este usuario?</p>
                                      </div>
                                      <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="deleteUser()">Eliminar</button>
                                        <button type="button" class="btn btn-primary" #closebutton3  data-dismiss="modal">Salir</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

<!-- </div> -->
<!-- /.container-fluid -->