import { Component, OnInit, DoCheck  } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
//import * as $ from 'jquery';
//import  $ from 'jQuery';

import { User } from './components/users/models/user';
import { UserService } from './components/users/services/user.service';
import { GLOBAL } from './services/global';
import { Title }  from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [UserService]
})
export class AppComponent implements OnInit, DoCheck  {
  //title = 'odt';
  public title: string;
  public identity;
  public url: string;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService:UserService,
    private titleService: Title,
  ){
    this.title = 'Login';
    this.url = GLOBAL.url;
    this.titleService.setTitle( 'ODT' );
  }

  ngOnInit(){
    this.identity = this._userService.getIdentity();
  }

  //Para verificar si los datos cambian
  ngDoCheck(){
    this.identity = this._userService.getIdentity();
  }

  logout(){
    localStorage.clear();
    this.identity = null;
    this._router.navigate(['/']);
  }

}
