import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { User } from '../../../users/models/user';
import { UserService } from '../../../users/services/user.service';
import { Invitation  } from '../../../users/models/invitation';
import { InvitationService } from '../../../users/services/invitation.service';
import { Role  } from '../../../roles/models/role';
import { RoleService } from '../../../roles/services/role.service';
import { GLOBAL } from '../../../../services/global';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
    selector: 'userList',
    templateUrl: './userList.component.html',
    styleUrls: ['./userList.component.scss'],
    providers: [UserService, InvitationService, RoleService]
})

export class UserListComponent implements OnInit{
    @ViewChild('closebutton') closebutton;
    @ViewChild('closebutton2') closebutton2;
    @ViewChild('closebutton3') closebutton3;

    @ViewChild(DataTableDirective, {static: false})
    dtElement: DataTableDirective;
//   dtOptions: DataTables.Settings = {};
    isDtInitialized:boolean = false;
    dtTrigger: Subject<any> = new Subject();



    public title: string;
    public user: User;
    public userToUpdate: User;
    public userToDelete: User;
    public data: User[];
    public url: String;
    public identity;
    public token;
    public statusInvitation: string;
    public statusUserEdit: string;
    public statusUserDelete: string;
    public statusGetRoles: string;
    public statusGetUsers: string;
    public statusGetUser: string;

    public invitation: Invitation;

    public role: Role;
    public roles: Role[];
    


    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _userService: UserService,
        private _roleService: RoleService,
        private _invitationService: InvitationService
    ){
        this.title = 'Listado de Usuarios';
        this.url = GLOBAL.url;
        this.user = new User("","","","","","",false,"","","","","");
        this.invitation = new Invitation("","","","","");
        this.identity = this._userService.getIdentity();
        this.token = this._userService.getToken();
    }

    ngOnInit(){
        this.getRoles();
        this.getUsers(false);
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next();
      }
    
      ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.dtTrigger.unsubscribe();
      }
    
      rerender(): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          // Destroy the table first
          dtInstance.destroy();
          // Call the dtTrigger to rerender again
          this.dtTrigger.next();
        });
      }

    getUsers(updated:boolean){
        this._userService.getUsers().subscribe(
            response => {
                if(!response.users){
                    this.statusGetUsers = 'error';
                }else{
                    this.data = response.users;
                    //console.log(this.data);
                    if(updated){
                        // this.rerender();
                        // this.ngAfterViewInit();

                        var table = $('#datatableexample').DataTable()
                        table.destroy();
                        setTimeout(()=>{                          
                            $('#datatableexample').DataTable( {
                                destroy: true,
                              dom: 'lpftrip',
                              pagingType: 'full_numbers',
                              pageLength: 20,
                              processing: true,
                              lengthMenu : [20, 50, 100],
                              order:[[1,"desc"]],
                              language: {
                                processing: "Procesando...",
                                search: "Buscar:",
                                lengthMenu: "Mostrar _MENU_ &eacute;l&eacute;ments",
                                info: "Mostrando desde _START_ al _END_ de _TOTAL_ elementos",
                                infoEmpty: "Mostrando ningún elemento.",
                                infoFiltered: "(filtrado _MAX_ elementos total)",
                                infoPostFix: "",
                                loadingRecords: "Cargando registros...",
                                zeroRecords: "No se encontraron registros",
                                emptyTable: "No hay datos disponibles en la tabla",
                                paginate: {
                                  first: "Primero",
                                  previous: "Anterior",
                                  next: "Siguiente",
                                  last: "Último"
                                },
                                aria: {
                                  sortAscending: ": Activar para ordenar la tabla en orden ascendente",
                                  sortDescending: ": Activar para ordenar la tabla en orden descendente"
                                }}
                          } );
                          }, 1);
                        //   this.ngOnInit();
                    }else{
                        setTimeout(()=>{                          
                            $('#datatableexample').DataTable( {
                                destroy: true,
                              dom: 'lpftrip',
                              pagingType: 'full_numbers',
                              pageLength: 20,
                              processing: true,
                              lengthMenu : [20, 50, 100],
                              order:[[1,"desc"]],
                              language: {
                                processing: "Procesando...",
                                search: "Buscar:",
                                lengthMenu: "Mostrar _MENU_ Elementos",
                                info: "Mostrando desde _START_ al _END_ de _TOTAL_ elementos",
                                infoEmpty: "Mostrando ningún elemento.",
                                infoFiltered: "(filtrado _MAX_ elementos total)",
                                infoPostFix: "",
                                loadingRecords: "Cargando registros...",
                                zeroRecords: "No se encontraron registros",
                                emptyTable: "No hay datos disponibles en la tabla",
                                paginate: {
                                  first: "Primero",
                                  previous: "Anterior",
                                  next: "Siguiente",
                                  last: "Último"
                                },
                                aria: {
                                  sortAscending: ": Activar para ordenar la tabla en orden ascendente",
                                  sortDescending: ": Activar para ordenar la tabla en orden descendente"
                                }}
                          } );
                          }, 1);
                    }
                    
                }
            },
            error => {
                var errorMessage = <any>error;
               // console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetUsers = 'error';
                }
            }
        );
    }
    
    getRoles(){
      this._roleService.getRoles(this.token).subscribe(
        response => {
            if(!response.roles){
                this.statusGetRoles = 'error';
            }else{
                this.roles = response.roles;
                //console.log(this.roles); 
            }
        },
        error => {
            var errorMessage = <any>error;
            //console.log(errorMessage);
    
            if(errorMessage != null){
                this.statusGetRoles = 'error';
            }
        }
        );
    }

    onSubmit(invitationForm:any) {
        this.closebutton.nativeElement.click();
        console.log("enviando");
        this._invitationService.sendInvitation(this.invitation, this.token).subscribe(
            response => {
               // this.identity = response.data;
                //console.log(response);
                if(!response.data){
                    invitationForm.reset();
                    this.statusInvitation = 'error';
                    this.showAlertCreate = true;
                    //console.log('Error al guardar el token');
                }else{
                  this.statusInvitation = 'success';
                  this.showAlertCreateS = true;
                  invitationForm.reset();
                  //console.log("response");
                  //console.log(response);
                  this.getUsers(true)
                }
  
            },error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
  
                if(errorMessage != null){
                    this.statusInvitation = 'error';
                }
            }
        );
    }

    getUser(idUser:String){
        this._userService.getUser(idUser).subscribe(
            response => {
                if(!response.user){
                    this.statusGetUser = 'error';
                }else{
            
                    this.userToUpdate = response.user;
                    //(this.userToUpdate);
                   
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
        
                if(errorMessage != null){
                    this.statusGetUser = 'error';
                }
            }
        );

    }

    getUserToDelete(idUser:String){
        this._userService.getUser(idUser).subscribe(
            response => {
                if(!response.user){
                    this.statusGetUser = 'error';
                }else{
            
                    this.userToDelete = response.user;
                    //console.log(this.userToDelete);
                   
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
        
                if(errorMessage != null){
                    this.statusGetUser = 'error';
                }
            }
        );

    }

    updateUser(){
        this.closebutton2.nativeElement.click();
        this._userService.updateUser(this.userToUpdate).subscribe(
            response => {
                if(!response.user){
                    this.statusUserEdit = 'error';
                    this.showAlertEdit = true;
                }else{
            
                    //this.user = response.user;;
                    // this.ngAfterViewInit();
                    // this.getUsers(true);
                    this.statusUserEdit = 'success';
                    this.showAlertEditS = true;
                    this.getUsers(true);
                    //console.log(this.user);
                    // this._router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                    //     this._router.navigate(['user/list']);
                    // }); 
                    
    
                    //SUBIDA DE IMAGEN
                    if(this.filesToUpload && this.filesToUpload.length)
                    {
                        this._userService.makeFileRequest(this.url+'upload-image-user/'+this.userToUpdate._id,[], this.filesToUpload, this.token, 'img')
                                        .then((result: any) =>{
                                                this.user.img = result.user.img;
                                                // this.ngAfterViewInit();
                                                this.getUsers(true);
                                               
                                                //localStorage.setItem('identity', JSON.stringify(this.user));
                                        });
                                        this.statusUserEdit = 'success';
                                        // this._router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                                        //     this._router.navigate(['user/list']);
                                        // }); 
                                        // this.getUsers(true)
                    }else{
                        //localStorage.setItem('identity', JSON.stringify(this.user));
                        //this._router.navigate(['/home']);
                    }
                   
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
        
                if(errorMessage != null){
                    this.statusUserEdit = 'error';
                }
            }
        );
    }
  
    public filesToUpload: Array<File>;
    fileChangeEvent(fileInput: any){
        this.filesToUpload = <Array<File>>fileInput.target.files;
        //console.log(this.filesToUpload)
    }

    deleteUser(){
        this.closebutton3.nativeElement.click();
        this._userService.inactiveUser(this.userToDelete._id).subscribe(
            response => {
               // this.identity = response.data;
                //console.log(response);
                if(!response.data){
                    this.statusUserDelete = 'error';
                    this.showAlertDelete = true;
                }else{
                  //console.log("response");
                  //console.log(response);
                  this.statusUserDelete = 'success';
                  this.showAlertDeleteS = true;

                //   this.ngAfterViewInit();
                this.getUsers(true)
                // this._router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                //     this._router.navigate(['user/list']);
                // }); 
                }
      
            },error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
      
                if(errorMessage != null){
                    this.statusUserDelete = 'error';
                }
            }
        );
    }


    public showAlertCreateS: Boolean
    closeCrearS(){
        this.showAlertCreateS = false;
    }

    public showAlertCreate: Boolean
    closeCrear(){
        this.showAlertCreate = false;
    }

    public showAlertEditS: Boolean
    closeEditS(){
        this.showAlertEditS = false;
    }

    public showAlertEdit: Boolean
    closeEdit(){
        this.showAlertEdit = false;
    }


    public showAlertDeleteS: Boolean
    closeDeleteS(){
        this.showAlertDeleteS = false;
    }

    public showAlertDelete: Boolean
    closeDelete(){
        this.showAlertDelete = false;
    }


}
