import {Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GLOBAL } from '../../../../../services/global';
import { PropertyType } from '../models/propertyType';

@Injectable()
export class PropertyTypeService{
    public url:string;
    public identity:any;
    public token:any;
 

    constructor(public _http: HttpClient){
        this.url = GLOBAL.url;
    }


    getPropertyTypes(token:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

                                       
        return this._http.get(this.url+'property-types/', {headers: headers});
    }

    getPropertyType(addressType:any, token:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

                                       
        return this._http.get(this.url+'property-type/'+addressType, {headers: headers});
    }

    savePropertyType(addressType:any,token:any ): Observable<any>{
        
        let params = JSON.stringify(addressType);
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                       .set('Authorization',token);

        return this._http.post(this.url+'property-type',params, {headers:headers});
    }

    updatePropertyType(addressType:any,token:any ): Observable<any>{
        
        let params = JSON.stringify(addressType);
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                       .set('Authorization',token);

        return this._http.put(this.url+'property-type/'+addressType._id,params, {headers:headers});
    }

    deletePropertyType(addressType:any,token:any ): Observable<any>{
        
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                        .set('Authorization',token);

        return this._http.delete(this.url+'property-type/'+addressType, {headers:headers});
    }

    activePropertyType(addressType:any,token:any ): Observable<any>{
        
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                       .set('Authorization',token);

        return this._http.post(this.url+'property-type'+addressType._id, {headers:headers});
    }

    makeFileRequest(
        url: string,
        params: Array<string>,
        files: Array<File>,
        token: string,
        name: string
      ) {
        return new Promise(function (resolve, reject) {
          var formData: any = new FormData();
          var xhr = new XMLHttpRequest();
    
          for (var i = 0; i < files.length; i++) {
            formData.append(name, files[i], files[i].name);
          }
    
          xhr.onreadystatechange = function () {
            if (xhr.readyState == 4) {
              if (xhr.status == 200) {
                resolve(JSON.parse(xhr.response));
              } else {
                reject(xhr.response);
              }
            }
          };
    
          xhr.open('POST', url, true);
          xhr.setRequestHeader('Authorization', token);
          xhr.send(formData);
        });
      }

}