import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from '../../../../users/services/user.service';
import { Company } from '../models/company';
import { CompanyService } from '../services/company.service'
import { GLOBAL } from '../../../../../services/global';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
    selector: 'company',
    templateUrl: './company.component.html',
    styleUrls: ['./company.component.scss'],
    providers: [UserService, CompanyService]
})

export class CompanyComponent implements OnInit{
    @ViewChild('closebutton') closebutton;
    @ViewChild('closebutton2') closebutton2;
    @ViewChild('closebutton3') closebutton3;

    @ViewChild(DataTableDirective, {static: false})
    dtElement: DataTableDirective;
//   dtOptions: DataTables.Settings = {};
    isDtInitialized:boolean = false;
    dtTrigger: Subject<any> = new Subject();


    public title: string;
    public propertyType: Company;
    public propertyToUpdate: Company;
    public propertyToDelete: Company;
    public data: Company[];
    public url: String;
    public identity;
    public token;
    public statusCreate: string;
    public statusEdit: string;
    public statusDelete: string;
    public statusGetPropertys: string;
    public statusGetProperty: string;


    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _userService: UserService,
        private _propertyTypeService: CompanyService,
    ){
        this.title = 'Listado de Usuarios';
        this.url = GLOBAL.url;
        this.propertyType = new Company("","","","");
        this.identity = this._userService.getIdentity();
        this.token = this._userService.getToken();
    }

    ngOnInit(){
        this.getPropertys(false);
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next();
      }
    
      ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.dtTrigger.unsubscribe();
      }
    
      rerender(): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          // Destroy the table first
          dtInstance.destroy();
          // Call the dtTrigger to rerender again
          this.dtTrigger.next();
        });
      }

    getPropertys(updated:boolean){
        this._propertyTypeService.getCompanies(this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetPropertys = 'error';
                }else{
                    this.data = response.data;
                    //console.log(this.data);

                    if(updated){
                        // this.rerender();
                        // this.ngAfterViewInit();
                        var table = $('#datatableexample').DataTable()
                        table.destroy();
                        setTimeout(()=>{                          
                            $('#datatableexample').DataTable( {
                              dom: 'lpftrip',
                              pagingType: 'full_numbers',
                              pageLength: 20,
                              processing: true,
                              lengthMenu : [20, 50, 100],
                              order:[[1,"desc"]],
                              language: {
                                processing: "Procesando...",
                                search: "Buscar:",
                                lengthMenu: "Mostrar _MENU_ &eacute;l&eacute;ments",
                                info: "Mostrando desde _START_ al _END_ de _TOTAL_ elementos",
                                infoEmpty: "Mostrando ningún elemento.",
                                infoFiltered: "(filtrado _MAX_ elementos total)",
                                infoPostFix: "",
                                loadingRecords: "Cargando registros...",
                                zeroRecords: "No se encontraron registros",
                                emptyTable: "No hay datos disponibles en la tabla",
                                paginate: {
                                  first: "Primero",
                                  previous: "Anterior",
                                  next: "Siguiente",
                                  last: "Último"
                                },
                                aria: {
                                  sortAscending: ": Activar para ordenar la tabla en orden ascendente",
                                  sortDescending: ": Activar para ordenar la tabla en orden descendente"
                                }}
                          } );
                          }, 1);
                          //this.ngOnInit();
                    }else{
                        setTimeout(()=>{                          
                            $('#datatableexample').DataTable( {
                              dom: 'lpftrip',
                              pagingType: 'full_numbers',
                              pageLength: 20,
                              processing: true,
                              lengthMenu : [20, 25, 100],
                              order:[[1,"desc"]],
                              language: {
                                processing: "Procesando...",
                                search: "Buscar:",
                                lengthMenu: "Mostrar _MENU_ elementos",
                                info: "Mostrando desde _START_ al _END_ de _TOTAL_ elementos",
                                infoEmpty: "Mostrando ningún elemento.",
                                infoFiltered: "(filtrado _MAX_ elementos total)",
                                infoPostFix: "",
                                loadingRecords: "Cargando registros...",
                                zeroRecords: "No se encontraron registros",
                                emptyTable: "No hay datos disponibles en la tabla",
                                paginate: {
                                  first: "Primero",
                                  previous: "Anterior",
                                  next: "Siguiente",
                                  last: "Último"
                                },
                                aria: {
                                  sortAscending: ": Activar para ordenar la tabla en orden ascendente",
                                  sortDescending: ": Activar para ordenar la tabla en orden descendente"
                                }}
                          } );
                          }, 1);
                    }
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetPropertys = 'error';
                }
            }
        );
    }

   

    onSubmit(saveForm:any) {
        this.closebutton.nativeElement.click();
        //console.log("enviando");
        this._propertyTypeService.saveCompany(this.propertyType, this.token).subscribe(
            response => {
                //console.log(response);
                if(!response.data){
                    saveForm.reset();
                    this.statusCreate = 'error';
                    this.showAlertCreate = true;
                    //console.log('Error al guardar el token');
                }else{
                  this.statusCreate = 'success';
                  this.showAlertCreateS = true;
                  saveForm.reset();
                  //console.log("response");
                  //console.log(response);
                  this.getPropertys(true);
                //   this._router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                //     this._router.navigate(['companies']);
                //   }); 
                }
  
            },error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
  
                if(errorMessage != null){
                    this.statusCreate = 'error';
                }
            }
        );
    }

    getProperty(idUser:String){
        this._propertyTypeService.getCompany(idUser, this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetProperty = 'error';
                }else{
            
                    this.propertyToUpdate = response.data;
                   // console.log(this.propertyToUpdate);
                   
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
        
                if(errorMessage != null){
                    this.statusGetProperty = 'error';
                }
            }
        );

    }

    getPropertyToDelete(idUser:String){
        this._propertyTypeService.getCompany(idUser, this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetProperty = 'error';
                }else{
            
                    this.propertyToDelete = response.data;
                    //console.log(this.propertyToDelete);                   
                }
            },
            error => {
                var errorMessage = <any>error;
               // console.log(errorMessage);
        
                if(errorMessage != null){
                    this.statusGetProperty = 'error';
                }
            }
        );

    }

    updatePorperty(){
        this.closebutton2.nativeElement.click();
        this._propertyTypeService.updateCompany(this.propertyToUpdate, this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusEdit = 'error';
                    this.showAlertEdit = true;
                }else{
                    ;
                    this.statusEdit = 'success';
                    this.showAlertEditS = true;
                    this.getPropertys(true)
                    //console.log(this.propertyToUpdate);
                    // this._router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                    //     this._router.navigate(['companies']);
                    //   }); 
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
        
                if(errorMessage != null){
                    this.statusEdit = 'error';
                }
            }
        );
       
    }
  

    deleteProperty(){
        this.closebutton3.nativeElement.click();
        this._propertyTypeService.deleteCompany(this.propertyToDelete._id, this.token).subscribe(
            response => {
               // this.identity = response.data;
                //console.log(response);
                if(!response.data){
                    this.statusDelete = 'error';
                    this.showAlertDelete = true
                }else{
                 // console.log("response");
                 // console.log(response);
                  this.statusDelete = 'success';
                  this.showAlertDeleteS = true
                  this.getPropertys(true);
                //   this._router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                //     this._router.navigate(['companies']);
                //   }); 
                }
      
            },error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
      
                if(errorMessage != null){
                    this.statusDelete = 'error';
                }
            }
        );
        
    }


    public showAlertCreateS: Boolean
    closeCrearS(){
        this.showAlertCreateS = false;
    }

    public showAlertCreate: Boolean
    closeCrear(){
        this.showAlertCreate = false;
    }

    public showAlertEditS: Boolean
    closeEditS(){
        this.showAlertEditS = false;
    }

    public showAlertEdit: Boolean
    closeEdit(){
        this.showAlertEdit = false;
    }


    public showAlertDeleteS: Boolean
    closeDeleteS(){
        this.showAlertDeleteS = false;
    }

    public showAlertDelete: Boolean
    closeDelete(){
        this.showAlertDelete = false;
    }


}
