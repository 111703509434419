import {Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GLOBAL } from '../../../services/global';
import { Contact } from '../models/contact';

@Injectable()
export class ContactService{
    public url:string;
    public identity:any;
    public token:any;
 

    constructor(public _http: HttpClient){
        this.url = GLOBAL.url;
    }


    getContacts(token:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

                                       
        return this._http.get(this.url+'contacts/', {headers: headers});
    }

    getContactsToSelect(token:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

                                       
        return this._http.get(this.url+'contacts-select/', {headers: headers});
    }

    getContact(object:any, token:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

                                       
        return this._http.get(this.url+'contact/'+object, {headers: headers});
    }

    getContactStat(object:any, token:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

                                       
        return this._http.get(this.url+'contact-count/'+object, {headers: headers});
    }

    saveContact(object:any,token:any ): Observable<any>{
        
        let params = JSON.stringify(object);
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                       .set('Authorization',token);

        return this._http.post(this.url+'contact',params, {headers:headers});
    }

    updateContact(object:any,token:any ): Observable<any>{
        
        let params = JSON.stringify(object);
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                       .set('Authorization',token);

        return this._http.put(this.url+'contact/'+object._id,params, {headers:headers});
    }

    deleteContact(object:any,token:any ): Observable<any>{
        
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                        .set('Authorization',token);

        return this._http.delete(this.url+'contact/'+object, {headers:headers});
    }

    activeContact(object:any,token:any ): Observable<any>{
        
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                       .set('Authorization',token);

        return this._http.post(this.url+'contact'+object._id, {headers:headers});
    }

}