import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { User } from '../../../users/models/user';
import { UserService } from '../../../users/services/user.service';
import { GLOBAL } from '../../../../services/global';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    providers: [UserService]
})

export class LoginComponent implements OnInit{
    public title: string;
    public user: User;
    public url: String;
    public identity;
    public token;
    public status: string;

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _userService: UserService
    ){
        this.title = 'Inicio de Sesión';
        this.url = GLOBAL.url;
        this.user = new User("","","","","","",false,"","","","","");
        this.identity = this._userService.getIdentity();
        this.token = this._userService.getToken();
    }

    ngOnInit(){

    }

    onSubmit(loginForm){
        //Loguear al usuario y conseguir datos
        console.log(this.user);
        this._userService.signup(this.user).subscribe(
            response => {
                this.identity = response.user;

                if(!this.identity || !this.identity._id){
                    this.status = 'error';
                }else{
                    //GUARDAR DATOS DE USUARIO EN LOCALSTORAGE
                    localStorage.setItem('identity', JSON.stringify(this.identity));

                    //conseguir el token
                    this.getToken();
                }

            },error => {
                var errorMessage = <any>error;
                console.log(errorMessage);

                if(errorMessage != null){
                    this.status = 'error';
                }
            }
        );
    }

    getToken(){
        this._userService.signup(this.user, 'true').subscribe(
            response => {
                this.token = response.token;

                if(this.token.length <= 0){
                    this.status = 'error';
                }else{
                    //GUARDAR TOKEN DE USUARIO  EN LOCAL STORAGE
                    localStorage.setItem('token', this.token);

                    //rederigir a home
                    this._router.navigate(['/dashboard']);

                    //conseguir contadores o estadisticas del usuario
                   // this.getCounters();

                }

            },error => {
                var errorMessage = <any>error;
                console.log(errorMessage);

                if(errorMessage != null){
                    this.status = 'error';
                }
            }
        );

    }

    

}
