import { Injectable } from '@angular/core';
import { Router, CanActivate }  from '@angular/router';
import { UserService } from '../components/users/services/user.service';

@Injectable()
export class SecurityGuard implements CanActivate{

    constructor(
        private _router: Router,
        private _userService: UserService
    ){}

    canActivate(){
        let identity = this._userService.getIdentity();

        if(identity && (identity.status == 'ACTIVE' || identity._id)){
            return true;
        }else{
            this._router.navigate(['/login']);
        }
    }
}