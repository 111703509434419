<body class="bg-gradient-primary">

    <div class="container">

        <div class="card o-hidden border-0 shadow-lg my-5">
            <div class="card-body p-0">
                <!-- Nested Row within Card Body -->
                <div class="row">
                    <div class="col-lg-5 d-none d-lg-block bg-register-image"></div>
                    <div class="col-lg-7">
                        <div class="p-5">
                            <div class="text-center">
                                <img style="margin-bottom:20px;" width="200" src="assets/img/logo-odt.png" />
                                <h1 class="h4 text-gray-900 mb-4">¡Crear una cuenta!</h1>

                                <div class="alert alert-danger" role="alert" *ngIf="status=='error'">Algunos de los datos son incorrectos.</div>
                                <div class="alert alert-danger" role="alert" *ngIf="statusToken=='error'">El TOKEN es incorrecto.</div>
                                <div class="alert alert-danger" role="alert" *ngIf="statusPassword=='error'">Las contrase&ntilde;as no coinciden.</div>
                            </div>
                            <form #registerForm="ngForm" #registerForm="ngForm" (ngSubmit)="onSubmit(registerForm)" class="user">
                                <div class="form-group row">
                                    <div class="col-sm-6 mb-3 mb-sm-0">
                                        <input 
                                            type="text" 
                                            class="form-control form-control-user" 
                                            id="firstName"
                                            name="name"
                                            #name="ngModel"
                                            [(ngModel)]="user.name"
                                            placeholder="Ingresa tu primer nombre"
                                        >
                                    </div>
                                    <div class="col-sm-6">
                                        <input 
                                            type="text" 
                                            class="form-control form-control-user" 
                                            id="lastName"
                                            name="surname"
                                            #surname="ngModel"
                                            [(ngModel)]="user.surname"
                                            placeholder="Ingresa tu primer apellido">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input 
                                        type="email" 
                                        class="form-control form-control-user" 
                                        id="inputEmail"
                                        name="email"
                                        #email="ngModel"
                                        [(ngModel)]="user.email"
                                        placeholder="Correo electr&oacute;nico">
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6 mb-3 mb-sm-0">
                                        <input 
                                            type="password" 
                                            class="form-control form-control-user"
                                            id="inputPassword" 
                                            name="password"
                                            #password="ngModel"
                                            [(ngModel)]="user.password"
                                            placeholder="Contrase&ntilde;a">
                                    </div>
                                    <div class="col-sm-6">
                                        <input 
                                            type="password" 
                                            class="form-control form-control-user"
                                            id="repeatPassword" 
                                            name="PasswordConfirm"
                                            [(ngModel)]="PasswordConfirm"
                                            placeholder="Confirmar Contrase&ntilde;a">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input 
                                        type="text" 
                                        class="form-control form-control-user" 
                                        id="inputToken"
                                        name="token"
                                        #token="ngModel"
                                        [(ngModel)]="user.token"
                                        class="form-control bg-light form-control"
                                        placeholder="Ingresa tu token">
                                </div>
                                <!-- <a href="login.html" class="btn btn-primary btn-user btn-block">
                                    Registrarme
                                </a> -->
                                <input type="submit" class="btn btn-primary btn-user btn-block" value="Registrarme">
                            </form>
                            <hr>
                            <div class="text-center">
                                <a  routerLink="/forgot-password" class="small">¿Has olvidado tu contrase&ntilde;a?</a>
                            </div>
                            <div class="text-center">
                                ¿Ya tienes una cuenta? <a routerLink="/login" class="small">Inicia sesi&oacute;n aqu&iacute;</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>