import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from '../../../../users/services/user.service';
import { PropertyType } from '../models/propertyType';
import { PropertyTypeService } from '../services/propertyTypes.service'
import { GLOBAL } from '../../../../../services/global';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
    selector: 'propertyType',
    templateUrl: './propertyType.component.html',
    styleUrls: ['./propertyType.component.scss'],
    providers: [UserService, PropertyTypeService]
})

export class PropertyTypeComponent implements OnInit{
    @ViewChild('closebutton') closebutton;
    @ViewChild('closebutton2') closebutton2;
    @ViewChild('closebutton3') closebutton3;

    @ViewChild(DataTableDirective, {static: false})
    dtElement: DataTableDirective;
//   dtOptions: DataTables.Settings = {};
    isDtInitialized:boolean = false;
    dtTrigger: Subject<any> = new Subject();


    public title: string;
    public propertyType: PropertyType;
    public propertyToUpdate: PropertyType;
    public propertyToDelete: PropertyType;
    public data: PropertyType[];
    public url: String;
    public identity;
    public token;
    public statusCreate: string;
    public statusEdit: string;
    public statusDelete: string;
    public statusGetPropertys: string;
    public statusGetProperty: string;


    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _userService: UserService,
        private _propertyTypeService: PropertyTypeService,
    ){
        this.title = 'Listado de Usuarios';
        this.url = GLOBAL.url;
        this.propertyType = new PropertyType("","","","","");
        this.identity = this._userService.getIdentity();
        this.token = this._userService.getToken();
    }

    ngOnInit(){
        this.getPropertys(false);
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next();
      }
    
      ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.dtTrigger.unsubscribe();
      }
    
      rerender(): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          // Destroy the table first
          dtInstance.destroy();
          // Call the dtTrigger to rerender again
          this.dtTrigger.next();
        });
      }

    getPropertys(updated:boolean){
        this._propertyTypeService.getPropertyTypes(this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetPropertys = 'error';
                }else{
                    this.data = response.data;
                    ////console.log(this.data);

                    if(updated){
                        //this.rerender();
                        //this.ngAfterViewInit();
                        var table = $('#datatableexample').DataTable()
                        table.destroy();
                        
                        setTimeout(()=>{                          
                            $('#datatableexample').DataTable( {
                              dom: 'lpftrip',
                              pagingType: 'full_numbers',
                              pageLength: 20,
                              processing: true,
                              lengthMenu : [20, 25, 35],
                              order:[[1,"desc"]],
                              language: {
                                processing: "Procesando...",
                                search: "Buscar:",
                                lengthMenu: "Mostrar _MENU_ &eacute;l&eacute;ments",
                                info: "Mostrando desde _START_ al _END_ de _TOTAL_ elementos",
                                infoEmpty: "Mostrando ningún elemento.",
                                infoFiltered: "(filtrado _MAX_ elementos total)",
                                infoPostFix: "",
                                loadingRecords: "Cargando registros...",
                                zeroRecords: "No se encontraron registros",
                                emptyTable: "No hay datos disponibles en la tabla",
                                paginate: {
                                  first: "Primero",
                                  previous: "Anterior",
                                  next: "Siguiente",
                                  last: "Último"
                                },
                                aria: {
                                  sortAscending: ": Activar para ordenar la tabla en orden ascendente",
                                  sortDescending: ": Activar para ordenar la tabla en orden descendente"
                                }}
                          } );
                          }, 1);
                          //this.ngOnInit();
                    }else{
                        setTimeout(()=>{                          
                            $('#datatableexample').DataTable( {
                              dom: 'lpftrip',
                              pagingType: 'full_numbers',
                              pageLength: 20,
                              processing: true,
                              lengthMenu : [20, 25, 100],
                              order:[[1,"desc"]],
                              language: {
                                processing: "Procesando...",
                                search: "Buscar:",
                                lengthMenu: "Mostrar _MENU_ elementos",
                                info: "Mostrando desde _START_ al _END_ de _TOTAL_ elementos",
                                infoEmpty: "Mostrando ningún elemento.",
                                infoFiltered: "(filtrado _MAX_ elementos total)",
                                infoPostFix: "",
                                loadingRecords: "Cargando registros...",
                                zeroRecords: "No se encontraron registros",
                                emptyTable: "No hay datos disponibles en la tabla",
                                paginate: {
                                  first: "Primero",
                                  previous: "Anterior",
                                  next: "Siguiente",
                                  last: "Último"
                                },
                                aria: {
                                  sortAscending: ": Activar para ordenar la tabla en orden ascendente",
                                  sortDescending: ": Activar para ordenar la tabla en orden descendente"
                                }}
                          } );
                          }, 1);
                    }
                }
            },
            error => {
                var errorMessage = <any>error;
                ////console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetPropertys = 'error';
                }
            }
        );
    }

    public filesToUpload2: Array<File>;
  fileChangeEvent2(fileInput: any) {
    this.filesToUpload2 = <Array<File>>fileInput.target.files;
    //console.log(this.filesToUpload)
  }
   

    onSubmit(saveForm:any) {
        this.closebutton.nativeElement.click();
        ////console.log("enviando");
        this._propertyTypeService.savePropertyType(this.propertyType, this.token).subscribe(
            response => {
                ////console.log(response);
                if(!response.data){
                    saveForm.reset();
                    this.statusCreate = 'error';
                    this.showAlertCreate = true;
                    // ////console.log('Error al guardar el token');
                }else{
                  this.statusCreate = 'success';
                  this.showAlertCreateS = true;
                  saveForm.reset();
                  ////console.log("response");
                  ////console.log(response);
                  
                  this.getPropertys(true);
                //   this._router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                //     this._router.navigate(['property-types']);
                //   }); 

                if (this.filesToUpload2 && this.filesToUpload2.length) {
                    this._propertyTypeService
                      .makeFileRequest(
                        this.url + 'upload-image-ptype/' + response.data._id,
                        [],
                        this.filesToUpload2,
                        this.token,
                        'img'
                      )
                      .then((result: any) => {
                        this.propertyType.img = result.data.img;
                        // this.ngAfterViewInit();
                        // this.getUsers(true);
                        this.statusEdit = 'success';
                        this.getPropertys(true);
        
                      });
                    
                  }

                }
  
            },error => {
                var errorMessage = <any>error;
                ////console.log(errorMessage);
  
                if(errorMessage != null){
                    this.statusCreate = 'error';
                }
            }
        );
    }

    getProperty(idUser:String){
        this._propertyTypeService.getPropertyType(idUser, this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetProperty = 'error';
                }else{
            
                    this.propertyToUpdate = response.data;
                    ////console.log(this.propertyToUpdate);
                   
                }
            },
            error => {
                var errorMessage = <any>error;
                ////console.log(errorMessage);
        
                if(errorMessage != null){
                    this.statusGetProperty = 'error';
                }
            }
        );

    }

    getPropertyToDelete(idUser:String){
        this._propertyTypeService.getPropertyType(idUser, this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetProperty = 'error';
                }else{
            
                    this.propertyToDelete = response.data;
                    ////console.log(this.propertyToDelete);                   
                }
            },
            error => {
                var errorMessage = <any>error;
                ////console.log(errorMessage);
        
                if(errorMessage != null){
                    this.statusGetProperty = 'error';
                }
            }
        );

    }

    public filesToUpload: Array<File>;
    fileChangeEvent(fileInput: any) {
      this.filesToUpload = <Array<File>>fileInput.target.files;
      //console.log(this.filesToUpload)
    }

    updatePorperty(){
        this.closebutton2.nativeElement.click();
        this._propertyTypeService.updatePropertyType(this.propertyToUpdate, this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusEdit = 'error';
                    this.showAlertEdit = true;
                }else{
                    //this.getPropertys(true);
                    this.statusEdit = 'success';
                    this.showAlertEditS = true;
                    ////console.log(this.propertyToUpdate);
                    this.getPropertys(true);
                    // this._router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                    //     this._router.navigate(['property-types']);
                    //   }); 

                    if (this.filesToUpload && this.filesToUpload.length) {
                        this._propertyTypeService
                          .makeFileRequest(
                            this.url + 'upload-image-ptype/' + response.data._id,
                            [],
                            this.filesToUpload,
                            this.token,
                            'img'
                          )
                          .then((result: any) => {
                            this.propertyToUpdate.img = result.data.img;
                            // this.ngAfterViewInit();
                            // this.getUsers(true);
                            this.statusEdit = 'success';
                            this.getPropertys(true);
            
                          });
                        
                    }
    

                }
            },
            error => {
                var errorMessage = <any>error;
                ////console.log(errorMessage);
        
                if(errorMessage != null){
                    this.statusEdit = 'error';
                }
            }
        );
       
    }
  

    deleteProperty(){
        this.closebutton3.nativeElement.click();
        this._propertyTypeService.deletePropertyType(this.propertyToDelete._id, this.token).subscribe(
            response => {
               // this.identity = response.data;
                ////console.log(response);
                if(!response.data){
                    this.statusDelete = 'error';
                    this.showAlertDelete = true;
                }else{
                  ////console.log("response");
                  ////console.log(response);
                  this.statusDelete = 'success';
                  this.showAlertDeleteS = true;
                  //this.getPropertys(true);
                //   this._router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                //     this._router.navigate(['property-types']);
                //   }); 
                this.getPropertys(true);
                }
      
            },error => {
                var errorMessage = <any>error;
                ////console.log(errorMessage);
      
                if(errorMessage != null){
                    this.statusDelete = 'error';
                }
            }
        );
        
    }


    public showAlertCreateS: Boolean
    closeCrearS(){
        this.showAlertCreateS = false;
    }

    public showAlertCreate: Boolean
    closeCrear(){
        this.showAlertCreate = false;
    }

    public showAlertEditS: Boolean
    closeEditS(){
        this.showAlertEditS = false;
    }

    public showAlertEdit: Boolean
    closeEdit(){
        this.showAlertEdit = false;
    }


    public showAlertDeleteS: Boolean
    closeDeleteS(){
        this.showAlertDeleteS = false;
    }

    public showAlertDelete: Boolean
    closeDelete(){
        this.showAlertDelete = false;
    }


}
