export class Contact{
    constructor(
        public _id: string,
        public name: string,
        public nit: string,
        public email: string,
        public phone: string,
        public registration_date: string,
        public owner: string,
        public origin: string,
        public status: String,
        public isCompany:Boolean,
        public companyID: String
    ){}
}