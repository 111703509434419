<!-- <div class="container-fluid"> -->

    <!-- DataTales Example -->
    <div class="card shadow mb-4">
        <div class="card-header py-3">
            <div class="row">
                <div class="col-md-12">
                    <div class="alert alert-success" role="alert" *ngIf="statusCreate == 'success' && showAlertCreateS">Se ha creado el estatus satisfactoriamente.<button type="button" class="close" (click)="closeCrearS()" aria-label="Close" ><span aria-hidden="true">&times;</span></button></div>
                    <div class="alert alert-success" role="alert" *ngIf="statusEdit == 'success' && showAlertEditS">Se ha editado el estatus satisfactoriamente. <button type="button" class="close" (click)="closeEditS()" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
                    <div class="alert alert-success" role="alert" *ngIf="statusDelete == 'success' && showAlertDeleteS">Se ha eliminado el estatus satisfactoriamente. <button type="button" class="close" (click)="closeDeleteS()" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
                    <div class="alert alert-danger" role="alert" *ngIf="statusCreate == 'error' && showAlertCreate">No se ha podido crear el estatus, intenta m&aacute;s tarde. <button type="button" class="close" (click)="closeCrear()" aria-label="Close" ><span aria-hidden="true">&times;</span></button></div>
                    <div class="alert alert-danger" role="alert" *ngIf="statusEdit == 'error' && showAlertEdit">No se ha podido editar el estatus, intenta m&aacute;s tarde. <button type="button" class="close" (click)="closeEdit()" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
                    <div class="alert alert-danger" role="alert" *ngIf="statusDelete == 'error' && showAlertDelete">No se ha podido eliminar el estatus, intenta m&aacute;s tarde. <button type="button" class="close" (click)="closeDelete()" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-10">
                    <h6 class="m-0 font-weight-bold text-primary">Listado de Estatus</h6>
                    <p>Todos los Estatus del sistema.</p>
                </div>

                <div class="col-md-2 float-right">

                    <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#createType">
                      <i class="fas fa-user"></i> Crear Estatus
                    </button>

                    <!-- Modal -->
                    <div class="modal fade" id="createType" tabindex="-1" role="dialog" aria-labelledby="createTypeLabel" aria-hidden="true">
                      <div class="modal-dialog" role="document">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="createTypeLabel">Crear estatus</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <form #saveForm="ngForm" #saveForm="ngForm" (ngSubmit)="onSubmit(saveForm)">
                              <div class="form-group">
                                <label for="propertyTypeName" class="col-form-label">Nombre:  <small class="text-secondary">(Colocar en min&uacute;sculas, sin espacios ni tildes)</small> </label>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    id="propertyTypeName"
                                    name="name"
                                    #name="ngModel"
                                    [(ngModel)]="propertyStatus.name"
                                >
                                    
                              </div>

                              <div class="form-group">
                                <label for="propertyTypeLabel" class="col-form-label">Label:</label>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    id="propertyTypeLabel"
                                    name="label"
                                    #label="ngModel"
                                    [(ngModel)]="propertyStatus.label"
                                >
                              </div>

                              <div class="form-group">
                                <label for="statusBadge" class="col-form-label">Badge (Ingresa cualquiera de los siguientes badges: primary, secondary, success, danger, warning, info, light, dark):</label>
                                <input type="text" class="form-control" id="statusBadge" name="statusBadge"
                                #statusBadge="ngModel"
                                [(ngModel)]="propertyStatus.statusBadge">
                              </div>

                              <div class="modal-footer">
                                <button type="button" #closebutton class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                                <button type="submit"class="btn btn-primary" id="closeModal">Crear Estatus</button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-bordered" id="datatableexample" width="100%" cellspacing="0">
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Label</th>
                            <th>Badge</th>
                            <th>Estatus</th>
                            <th style="width:80px;"></th>
                        </tr>
                    </thead>
                    <tfoot>
                        <tr>
                            <th>Nombre</th>
                            <th>Label</th>
                            <th>Badge</th>
                            <th>Estatus</th>
                            <th style="width:80px;"></th>
                        </tr>
                    </tfoot>
                    <tbody>
                        <tr *ngFor="let status of allPropertyStatus">
                            <td>{{ status.name}}</td>
                            <td>{{ status.label}}</td>
                            <td *ngIf="validBadge(status.statusBadge)"><span class="badge badge-{{status.statusBadge}}">badge</span></td>
                            <td *ngIf="!validBadge(status.statusBadge)">No badge</td>
                            <td *ngIf="status.status =='ACTIVE'"><span class="badge badge-success">Activo</span></td>
                            <td *ngIf="status.status =='INACTIVE'"><span class="badge badge-secondary">Inactivo</span></td>
                            <td>
                                <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#editUser" (click)="getProperty(status._id)"><i class="fas fa-edit"></i></button>

                                <!-- Modal -->
                                <div class="modal fade" id="editUser" tabindex="-1" role="dialog" aria-labelledby="editUserLabel" aria-hidden="true">
                                  <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h5 class="modal-title" id="editUserLabel">Editar Tipo de Propiedad</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div class="modal-body">
                                        <form *ngIf="propertyStatusToUpdate">

                                            <div class="form-group">
                                                <label for="propertyTypeName" class="col-form-label">Nombre:</label>
                                                <input 
                                                    type="text" 
                                                    class="form-control" 
                                                    id="propertyTypeName"
                                                    name="name"
                                                    #name="ngModel"
                                                    [(ngModel)]="propertyStatusToUpdate.name"
                                                    readonly
                                                >
                                                    
                                              </div>
                
                                              <div class="form-group">
                                                <label for="propertyTypeLabel" class="col-form-label">Label:</label>
                                                <input 
                                                    type="text" 
                                                    class="form-control" 
                                                    id="propertyTypeLabel"
                                                    name="label"
                                                    #label="ngModel"
                                                    [(ngModel)]="propertyStatusToUpdate.label"
                                                >
                                              </div>

                                              <div class="form-group">
                                                <label for="statusBadge" class="col-form-label">Badge (Ingresa cualquiera de los siguientes badges: primary, secondary, success, danger, warning, info, light, dark):</label>
                                                <input type="text" class="form-control" id="statusBadge" name="statusBadge"
                                                #statusBadge="ngModel"
                                                [(ngModel)]="propertyStatusToUpdate.statusBadge">
                                              </div>

                                        </form>
                                      </div>
                                      <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" #closebutton2  data-dismiss="modal">Cerrar</button>
                                        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="updatePorperty()">Guardar Cambios</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <button type="button" class="btn btn-danger" data-toggle="modal" data-target="#deletePropertyType" (click)="getPropertyToDelete(status._id)"><i class="far fa-trash-alt"></i></button>

                                <!-- Modal -->
                                <div class="modal fade" id="deletePropertyType" tabindex="-1" role="dialog" aria-labelledby="deletePropertyTypeLabel" aria-hidden="true">
                                  <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h5 class="modal-title" id="deletePropertyTypeLabel">Eliminar Estatus</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div class="modal-body">
                                        <p>¿Estás seguro de eliminar este estatus?</p>
                                      </div>
                                      <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" (click)="deleteProperty()" data-dismiss="modal">Eliminar</button>
                                        <button type="button" class="btn btn-primary" #closebutton3  data-dismiss="modal">Salir</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

<!-- </div> -->