import {Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GLOBAL } from '../../../../../services/global';
import { Departament } from '../models/departament';

@Injectable()
export class DepartamentService{
    public url:string;
    public identity:any;
    public token:any;
 

    constructor(public _http: HttpClient){
        this.url = GLOBAL.url;
    }


    getDepartaments(token:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

                                       
        return this._http.get(this.url+'departaments/', {headers: headers});
    }

    getDepartament(addressType:any, token:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

                                       
        return this._http.get(this.url+'departament/'+addressType, {headers: headers});
    }

    saveDepartament(addressType:any,token:any ): Observable<any>{
        
        let params = JSON.stringify(addressType);
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                       .set('Authorization',token);

        return this._http.post(this.url+'departament',params, {headers:headers});
    }

    updateDepartament(addressType:any,token:any ): Observable<any>{
        
        let params = JSON.stringify(addressType);
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                       .set('Authorization',token);

        return this._http.put(this.url+'departament/'+addressType._id,params, {headers:headers});
    }

    deleteDepartament(addressType:any,token:any ): Observable<any>{
        
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                        .set('Authorization',token);

        return this._http.delete(this.url+'departament/'+addressType, {headers:headers});
    }

    activeDepartament(addressType:any,token:any ): Observable<any>{
        
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                       .set('Authorization',token);

        return this._http.post(this.url+'departament'+addressType._id, {headers:headers});
    }

}