<!-- Begin Page Content -->
<!-- <div class="container-fluid"> -->

  <div class="card shadow mb-4">
      <div class="card-header py-3">
        <div class="row">
            <div class="col-md-12">
                <h6 class="m-0 font-weight-bold text-primary">Filtro de Propiedades</h6>
                <p>Encuentra propiedades según el requerimiento del cliente.</p>
            </div>
        </div>
    </div>
    <div class="card-body">
        <form>
          <div class="row">
            
              <div class="col-2 form-group">
                <select id="propertyModule" name="propertyModule" class="form-control" 
                    #propertyModule="ngModel" [(ngModel)]="propertyToSearch.stage">
                  <option value=0> --Módulo-- </option>
                  <option value=1>Propiedad</option>
                  <option value=2>Proyecto</option>
                </select>
              </div>
              <div class="col-2 form-group">

                

                  <select id="propertyOperation" class="form-control" name="propertyOperation"
                      #propertyOperation="ngModel" [(ngModel)]="propertyToSearch.propertyOperation">
                      <option value=0> --Operaci&oacute;n-- </option>
                      <option value=1>Venta</option>
                      <option value=2>Renta</option>
                      <option value=0>Venta y Renta</option>
                  </select>
              </div>

              <div class="col-2 form-group">
                  <select id="propertyType" class="form-control" name="propertyType" #propertyType="ngModel"
                      [(ngModel)]="propertyToSearch.propertyType">
                      <option value=""> --Tipo-- </option>
                      <option *ngFor="let type of propertyCatalogueTypes" value="{{type.name}}">{{type.label}}</option>
                  </select>
              </div>

              <div class="col-3 form-group">
                  <select id="propertyState" class="form-control" name="propertyState" #propertyState="ngModel"
                      [(ngModel)]="propertyToSearch.propertyState">
                      <option value=""> --Departamento-- </option>
                      <option *ngFor="let state of propertyCatalogueStates" value="{{state.name}}">{{state.label}}</option>
                  </select>
              </div>

              <div class="col-3 form-group">
                  <select id="propertyLocation" class="form-control" name="propertyLocation" #propertyLocation="ngModel"
                  [(ngModel)]="propertyToSearch.propertyLocation">
                      <option value=""> --Sector / Municipio-- </option>
                      <option *ngFor="let location of propertyCatalogueLocations" value="{{location.name}}">{{location.label}}</option>
                  </select>
              </div>

              <div class="col-3 mt-4">
                <div class="input-group mb-3">
                    <div class="input-group-prepend"><span class="input-group-text">$</span></div>
                    <input name="minPrice" #minPrice="ngModel"
                    [(ngModel)]="propertyToSearch.minPrice" type="number" class="form-control" placeholder="Precio mínimo....">
                    <div class="input-group-append"><span class="input-group-text">.00</span></div>
                </div>
            </div>

            <div class="col-3 mt-4">
                <div class="input-group mb-3">
                    <div class="input-group-prepend"><span class="input-group-text">$</span></div>
                    <input name="maxPrice" #maxPrice="ngModel" ng-model-options="{ getterSetter: true, debounce: 200 }"
                    [(ngModel)]="propertyToSearch.maxPrice" type="number" class="form-control" placeholder="Precio máximo....">
                    <div class="input-group-append"><span class="input-group-text">.00</span></div>
                </div>
            </div>

            <div class="col-2 mt-4" *ngIf="propertyToSearch.stage==1">
                  <input name="dorms" #dorms="ngModel" [(ngModel)]="propertyToSearch.dorms" type="number" class="form-control" placeholder="Dormitorios">

          </div>

              <div class="col-2 mt-4">
                  <button style="width:100%;" type="submit" (click)="SearchProperties()" class="btn btn-primary mb-2">BUSCAR</button>
              </div>
              <div class="col-2 mt-4"><button _ngcontent-dna-c58="" (click)="cleanSearch()" type="submit" class="btn btn-outline-danger mb-2" style="width: 100%;">LIMPIAR BÚSQUEDA</button></div>
          </div>
        </form>
    </div>
  </div>

    <!-- DataTales Example -->
    <div class="card shadow mb-4">
        <div class="card-header py-3">
            <div class="row">
                <div class="col-md-12">
                    <div class="alert alert-success" role="alert" *ngIf="statusCreate == 'success'">Se ha creado la propiedad satisfactoriamente.</div>
                    <div class="alert alert-success" role="alert" *ngIf="statusEdit == 'success'">Se ha editado la propiedad satisfactoriamente.</div>
                    <div class="alert alert-success" role="alert" *ngIf="statusDelete == 'success'">Se ha eliminado la propiedad satisfactoriamente.</div>
                    <div class="alert alert-danger" role="alert" *ngIf="statusCreate == 'error' && showAlertCreate">No se ha podido crear la propiedad, intenta m&aacute;s tarde. <button type="button" class="close" (click)="closeCrear()" aria-label="Close" ><span aria-hidden="true">&times;</span></button></div>
                    <div class="alert alert-danger" role="alert"  *ngIf="statusEdit == 'error' && showAlertEdit">No se ha podido editar la propiedad, intenta m&aacute;s tarde. <button type="button" class="close" (click)="closeEdit()" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
                    <div class="alert alert-danger" role="alert" *ngIf="statusDelete == 'error' && showAlertDelete">No se ha podido eliminar la propiedad, intenta m&aacute;s tarde. <button type="button" class="close" (click)="closeDelete()" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
                    <div class="alert alert-danger" role="alert" *ngIf="statusGetSearchProperties == 'error'">En este momento no hay propiedades que correspondan a tus criterios de b&uacute;squeda.</div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-10">
                    <h6 class="m-0 font-weight-bold text-primary">Listado de Propiedades</h6>
                    <p>Todas las propiedades del sistema.</p>
                </div>

                <div class="col-md-2 float-right">

                    <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#createProperty">
                      <i class="fas fa-user"></i> Crear Propiedad
                    </button>
                    <!-- Modal -->
                    <div class="modal fade" id="createProperty" tabindex="-1" role="dialog" aria-labelledby="createPropertyLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="createPropertyLabel">Crear Propiedad</h5>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <form #saveForm="ngForm" #saveForm="ngForm" (ngSubmit)="onSubmit(saveForm)">
                                <div class="form-group">
                                  <label for="propertyModule">M&oacute;dulo:</label>
                                  <select 
                                    id="propertyModule" 
                                    class="form-control"
                                    name="propertyModule"
                                    #propertyModule="ngModel"
                                    [(ngModel)]="property.propertyModule"
                                  >
                                      <option value="1">Propiedad</option>
                                      <option value="2">Proyecto Nuevo</option>
                                  </select>
                                </div>

                              <div class="form-group">
                                  <label for="propertyFeaturedImage">Imagen Destacada:</label>
                                  <input 
                                    type="file" 
                                    (change)="fileChangeEvent($event)"
                                    class="form-control-file" 
                                    id="propertyFeaturedImage"
                                  >
                              </div>

                              <div class="form-group">
                                  <label for="propertyTitle">T&iacute;tulo:</label>
                                  <input 
                                    type="text" 
                                    class="form-control" 
                                    id="propertyTitle"
                                    name="propertyTitle"
                                    #propertyTitle="ngModel"
                                    [(ngModel)]="property.propertyTitle"
                                  >
                              </div>

                              <div class="form-group">
                                  <label for="propertyOperation"><b>Tipo de Operaci&oacute;n</b>:</label>
                                  <select 
                                    class="form-control" 
                                    id="propertyOperation"
                                    name="propertyOperation"
                                    #propertyOperation="ngModel"
                                    [(ngModel)]="property.propertyOperation"
                                  >
                                    <option value="1">Venta</option>
                                    <option value="2">Renta</option>
                                    <option value="3">Venta y Renta</option>
                                  </select>
                              </div>

                              <div class="form-group">
                                  <label for="propertyVisibility"><b>Visibilidad</b>:</label>
                                  <select 
                                    class="form-control" 
                                    id="propertyVisibility"
                                    name="propertyVisibility"
                                    #propertyVisibility="ngModel"
                                    [(ngModel)]="property.propertyVisibility"
                                  >
                                    <option value="1">P&uacute;blica</option>
                                    <option value="2">Privada</option>
                                  </select>
                              </div>

                              <div class="form-group">
                                  <label for="propertyType"><b>Tipo de Propiedad</b>:</label>
                                  <select  
                                    *ngIf="propertyTypes" 
                                    class="form-control" 
                                    id="propertyType"
                                    name="propertyType"
                                    #propertyType="ngModel"
                                    [(ngModel)]="property.propertyType"
                                  >
                                    <option *ngFor="let type of propertyTypes" value="{{type._id}}">{{type.label}}</option>
                                  </select>
                              </div>
                              <div class="modal-footer">
                                <button type="button" #closebutton class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                                <button type="submit"class="btn btn-primary" id="closeModal">Crear Propiedad</button>
                              </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>

                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-bordered" id="datatableexample" width="100%" cellspacing="0">
                    <thead>
                        <tr>
                            <th>Imagen</th>
                            <th>M&oacute;dulo</th>
                            <th>C&oacute;digo</th>
                            <th>T&iacute;tulo</th>
                            <th>Precio</th>
                            <th>Operaci&oacute;n</th>
                            <th>Tipo</th>
                            <th>Ingresado por Agente</th>
                            <th>Estatus</th>
                            <th>Ubicación</th>
                            <th style="width:80px;"></th>
                        </tr>
                    </thead>
                    <tfoot>
                        <tr>
                            <th>Imagen</th>
                            <th>M&oacute;dulo</th>
                            <th>C&oacute;digo</th>
                            <th>T&iacute;tulo</th>
                            <th>Precio</th>
                            <th>Operaci&oacute;n</th>
                            <th>Tipo</th>
                            <th>Ingresado por Agente</th>
                            <th>Estatus</th>
                            <th>Ubicación</th>
                            <th style="width:80px;"></th>
                        </tr>
                    </tfoot>
                    <tbody>
                        <tr *ngFor="let property of data">
                            <!-- <td><img width="100" src="https://olgadetorrebiarte.com/wp-content/uploads/2019/04/2-1-835x467.jpg"><hr></td> -->
                            <td *ngIf="property.propertyThumbnailImage"><img width="100" src="{{ url +'get-thumbnail-property/' + property.propertyThumbnailImage }}"><hr></td>                              
                            <!-- <td *ngIf="property.propertyFeaturedImage && !property.propertyThumbnailImage"><img width="100" src="{{ url +'get-image-property/' + property.propertyFeaturedImage }}"><hr></td> -->
                            <td *ngIf="!property.propertyThumbnailImage"> </td>
                            <td *ngIf="property.propertyModule==1">Propiedad</td>
                            <td *ngIf="property.propertyModule==2">Proyecto Nuevo</td>
                            <td *ngIf="property.propertyModule!=2 && property.propertyModule!=1">{{" "}}</td>
                            <td><span class="badge badge-success">{{property.code?property.code:""}}</span></td>
                            <td><a href="https://admin.olgadetorrebiarte.com/view-property/{{property._id}}" target="_blank">{{ property.propertyTitle}}</a>  </td>
                            <td><b *ngIf="property.propertyRentPrice">Precio Renta: $</b>{{property.propertyRentPrice?property.propertyRentPrice:""}}<br/><br/><b *ngIf="property.propertySalePrice">Precio Venta: $</b>{{property.propertySalePrice?property.propertySalePrice:""}}<br/><br/><b *ngIf="property.propertyMinPrice">Precio Mínimo: $</b>{{property.propertyMinPrice?property.propertyMinPrice:""}}<br/><br/><b *ngIf="property.propertyMaxPrice">Precio Máximo: $</b>{{property.propertyMaxPrice?property.propertyMaxPrice:""}}</td>
                            <td *ngIf="property.propertyOperation==1">Venta</td>
                            <td *ngIf="property.propertyOperation==2">Renta</td>
                            <td *ngIf="property.propertyOperation==3">Venta y Renta</td>
                            <td>{{ property.propertyType.label}}</td>
                            <td *ngIf="property.propertyOwner">{{ property.propertyOwner.name +" "+ property.propertyOwner.surname}}</td>
                            <td *ngIf="!property.propertyOwner">{{property.propertyOwnerName?property.propertyOwnerName:"Inmobiliaria Olga de Torrebiarte"}}</td>
                            <td><span class="badge badge-success">{{ property.propertyStatus?property.propertyStatus.label:""}}</span></td>
                            <!-- <td *ngIf="property.propertyVisibility=='2'"><span class="badge badge-secondary">Privado</span></td>
                            <td *ngIf="property.propertyVisibility=='1'"><span class="badge badge-primary">Público</span></td> -->
                            <td *ngIf="!property.propertyStateName"> <span class="badge badge-primary">{{property.propertyState? getDepartmentLabel(property.propertyState):""}}</span> <span class="badge badge-warning">{{property.propertyLocation?property.propertyLocation.label:""}}</span> </td>
                            <td *ngIf="property.propertyStateName"> <span class="badge badge-primary">{{property.propertyStateName}}</span> <span class="badge badge-warning">{{property.propertyLocationName}}</span> </td>
                            <td>
                                <!-- <a class="btn btn-primary" role="button" aria-pressed="true" (click)="(getProperty._id)"><i class="fas fa-edit"></i></a> -->
                                <!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#editUser" (click)="getProperty(property._id)"><i class="fas fa-edit"></i></button> -->

                                <a href="https://admin.olgadetorrebiarte.com/property/{{property._id}}" target="_blank" class="btn btn-primary" style="margin-right:10px;"><i class="fas fa-edit"></i></a>

                                <button type="button" class="btn btn-danger" data-toggle="modal" data-target="#deleteProperty" (click)="getPropertyToDelete(property._id)"><i class="far fa-trash-alt"></i></button>
                                <!-- <button type="button" class="btn btn-info"  aria-pressed="true" (click)="viewProperty(property._id)"><i class="fas fa-eye"></i></button> -->

                                <!-- Modal -->
                                <div class="modal fade" id="deleteProperty" tabindex="-1" role="dialog" aria-labelledby="deletePropertyLabel" aria-hidden="true">
                                    <div class="modal-dialog" role="document">
                                      <div class="modal-content">
                                        <div class="modal-header">
                                          <h5 class="modal-title" id="deletePropertyLabel">Eliminar Propiedad</h5>
                                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                          </button>
                                        </div>
                                        <div class="modal-body">
                                          <p>¿Estás seguro de eliminar esta propiedad?</p>
                                        </div>
                                        <div class="modal-footer">
                                          <button type="button" class="btn btn-secondary" (click)="deleteProperty()">Eliminar</button>
                                          <button type="button" class="btn btn-primary" #closebutton3  data-dismiss="modal">Salir</button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                            </td>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

<!-- </div> -->
<!-- /.container-fluid -->