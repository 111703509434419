 <!-- Begin Page Content -->
 <!-- <div class="container-fluid"> -->
    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 class="h3 mb-0 text-gray-800">Editar Propiedad</h1>
    </div>

    <div class="row">

        <div class="col-lg-8">

            <div class="card shadow mb-4">
                <div class="card-header py-3">
                    <div class="row">

                        <div class="col-md-12">
                            <h6 class="m-0 font-weight-bold text-primary">Datos Generales de la Propiedad</h6>
                            <p>Ingresa los valores correspondientes de esta propiedad.</p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="alert alert-success" *ngIf="statusNewContact == 'success' && showNewContact" role="alert">El contacto se ha agregado exitosamente. <button type="button" class="close" (click)="closeContact()" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
                    <div class="alert alert-danger" *ngIf="statusNewContact == 'error' && showNewContact" role="alert">El contacto no se ha podido crear, intenta nuevamente. <button type="button" class="close" (click)="closeContact()" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
                    <div class="alert alert-danger" *ngIf="statusNewContact == 'repeated' && showNewContact" role="alert">El contacto no se ha podido crear, ya existe un contacto asociado al número de teléfono ingresado. <button type="button" class="close" (click)="closeContact()" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
                    <form>
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label for="propertyModule">M&oacute;dulo:</label>
                                <select 
                                    id="propertyModule" 
                                    class="form-control"
                                    name="propertyModule"
                                    #propertyModule="ngModel"
                                    [(ngModel)]="property.propertyModule" 
                                    disabled
                                >
                                    <option value="1">Propiedad</option>
                                    <option value="2">Proyecto Nuevo</option>
                                </select>
                            </div>

                          
                            <div class="form-group col-md-4">
                                <label for="propertyContact">Propietario:</label>
                                <select 
                                    *ngIf="contacts && hasContacts"
                                    class="js-example-basic-single form-control select2"
                                    id="propertyContact" 
                                    name="contact"
                                    #contact="ngModel"
                                    [(ngModel)]="property.contact._id" 
                                >
                                    <option *ngFor="let contact of contacts" value="{{contact._id}}">{{contact.name}}</option>
                                </select>

                                    
                                <!-- <ng-select2 
                                class="form-control"
                                    id="propertyContact"
                                    width=100%
                                    placeholder='Please select a Product...'
                                    [data]="exampleData"
                                    [value]="Cvalue"
                                    (valueChanged)="changed($event)"
                                  
                                    >    
                                </ng-select2> -->

                               


                                <select 
                                    *ngIf="contacts && !hasContacts"
                                    id="propertyContact" 
                                    class="form-control select2"
                                    name="contact"
                                    #contact="ngModel"
                                    [(ngModel)]="property.contact" 
                                >
                                    <option *ngFor="let contact of contacts" value="{{contact._id}}">{{contact.name}}</option>
                                </select>
                                <a style="margin-top:10px; display:block; color:green;" href="#createContact" data-toggle="modal" data-target="#createContact"><i class="fas fa-user-plus"></i> Crear Propietario</a>
                            </div>
                            <div class="form-group col-md-4">
                              <label for="propertyProject">Condominio / Edificio / Proyecto:</label>
                              <select 
                                *ngIf="projects && haspropertyProject "
                                id="propertyProject" 
                                class="form-control select2"
                                name="propertyProject"
                                #propertyProject="ngModel"
                                [(ngModel)]="property.propertyProject._id" 
                              >
                                  <option *ngFor="let project of projects" value="{{project._id}}">{{project.label}}</option>
                              </select>

                              <select 
                                *ngIf="projects && !haspropertyProject "
                                id="propertyProject" 
                                class="form-control select2"
                                name="propertyProject"
                                #propertyProject="ngModel"
                                [(ngModel)]="property.propertyProject" 
                              >
                                  <option *ngFor="let project of projects" value="{{project._id}}">{{project.label}}</option>
                              </select>
                              <label style="font-size: 12px;">Donde se encuentra ubicada esta propiedad.</label>
                            </div>
                            
                        </div>


                        <div class="form-group">
                            <label for="propertyFeaturedImage">Subir Nueva Imagen:</label>
                            <input 
                                type="file" 
                                (change)="fileChangeEvent($event)"
                                class="form-control-file" 
                                id="propertyFeaturedImage"
                            >
                              <img *ngIf="property.propertyFeaturedImage" width="240" src="{{url+ 'get-image-property/'+property.propertyFeaturedImage }}" />
                        </div>

                        <div _ngcontent-ybu-c60="" class="form-group">
                          <label _ngcontent-ybu-c60="" for="propertyCode">Código:</label>
                          <input 
                          _ngcontent-ybu-c60="" 
                          type="text" 
                          id="propertyCode" 
                          name="propertyCode" 
                          #code="ngModel"
                          [(ngModel)]="property.code"
                          class="form-control ng-valid ng-touched ng-dirty" 
                          ng-reflect-name="propertyCode" 
                          ng-reflect-model="SR126"
                          >
                        </div>

                        <div class="form-group">
                            <label for="propertyTitle">T&iacute;tulo:</label>
                            <input 
                                type="text" 
                                class="form-control" 
                                id="propertyTitle"
                                name="propertyTitle"
                                #propertyTitle="ngModel"
                                [(ngModel)]="property.propertyTitle"
                            >
                        </div>

                        <div class="form-group">
                            <label for="propertySlug">Slug:</label>
                            <input 
                                type="text" 
                                class="form-control" 
                                id="propertySlug"
                                name="propertySlug"
                                #propertySlug="ngModel"
                                [(ngModel)]="property.propertySlug"
                                disabled
                            >
                        </div>

                        <div class="form-group">
                            <label for="propertyDesc">Descripci&oacute;n:</label>
                            <ckeditor 
                                [editor]="Editor"
                                [config]="{ toolbar: [  'heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'undo', 'redo' ] }"
                                name="propertyDesc"
                                #propertyDesc="ngModel"
                                [(ngModel)]="property.propertyDesc"
                            ></ckeditor>
                        </div>

                        <div class="form-group">
                            <label for="propertyYoutube">V&iacute;deo de YouTube:</label>
                            <input 
                                type="url" 
                                class="form-control" 
                                id="propertyYoutube"
                                name="propertyYoutube"
                                #propertyYoutube="ngModel"
                                [(ngModel)]="property.propertyYoutube"
                            >
                        </div>

                        <div class="form-group">
                          <label for="propertyTagline">Descripci&oacute;n de Buscador:</label>
                          <input 
                              type="text" 
                              class="form-control" 
                              id="propertyTagline"
                              name="propertyTagline"
                              #propertyTagline="ngModel"
                              [(ngModel)]="property.propertyTagline"
                          >
                      </div>

                        <div class="form-group">
                            <label for="propertyPresentation">Presentaci&oacute;n Comercial:</label>
                            <input 
                                type="file" 
                                (change)="fileChangeEvent2($event)"
                                class="form-control-file" 
                                id="propertyPresentation"
                              >
                              <a *ngIf="property.propertyPresentation" href="{{url+ 'get-property-doc/'+property.propertyPresentation }}" class="download-button">Descargar Presentaci&oacute;n</a>
                        </div>

                        
                    </form>
                </div>
            </div>

            <div class="card shadow mb-4">
              <div class="card-header py-3">
                  <div class="row">
                      <div class="col-md-12">
                          <h6 class="m-0 font-weight-bold text-primary">&Aacute;reas Especiales</h6>
                          <p>Ingresa los datos a continuación.</p>
                      </div>
                  </div>
              </div>
              <div class="card-body">
                  <form>
                      <div class="form-row">
                          <div class="form-group col-md-4">
                              <label for="propertyBedrooms" class="col-form-label">Dormitorios:</label>
                              <input 
                                id="propertyBedrooms" 
                                type="number" 
                                class="form-control" aria-label=""
                                name="propertyBedrooms"
                                #propertyBedrooms="ngModel"
                                [(ngModel)]="property.propertyBedrooms"
                              >
                          </div>
                          <div class="form-group col-md-4">
                              <label for="propertyBathrooms" class="col-form-label">Ba&ntilde;os:</label>
                              <input 
                                id="propertyBathrooms" 
                                type="number" 
                                class="form-control" 
                                aria-label=""
                                name="propertyBathrooms"
                                #propertyBathrooms="ngModel"
                                [(ngModel)]="property.propertyBathrooms"
                              >
                          </div>

                          <div class="form-group col-md-2">
                              <label for="propertyArea" class="col-form-label">&Aacute;rea terreno: </label>
                              <input 
                                id="propertyArea" 
                                type="number" 
                                class="form-control" aria-label=""
                                name="propertyArea"
                                #propertyArea="ngModel"
                                [(ngModel)]="property.propertyArea"
                              >
                          </div>

                          <div class="form-group col-md-2">
                              <label for="propertyArea" class="col-form-label">Tipo de &Aacute;rea:</label>
                              <select 
                                class="form-control" 
                                id="propertyArea"
                                name="propertyAreaType"
                                #propertyAreaType="ngModel"
                                [(ngModel)]="property.propertyAreaType"
                              >
                                <option value="1">m<sup>2</sup></option>
                                <option value="2">vrs<sup>2</sup></option>
                                <option value="3">Manzanas</option>
                              </select>
                          </div>
                          <div class="form-group col-md-2">
                            <label for="propertyLevels" class="col-form-label">Niveles:</label>
                            <input id="propertyLevels" type="number" class="form-control" aria-label="" name="levels"
                            #levels="ngModel"
                            [(ngModel)]="property.levels">
                        </div>
                        <div class="form-group col-md-3">
                          <label for="propertyConstructionArea" class="col-form-label">&Aacute;rea construcci&oacute;n: </label>
                          <input 
                            id="propertyConstructionArea" 
                            type="number" 
                            class="form-control" aria-label=""
                            name="propertyConstructionArea"
                            #propertyConstructionArea="ngModel"
                            [(ngModel)]="property.propertyConstructionArea">
                        </div>

                        <div class="form-group col-md-2">
                            <label for="propertyConstructionArea" class="col-form-label">Tipo de &Aacute;rea:</label>
                            <select 
                              class="form-control" 
                              id="propertyConstructionArea"
                              name="propertyConstructionAreaType"
                              #propertyConstructionAreaType="ngModel"
                              [(ngModel)]="property.propertyConstructionAreaType"
                            >
                              <option value="1">m<sup>2</sup></option>
                              <option value="2">vrs<sup>2</sup></option>
                              <option value="3">Manzanas</option>
                            </select>
                        </div>
                        <div class="form-group col-md-2">
                          <label for="propertyParkinSlots" class="col-form-label">Parqueos:</label>
                          <input 
                            id="propertyParkinSlots" 
                            type="number" 
                            class="form-control" 
                            aria-label=""
                            name="parkinslots"
                            #parkinslots="ngModel"
                            [(ngModel)]="property.parkinslots">
                      </div>
                      </div>
                  </form>
              </div>
            </div>

            <div class="card shadow mb-4">
                <div class="card-header py-3">
                    <div class="row">
                        <div class="col-md-12">
                            <h6 class="m-0 font-weight-bold text-primary">Datos Econ&oacute;micos de la Propiedad</h6>
                            <p>Ingresa los valores econ&oacute;micos de esta propiedad.</p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <form>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="propertyRentPrice" class="col-form-label">Precio de Renta:</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">$</span>
                                    </div>
                                     <input 
                                        id="propertyRentPrice" 
                                        type="number" 
                                        class="form-control" aria-label=""
                                        name="propertyRentPrice"
                                        #propertyRentPrice="ngModel"
                                        [(ngModel)]="property.propertyRentPrice"
                                    >
                                    <div class="input-group-append">
                                        <span class="input-group-text">.00</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="propertySalePrice" class="col-form-label">Precio de Venta:</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">$</span>
                                    </div>
                                     <input 
                                        id="propertySalePrice" 
                                        type="number" 
                                        class="form-control" aria-label=""
                                        name="propertySalePrice"
                                        #propertySalePrice="ngModel"
                                        [(ngModel)]="property.propertySalePrice"
                                     >
                                    <div class="input-group-append">
                                        <span class="input-group-text">.00</span>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group col-md-6">
                                <label for="propertyMinPrice" class="col-form-label">Precio M&iacute;nimo:</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">$</span>
                                    </div>
                                     <input 
                                        id="propertyMinPrice" 
                                        type="number" 
                                        class="form-control" aria-label=""
                                        name="propertyMinPrice"
                                        #propertyMinPrice="ngModel"
                                        [(ngModel)]="property.propertyMinPrice"
                                    >
                                    <div class="input-group-append">
                                        <span class="input-group-text">.00</span>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group col-md-6">
                                <label for="propertyMaxPrice" class="col-form-label">Precio M&aacute;ximo:</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">$</span>
                                    </div>
                                     <input 
                                        id="propertyMaxPrice" 
                                        type="number" 
                                        class="form-control" aria-label=""
                                        name="propertyMaxPrice"
                                        #propertyMaxPrice="ngModel"
                                        [(ngModel)]="property.propertyMaxPrice"
                                    >
                                    <div class="input-group-append">
                                        <span class="input-group-text">.00</span>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group col-md-6">
                                <label for="propertyIusi" class="col-form-label">IUSI:</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                      <select class="custom-select" id="maintenanceCurrency" name="iusiCurrency"
                                      #iusiCurrency="ngModel" [(ngModel)]="property.iusiCurrency">
                                        <option value="1">Q - Quetzales</option>
                                        <option value="2">$ - Dólares</option>
                                      </select>
                                    </div>
                                     <input 
                                        id="propertyIusi" 
                                        type="number" 
                                        class="form-control" aria-label=""
                                        name="propertyIusi"
                                        #propertyIusi="ngModel"
                                        [(ngModel)]="property.propertyIusi"
                                    >
                                    <div class="input-group-append">
                                        <span class="input-group-text">.00</span>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group col-md-6">
                              <label for="propertyIusiType" class="col-form-label">Tipo de IUSI:</label>
                              <select 
                                class="form-control" 
                                id="propertyIusiType"
                                name="propertyIusiType"
                                #propertyIusiType="ngModel"
                                [(ngModel)]="property.propertyIusiType"
                                >
                                <option value="1">Trimestral</option>
                                <option value="2">Anual</option>
                              </select>
                          </div>

                            <div class="form-group col-md-6">
                                <label for="propertyMaintenance" class="col-form-label">Precio de Mantenimiento:</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                      <select class="custom-select" id="maintenanceCurrency" name="maintenanceCurrency"
                                      #maintenanceCurrency="ngModel" [(ngModel)]="property.maintenanceCurrency">
                                        <option value="1">Q - Quetzales</option>
                                        <option value="2">$ - Dólares</option>
                                      </select>
                                    </div>
                                     <input 
                                        id="propertyMaintenance" 
                                        type="number" 
                                        class="form-control" aria-label=""
                                        name="propertyMaintenance"
                                        #propertyMaintenance="ngModel"
                                        [(ngModel)]="property.propertyMaintenance"
                                    >
                                    <div class="input-group-append">
                                        <span class="input-group-text">.00</span>
                                    </div>
                                </div>
                                <p class="text-secondary" style="font-size:14px;">Si el mantenimiento está incluido, dejar en blanco.</p>
                            </div>

                            <div class="form-group col-md-6">
                                <label for="propertyComision" class="col-form-label">Comisi&oacute;n:</label>
                                <div class="input-group mb-3">
                                     <input 
                                        id="propertyComision" 
                                        type="number" 
                                        class="form-control" aria-label=""
                                        name="propertyComision"
                                        #propertyComision="ngModel"
                                        [(ngModel)]="property.propertyComision"
                                    >
                                    <div class="input-group-append">
                                        <span class="input-group-text">%</span>
                                    </div>
                                </div>
                                <p class="text-secondary" style="font-size:14px;">En rentas, la comisión será el 100% equivalente a una renta</p>
                            </div>

                            <div class="form-group col-md-12">
                                <div class="form-check">
                                    <input 
                                        type="checkbox" 
                                        class="form-check-input" 
                                        id="propertyTax"
                                        name="propertyTax"
                                        #propertyTax="ngModel"
                                        [(ngModel)]="property.propertyTax"
                                    >
                                    <label class="form-check-label" for="propertyTax">Seleccionar si la propiedad incluye impuestos.</label>
                                </div>
                             </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="card shadow mb-4">
                <div class="card-header py-3">
                    <div class="row">
                        <div class="col-md-10">
                            <h6 class="m-0 font-weight-bold text-primary">Galer&iacute;a de Im&aacute;genes</h6>
                            <p>Todas las im&aacute;genes de la propiedad.</p>
                        </div>
                        <div class="col-md-2 float-right">
                            <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#createImage">Agregar</button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>Imagen</th>
                                    <th>T&iacute;tulo</th>
                                    <th>Fecha</th>
                                    <th>Visibilidad</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let image of images">
                                    <td *ngIf="image.imgThumbnail"><img width="100" src="{{ url +'get-thumbnail-photo/' + image.imgThumbnail }}"></td>
                                    <td *ngIf="image.imageSrc && !image.imgThumbnail"><img width="100" src="{{ url +'get-image-photo/' + image.imageSrc }}"></td>
                                    <td *ngIf="!image.imageSrc && !image.imgThumbnail"><img width="100" src="https://olgadetorrebiarte.com/wp-content/uploads/2019/04/2-1-835x467.jpg"></td>
                                    <td>{{ image.imageName }}</td>
                                    <td>{{ (image.imageDate | amFromUnix) | amLocale:'es' | amDateFormat:'DD/MM/YYYY'}}</td>
                                    <!-- <td>18/02/2021</td> -->
                                    <td *ngIf="image.imageVisibility ==1"><span class="badge badge-success">Público</span></td>
                                    <td *ngIf="image.imageVisibility ==2"><span class="badge badge-secondary">Privado</span></td>
                                    <td>
                                        <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#editImage" (click)="getImageToUpdate(image._id)"><i class="fas fa-edit"></i></button>
                                        <button type="button" class="btn btn-danger" data-toggle="modal" data-target="#deleteImage" (click)="getImageToDelete(image._id)"><i class="far fa-trash-alt"></i></button>
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="card shadow mb-4">
                <div class="card-header py-3">
                    <div class="row">
                        <div class="col-md-10">
                            <h6 class="m-0 font-weight-bold text-primary">Manejo de Archivos</h6>
                            <p>Todos los archivos de la propiedad.</p>
                        </div>
                        <div class="col-md-2 float-right">
                            <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#createFile">Agregar</button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>Archivo</th>
                                    <th>T&iacute;tulo</th>
                                    <th>Fecha</th>
                                    <th>Visibilidad</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let doc of docs">
                                    <td><a href="{{url+ 'get-doc/'+doc.fileSrc }}" target="_blank">Ver Archivo</a></td>
                                    <td>{{ doc.fileName }}</td>
                                    <td>{{ (doc.fileDate | amFromUnix) | amLocale:'es' | amDateFormat:'DD/MM/YYYY'}}</td>
                                    <td *ngIf="doc.fileVisibility ==1"><span class="badge badge-success">Público</span></td>
                                    <td *ngIf="doc.fileVisibility ==2"><span class="badge badge-secondary">Privado</span></td>
                                    <td>
                                        <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#editFile" (click)="getDocToUpdate(doc._id)"><i class="fas fa-edit"></i></button>
                                        <button type="button" class="btn btn-danger" data-toggle="modal" data-target="#deleteFile" (click)="getDocToDelete(doc._id)"><i class="far fa-trash-alt"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="card shadow mb-4" *ngIf="property.propertyModule==2">
                <div class="card-header py-3">
                    <div class="row">
                        <div class="col-md-10">
                            <h6 class="m-0 font-weight-bold text-primary">Modelos del Proyecto</h6>
                            <p>Todos los modelos del proyecto.</p>
                        </div>
                        <div class="col-md-2 float-right">
                            <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#createModel">Agregar</button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>Planta</th>
                                    <th>T&iacute;tulo</th>
                                    <th>Fecha</th>
                                    <th>Visibilidad</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let modelProperty of modelProperties">
                                    <td *ngIf="modelProperty.imgThumbnail"><img width="100" src="{{ url +'get-thumbnail-model/' + modelProperty.imgThumbnail }}"></td>
                                    <td *ngIf="modelProperty.modelSrc && !modelProperty.imgThumbnail"><img width="100" src="{{ url +'get-image-model/' + modelProperty.modelSrc }}"></td>
                                    <td *ngIf="!modelProperty.modelSrc && !modelProperty.imgThumbnail"><img width="100" src="https://olgadetorrebiarte.com/wp-content/uploads/2019/04/2-1-835x467.jpg"></td>
                                    <td>
                                        {{modelProperty.modelName}}<br/>
                                        <span style="font-size:11px;">
                                          {{modelProperty.modelDesc}}.<br/>
                                            <b>Precio</b>: ${{modelProperty.modelPrice}}<br/>
                                            <b>Espacios</b>: {{modelProperty.modelDorms}}<br/>
                                            <b>Parqueos</b>: {{modelProperty.modelParkingSlots}}
                                        </span>
                                    </td>
                                    <td>{{ (modelProperty.modelDate | amFromUnix) | amLocale:'es' | amDateFormat:'DD/MM/YYYY'}}</td>
                                    <td *ngIf="modelProperty.modelVisibility ==1"><span class="badge badge-success">Público</span></td>
                                    <td *ngIf="modelProperty.modelVisibility ==2"><span class="badge badge-secondary">Privado</span></td>
                                    <td>
                                        <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#editModel" (click)="getModelToUpdate(modelProperty._id)"><i class="fas fa-edit"></i></button>
                                        <button type="button" class="btn btn-danger" data-toggle="modal" data-target="#deleteModel" (click)="getModelPropertiToDelete(modelProperty._id)"><i class="far fa-trash-alt"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="card shadow mb-4">
                <div class="card-header py-3">
                    <div class="row">
                        <div class="col-md-10">
                            <h6 class="m-0 font-weight-bold text-primary">Comentarios de la Propiedad</h6>
                            <p>Todos los comentarios de la propiedad.</p>
                        </div>
                        <div class="col-md-2 float-right">
                            <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#createComment">Agregar</button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>Comentario</th>
                                    <th style="width:120px;"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let comment of comments">
                                    <td>
                                        <span class="badge badge-success">{{ comment.commentAuthor.name}} {{ comment.commentAuthor.surname}}</span>
                                        <span style="font-size:12px;">
                                            <b>Fecha</b>: {{ (comment.commentDate | amFromUnix) | amLocale:'es' | amDateFormat:'DD/MM/YYYY'}}<br/>
                                                {{ comment.commentDesc}}
                                        </span>
                                    </td>
                                    <td>
                                        <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#editComment" (click)="getCommentToUpdate(comment._id)"><i class="fas fa-edit"></i></button>
                                        <button type="button" class="btn btn-danger" data-toggle="modal" data-target="#deleteComment" (click)="getCommentToDelete(comment._id)"><i class="far fa-trash-alt"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>

        <div class="col-lg-4">

            <div class="card shadow mb-4">
                <div
                    class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 class="m-0 font-weight-bold text-primary">Estatus y Visibilidad</h6>
                </div>
                <div class="card-body">
                    <b>Creada: </b> {{ (property.createdAt | amFromUnix) | amLocale:'es-mx' | amDateFormat:'LL'}}<br/>
                    <hr>
                    <b>Actualizada: </b>{{ (property.updatedAt | amFromUnix) | amLocale:'es-mx' | amDateFormat:'LL'}}<br/>
                    <hr>

                    <label for="propertyOwner"><b>Ingresado por Agente</b>:</label>
                    <select 
                        *ngIf="users && hasPropertyOwner"
                        class="form-control select2" 
                        id="propertyOwner"
                        name="propertyOwner"
                        #propertyOwner="ngModel"
                        [(ngModel)]="property.propertyOwner._id"
                    >
                    <option *ngFor="let user of users" value="{{user._id}}">{{user.name}} {{user.surname}}</option>
                    </select>
                    <select 
                        *ngIf="users && !hasPropertyOwner"
                        class="form-control select2" 
                        id="propertyOwner"
                        name="propertyOwner"
                        #propertyOwner="ngModel"
                        [(ngModel)]="property.propertyOwner"
                    >
                    <option *ngFor="let user of users" value="{{user._id}}">{{user.name}} {{user.surname}}</option>
                    </select>

                    <hr>

                    <label for="propertyOperation"><b>Tipo de Operaci&oacute;n</b>:</label>
                    <select 
                        class="form-control" 
                        id="propertyOperation"
                        name="propertyOperation"
                        #propertyOperation="ngModel"
                        [(ngModel)]="property.propertyOperation"
                    >
                      <option value="1">Venta</option>
                      <option value="2">Renta</option>
                      <option value="3">Venta y Renta</option>
                    </select>

                    <hr>

                    <label for="propertyStatus"><b>Estatus</b>:</label>
                    <select 
                      *ngIf="status && haspropertyStatus"
                      class="form-control select2" 
                      id="propertyStatus"
                      name="propertyStatus"
                      #propertyStatus="ngModel"
                      [(ngModel)]="property.propertyStatus._id"
                    >
                      <option *ngFor="let state of status" value="{{state._id}}">{{state.label}}</option>
                    </select>

                    <select 
                      *ngIf="status && !haspropertyStatus"
                      class="form-control select2" 
                      id="propertyStatus"
                      name="propertyStatus"
                      #propertyStatus="ngModel"
                      [(ngModel)]="property.propertyStatus"
                    >
                      <option *ngFor="let state of status" value="{{state._id}}">{{state.label}}</option>
                    </select>

                    <hr>

                    <label for="propertyVisibility"><b>Visibilidad</b>:</label>
                    <select 
                        class="form-control" 
                        id="propertyVisibility"
                        name="propertyVisibility"
                        #propertyVisibility="ngModel"
                        [(ngModel)]="property.propertyVisibility"
                    >
                      <option value="1">P&uacute;blica</option>
                      <option value="2">Privada</option>
                    </select>

                    <hr>
                    <label for="propertyTypeOfOwnership"><b>Tengo la propiedad en</b>:</label>
                    <select 
                      class="form-control" 
                      id="propertyTypeOfOwnership"
                      name="typeofownership"
                      #typeofownership="ngModel"
                      [(ngModel)]="property.typeofownership"
                    >
                      <option value="1">Propiedades en Directo</option>
                      <option value="2">Propiedades Compartidas</option>
                    </select>

                    <hr>

                    <div class="form-check">
                        <input 
                            type="checkbox" 
                            class="form-check-input" 
                            id="propertyFeatured"
                            name="propertyFeatured"
                            #propertyFeatured="ngModel"
                            [(ngModel)]="property.propertyFeatured"
                        >
                        <label class="form-check-label" for="propertyFeatured">Seleccionar si es propiedad destacada.</label>
                    </div>

                    <hr>

                    <button style="width:100%;" type="button" class="btn btn-primary" (click)="onSubmit()">Guardar Cambios</button>
                </div>
            </div>
            <div class="col-md-12">
              <div class="alert alert-success" role="alert" *ngIf="statusCreate == 'success' && showAlertEdit">Los cambios en la propiedad se han actualizado exitosamente. <button type="button" class="close" (click)="closeEdit()" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
              <div class="alert alert-danger" role="alert" *ngIf="statusCreate == 'error' && showAlertEditF">Los cambios en la propiedad no se han podido actualizar. Inténtalo de nuevo. <button type="button" class="close" (click)="closeDelete()" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
            </div>

            <div class="card shadow mb-4">
              <div
                  class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 class="m-0 font-weight-bold text-primary">Enlaces Para Compartir</h6>
              </div>
              <div class="card-body">
                  <a class="btn btn-sm btn-social btn-fb" href="https://www.facebook.com/sharer/sharer.php?u=https://olgadetorrebiarte.com/propiedad/{{property.propertyType.name+'/'+property.propertySlug}}" target="_blank" title="Comparte esta propiedad en Facebook">
                      <i class="fab fa-facebook-square"></i> Share
                  </a>
                  <a href="target_blank" title="Comparte esta propiedad en Linkedin" class="btn btn-sm btn-social btn-tw" href="http://www.linkedin.com/shareArticle?mini=true&url=https://olgadetorrebiarte.com/propiedad/{{property.propertyType.name+'/'+property.propertySlug}}&title=Comparte esta propiedad en Linkedin&summary={{property.propertyTagline}}&source=olgadetorrebiarte.com"><i _ngcontent-wfx-c67="" class="fab fa-linkedin"></i> Linkedin</a>
                  <!-- <a class="btn btn-sm btn-social btn-tw" href="https://twitter.com/intent/tweet?text={{property.propertyTitle}}&amp;url=https://olgadetorrebiarte.com/propiedad/{{property.propertyType.name+'/'+property.propertySlug}}" target="_blank" title="Comparte esta propiedad en Twitter">
                      <i class="fab fa-twitter"></i> Tweet
                  </a> -->
                  <a class="btn btn-sm btn-social btn-wa" href="https://api.whatsapp.com/send?text=Hola%2C%20creo%20que%20podr%C3%ADa%20interesarte%20esta%20propiedad%3A%20{{property.propertyTitle}}%20https%3A%2F%2Folgadetorrebiarte.com%2Fpropiedad%2F{{property.propertyType.name}}%2F{{property.propertySlug}}" target="_blank" title="Comparte esta propiedad en WhatsApp">
                      <i class="fab fa-whatsapp"></i> WhatsApp
                  </a>
                  <a class="btn btn-sm btn-social btn-email" href="#createEmail" data-toggle="modal" data-target="#createEmail">
                    <i class="fas fa-at"></i> Enviar por Correo
                </a>
              </div>
          </div>

            <div class="card shadow mb-4">
                <div
                    class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 class="m-0 font-weight-bold text-primary">Tipo de Propiedad</h6>
                </div>
                <div class="card-body">
                    <select 
                        *ngIf="propertyTypes && haspropertyType"
                        class="form-control select2" 
                        id="propertyType"
                        name="propertyType"
                        #propertyType="ngModel"
                        [(ngModel)]="property.propertyType._id"
                    >
                        <option *ngFor="let type of propertyTypes" value="{{type._id}}">{{type.label}}</option>
                    </select>
                    <select 
                        *ngIf="propertyTypes && !haspropertyType"
                        class="form-control select2" 
                        id="propertyType"
                        name="propertyType"
                        #propertyType="ngModel"
                        [(ngModel)]="property.propertyType"
                    >
                        <option *ngFor="let type of propertyTypes" value="{{type._id}}">{{type.label}}</option>
                    </select>
                </div>
            </div>

            <div class="card shadow mb-4">
              <div
                  class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 class="m-0 font-weight-bold text-primary">Amenidades</h6>
              </div>
              <div class="card-body">
                  <label for="propertyAmenities"><b>Agregar Amenidad</b>:</label>
                  <select 
                    class="form-control select2" 
                    id="propertyAmenities"
                    name="propertyAmenity"
                    #propertyAmenity="ngModel"
                    [(ngModel)]="amentiyProperty.propertyAmenity"
                    (change)='SaveAmenityProperty()'
                    
                  >
                      <option disabled selected value> -- Seleccionar Amenidades -- </option>
                      <option *ngFor="let amenity of amenities" value="{{amenity._id}}">{{amenity.label}}</option>
                  </select>

                  <hr>

                  <div id="addedAmenities">
                      <p><b>Amenidades Agregadas</b>:</p>
                      <p *ngFor="let amentiyProperty of amentiyProperties" class="addedAmenity">{{amentiyProperty.propertyAmenity.label}}
                        <a href="#" data-toggle="modal" data-target="#deleteAmenity" (click)="getAmenityPropertiToDelete(amentiyProperty._id)"><i class="fas fa-backspace"></i></a>
                      </p>
                  </div>
              </div>
          </div>

          <div class="card shadow mb-4">
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary">Caracter&iacute;sticas</h6>
            </div>
            <div class="card-body">
                <label for="propertyFeatures"><b>Agregar Caracter&iacute;stica</b>:</label>
                <select 
                  class="form-control select2" 
                  id="propertyFeatures"
                  name="propertyFeature"
                  #propertyFeature="ngModel"
                  [(ngModel)]="featureProperty.propertyFeature"
                  (change)='SaveFeatureProperty()'
                  
                >
                    <option disabled selected value> -- Seleccionar Caracter&iacute;sticas -- </option>
                    <option *ngFor="let feature of features" value="{{feature._id}}">{{feature.label}}</option>
                </select>

                <hr>

                <div id="addedFeatures">
                    <p><b>Caracter&iacute;sticas Agregadas</b>:</p>
                    <p *ngFor="let featureProperty of featureProperties" class="addedFeature">{{featureProperty.propertyFeature.label}}
                      <a href="#" data-toggle="modal" data-target="#deleteFeature" (click)="getFeaturePropertyToDelete(featureProperty._id)"><i class="fas fa-backspace"></i></a>
                    </p>
                </div>
            </div>
        </div>

            <div class="card shadow mb-4">
                <div
                    class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 class="m-0 font-weight-bold text-primary">Ubicaci&oacute;n</h6>
                </div>
                <div class="card-body">
                  <label for="propertyAmenities"><b>Departamento</b>:</label>
                  <select 
                    class="form-control select2" 
                    id="states"
                    name="propertyState"
                    #propertyState="ngModel"
                    [(ngModel)]="property.propertyState" 
                    (change)='changedDepartament($event)'
                  >
                    <option *ngFor="let department of departments" value="{{department._id}}">{{department.label}}</option>
                  </select>
                  <hr>
                    <label for="locations"><b>Ubicación</b>:</label>
                    <select
                        *ngIf="locations && haspropertyLocation && property.propertyState"
                        class="form-control select2" 
                        id="locations"
                        name="propertyLocation"
                        #propertyLocation="ngModel"
                        [(ngModel)]="property.propertyLocation._id"
                    >
                    <option *ngFor="let location of locations" value="{{location._id}}">{{location.label}}</option>
                    </select>

                    <select
                        *ngIf="locations && !haspropertyLocation && property.propertyState"
                        class="form-control select2" 
                        id="locations"
                        name="propertyLocation"
                        #propertyLocation="ngModel"
                        [(ngModel)]="property.propertyLocation"
                    >
                    <option *ngFor="let location of locations" value="{{location._id}}">{{location.label}}</option>
                    </select>

                    <select
                        *ngIf="!property.propertyState"
                        class="form-control select2" 
                        id="locations"
                        name="propertyLocation"
                        #propertyLocation="ngModel"
                        [(ngModel)]="property.propertyLocation"
                    >
                    <!-- <option *ngFor="let location of locations" value="{{location._id}}">{{location.label}}</option> -->
                    </select>
                </div>
            </div>

        </div>

    </div>

     <!-- MODALES PARA IMAGENES -->
    <!-- CREATE IMAGE -->
    <div class="modal fade" id="createImage" tabindex="-1" role="dialog" aria-labelledby="createImageLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="createImageLabel">Subir Imagen</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form #creteImage="ngForm" #creteImage="ngForm" (ngSubmit)="SaveImage(creteImage)">
                  <div class="form-group">
                      <label for="imageSrc">Seleccionar Imagen:</label>
                      <input 
                        type="file" 
                        (change)="fileChangeEvent3($event)"
                        class="form-control-file" 
                        id="imageSrc"
                      >
                  </div>
  
                <div class="form-group">
                  <label for="imageName" class="col-form-label">T&iacute;tulo:</label>
                  <input 
                    type="text" 
                    class="form-control" 
                    id="imageName"
                    name="imageName"
                    #imageName="ngModel"
                    [(ngModel)]="image.imageName" 
                  >
                </div>
  
                <div class="form-group">
                  <label for="imageVisibility" class="col-form-label">Visibilidad:</label>
                  <select 
                    class="form-control" 
                    id="imageVisibility"
                    name="imageVisibility"
                    #imageVisibility="ngModel"
                    [(ngModel)]="image.imageVisibility" 
                  >
                      <option value="1">P&uacute;blico</option>
                      <option value="2">Privado</option>
                  </select>
                </div>
                <div class="modal-footer">
                  <button type="button" #closebutton class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                  <button type="submit"class="btn btn-primary" id="closeModal">Guardar Cambios</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
  
      <!-- EDIT IMAGE -->
      <div class="modal fade" id="editImage" tabindex="-1" role="dialog" aria-labelledby="editImageLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="editImageLabel">Editar Imagen</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form *ngIf="imageToUpdate">
                  <div class="form-group">
                      <img *ngIf="imageToUpdate.imgThumbnail" width="100" src="{{ url +'get-thumbnail-photo/' + imageToUpdate.imgThumbnail }}">
                      <img *ngIf="imageToUpdate.imageSrc && !imageToUpdate.imgThumbnail" width="100" src="{{ url +'get-image-photo/' + imageToUpdate.imageSrc }}">
                      <img *ngIf="!imageToUpdate.imageSrc && !imageToUpdate.imgThumbnail" width="100" src="https://olgadetorrebiarte.com/wp-content/uploads/2019/04/2-1-835x467.jpg"><hr>
                      <label for="imageSrc">Seleccionar Nueva Imagen:</label>
                      <input 
                        type="file" 
                        (change)="fileChangeEvent4($event)"
                        class="form-control-file" 
                        id="imageSrc">
                  </div>
  
                  <div class="form-group">
                    <label for="imageName" class="col-form-label">T&iacute;tulo:</label>
                    <input 
                      type="text" 
                      class="form-control" 
                      id="imageName"
                      name="imageName"
                      #imageName="ngModel"
                      [(ngModel)]="imageToUpdate.imageName" 
                    >
                  </div>
    
                  <div class="form-group">
                    <label for="imageVisibility" class="col-form-label">Visibilidad:</label>
                    <select 
                      class="form-control" 
                      id="imageVisibility"
                      name="imageVisibility"
                      #imageVisibility="ngModel"
                      [(ngModel)]="imageToUpdate.imageVisibility" 
                    >
                        <option value="1">P&uacute;blico</option>
                        <option value="2">Privado</option>
                    </select>
                  </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" #closebutton2  data-dismiss="modal">Cerrar</button>
              <button type="button" class="btn btn-primary" (click)="UpdateImage()">Guardar Cambios</button>
            </div>
          </div>
        </div>
      </div>
  
      <!-- DELETE IMAGE -->
      <div class="modal fade" id="deleteImage" tabindex="-1" role="dialog" aria-labelledby="deleteImageLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteImageLabel">Eliminar Imagen</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>¿Estás seguro de eliminar esta imagen?</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" (click)="DeleteImage()">Eliminar</button>
              <button type="button" class="btn btn-primary" #closebutton3  data-dismiss="modal">Salir</button>
            </div>
          </div>
        </div>
      </div>
  
    <!-- MODALES PARA IMAGENES -->
  
      <!-- MODALES PARA ARCHIVOS -->
      <!-- CREATE FILE -->
      <div class="modal fade" id="createFile" tabindex="-1" role="dialog" aria-labelledby="createFileLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="createFileLabel">Subir Archivo</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form #creteDoc="ngForm" #creteDoc="ngForm" (ngSubmit)="SaveDoc(creteDoc)">
                  <div class="form-group">
                      <label for="fileSrc">Seleccionar Archivo:</label>
                      <input 
                        type="file" 
                        (change)="fileChangeEvent5($event)"
                        class="form-control-file" 
                        id="fileSrc">
                  </div>
  
                <div class="form-group">
                  <label for="fileName" class="col-form-label">T&iacute;tulo:</label>
                  <input 
                    type="text" 
                    class="form-control" 
                    id="fileName"
                    name="fileName"
                    #fileName="ngModel"
                    [(ngModel)]="doc.fileName" 
                  >
                </div>
  
                <div class="form-group">
                  <label for="fileVisibility" class="col-form-label">Visibilidad:</label>
                  <select 
                    class="form-control" 
                    id="fileVisibility"
                    name="fileVisibility"
                    #fileVisibility="ngModel"
                    [(ngModel)]="doc.fileVisibility" 
                  >
                      <option value="1">P&uacute;blico</option>
                      <option value="2">Privado</option>
                  </select>
                </div>
                <div class="modal-footer">
                  <button type="button" #closebutton4 class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                  <button type="submit"class="btn btn-primary" id="closeModal">Guardar Cambios</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
  
      <!-- EDIT FILE -->
      <div class="modal fade" id="editFile" tabindex="-1" role="dialog" aria-labelledby="editFileLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="editFileLabel">Editar Archivo</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form *ngIf="docToUpdate">
                  <div class="form-group">
                      <label for="fileSrc">Seleccionar Archivo:</label>
                      <input 
                        type="file" 
                        (change)="fileChangeEvent6($event)"
                        class="form-control-file" 
                        id="fileSrc"
                      >
                  </div>
  
                  <div class="form-group">
                    <label for="fileName" class="col-form-label">T&iacute;tulo:</label>
                    <input 
                      type="text" 
                      class="form-control" 
                      id="fileName"
                      name="fileName"
                      #fileName="ngModel"
                      [(ngModel)]="docToUpdate.fileName" 
                    >
                  </div>
    
                  <div class="form-group">
                    <label for="fileVisibility" class="col-form-label">Visibilidad:</label>
                    <select 
                      class="form-control" 
                      id="fileVisibility"
                      name="fileVisibility"
                      #fileVisibility="ngModel"
                      [(ngModel)]="docToUpdate.fileVisibility" 
                    >
                        <option value="1">P&uacute;blico</option>
                        <option value="2">Privado</option>
                    </select>
                  </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" #closebutton5  data-dismiss="modal">Cerrar</button>
              <button type="button" class="btn btn-primary" (click)="UpdateDoc()">Guardar Cambios</button>
            </div>
          </div>
        </div>
      </div>
  
      <!-- DELETE FILE -->
      <div class="modal fade" id="deleteFile" tabindex="-1" role="dialog" aria-labelledby="deleteFileLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteFileLabel">Eliminar Archivo</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>¿Estás seguro de eliminar este archivo?</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" (click)="DeleteDoc()">Eliminar</button>
              <button type="button" class="btn btn-primary" #closebutton6  data-dismiss="modal">Salir</button>
            </div>
          </div>
        </div>
      </div>
  
      <!-- MODALES PARA ARCHIVOS -->
  
      <!-- MODALES PARA MODELOS -->
      <!-- CREATE MODEL -->
      <div class="modal fade" id="createModel" tabindex="-1" role="dialog" aria-labelledby="createModelLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="createModelLabel">Crear Modelo</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form #creteModel="ngForm" #creteModel="ngForm" (ngSubmit)="SaveModelProperty(creteModel)">
                  <div class="form-group">
                      <label for="modelSrc">Seleccionar Imagen:</label>
                      <input 
                        type="file" 
                        (change)="fileChangeEvent7($event)"
                        class="form-control-file" 
                        id="modelSrc">
                  </div>
  
                <div class="form-group">
                  <label for="modelName" class="col-form-label">T&iacute;tulo:</label>
                  <input 
                    type="text" 
                    class="form-control" 
                    id="modelName"
                    name="modelName"
                    #modelName="ngModel"
                    [(ngModel)]="modelProperty.modelName" 
                  >
                </div>
  
                <div class="form-group">
                  <label for="modelDesc" class="col-form-label">Descripci&oacute;n:</label>
                  <input 
                    type="text" 
                    class="form-control" 
                    id="modelDesc"
                    name="modelDesc"
                    #modelDesc="ngModel"
                    [(ngModel)]="modelProperty.modelDesc" 
                  >
                </div>
  
                <div class="form-group">
                  <label for="modelPrice" class="col-form-label">Precio:</label>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input 
                      id="modelPrice" 
                      type="number" 
                      class="form-control" 
                      aria-label=""
                      name="modelPrice"
                      #modelPrice="ngModel"
                      [(ngModel)]="modelProperty.modelPrice" 
                    >
                    <div class="input-group-append">
                      <span class="input-group-text">.00</span>
                    </div>
                  </div>
                </div>
  
                <div class="form-group">
                  <label for="modelSpaces" class="col-form-label">N&uacute;mero de Espacios o Habitaciones:</label>
                  <input 
                    type="number" 
                    class="form-control" 
                    id="modelSpaces"
                    name="modelDorms"
                    #modelDorms="ngModel"
                    [(ngModel)]="modelProperty.modelDorms"
                  >
                </div>
  
                <div class="form-group">
                  <label for="modelParkingSlots" class="col-form-label">N&uacute;mero de Parqueos:</label>
                  <input 
                    type="number" 
                    class="form-control" 
                    id="modelParkingSlots"
                    name="modelParkingSlots"
                    #modelParkingSlots="ngModel"
                    [(ngModel)]="modelProperty.modelParkingSlots"
                  >
                </div>
  
                <div class="form-group">
                  <label for="modelVisibility" class="col-form-label">Visibilidad:</label>
                  <select 
                    class="form-control" 
                    id="modelVisibility"
                    name="modelVisibility"
                    #modelVisibility="ngModel"
                    [(ngModel)]="modelProperty.modelVisibility"
                  >
                      <option value="1">P&uacute;blico</option>
                      <option value="2">Privado</option>
                  </select>
                </div>
                <div class="modal-footer">
                  <button type="button" #closebutton11 class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                  <button type="submit"class="btn btn-primary" id="closeModal">Guardar Cambios</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
  
      <!-- EDIT MDEL -->
      <div class="modal fade" id="editModel" tabindex="-1" role="dialog" aria-labelledby="editModelLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="editModelLabel">Editar Modelo</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form *ngIf="modelPropertyToUpdate">
                  <div class="form-group">
                      <img  *ngIf="modelPropertyToUpdate.modelSrc" width="100" src="{{ url +'get-image-model/' + modelPropertyToUpdate.modelSrc}}"><hr>
                      <label for="modelSrc">Seleccionar Nueva Imagen:</label>
                      <input 
                        type="file" 
                        (change)="fileChangeEvent8($event)"
                        class="form-control-file" 
                        id="modelSrc"
                      >
                  </div>
  
                  <div class="form-group">
                    <label for="modelName" class="col-form-label">T&iacute;tulo:</label>
                    <input 
                      type="text" 
                      class="form-control" 
                      id="modelName"
                      name="modelName"
                      #modelName="ngModel"
                      [(ngModel)]="modelPropertyToUpdate.modelName" 
                    >
                  </div>
    
                  <div class="form-group">
                    <label for="modelDesc" class="col-form-label">Descripci&oacute;n:</label>
                    <input 
                      type="text" 
                      class="form-control" 
                      id="modelDesc"
                      name="modelDesc"
                      #modelDesc="ngModel"
                      [(ngModel)]="modelPropertyToUpdate.modelDesc" 
                    >
                  </div>
    
                  <div class="form-group">
                    <label for="modelPrice" class="col-form-label">Precio:</label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input 
                        id="modelPrice" 
                        type="number" 
                        class="form-control" 
                        aria-label=""
                        name="modelPrice"
                        #modelPrice="ngModel"
                        [(ngModel)]="modelPropertyToUpdate.modelPrice" 
                      >
                      <div class="input-group-append">
                        <span class="input-group-text">.00</span>
                      </div>
                    </div>
                  </div>
    
                  <div class="form-group">
                    <label for="modelSpaces" class="col-form-label">N&uacute;mero de Espacios o Habitaciones:</label>
                    <input 
                      type="number" 
                      class="form-control" 
                      id="modelSpaces"
                      name="modelDorms"
                      #modelDorms="ngModel"
                      [(ngModel)]="modelPropertyToUpdate.modelDorms"
                    >
                  </div>
    
                  <div class="form-group">
                    <label for="modelParkingSlots" class="col-form-label">N&uacute;mero de Parqueos:</label>
                    <input 
                      type="number" 
                      class="form-control" 
                      id="modelParkingSlots"
                      name="modelParkingSlots"
                      #modelParkingSlots="ngModel"
                      [(ngModel)]="modelPropertyToUpdate.modelParkingSlots"
                    >
                  </div>
    
                  <div class="form-group">
                    <label for="modelVisibility" class="col-form-label">Visibilidad:</label>
                    <select 
                      class="form-control" 
                      id="modelVisibility"
                      name="modelVisibility"
                      #modelVisibility="ngModel"
                      [(ngModel)]="modelPropertyToUpdate.modelVisibility"
                    >
                        <option value="1">P&uacute;blico</option>
                        <option value="2">Privado</option>
                    </select>
                  </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" #closebutton12  data-dismiss="modal">Cerrar</button>
              <button type="button" class="btn btn-primary" (click)="UpdateModel()">Guardar Cambios</button>
            </div>
          </div>
        </div>
      </div>
  
      <!-- DELETE MODEL -->
      <div class="modal fade" id="deleteModel" tabindex="-1" role="dialog" aria-labelledby="deleteModelLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteModelLabel">Eliminar Modelo</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>¿Estás seguro de eliminar este modelo?</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" (click)="DeleteModelProperty()" >Eliminar</button>
              <button type="button" class="btn btn-primary" #closebutton13  data-dismiss="modal">Salir</button>
            </div>
          </div>
        </div>
      </div>
    <!-- MODALES PARA MODELOS -->
  
  
      <!-- MODALES PARA COMMENTS -->
      <!-- CREATE COMMENT -->
      <div class="modal fade" id="createComment" tabindex="-1" role="dialog" aria-labelledby="createCommentLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="createCommentLabel">Crear Comentario</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form #creteComment="ngForm" #creteComment="ngForm" (ngSubmit)="SaveComment(creteComment)">
                  <input type="hidden" id="userID">
                <div class="form-group">
                  <label for="modelDesc" class="col-form-label">Comentario</label>
                  <textarea 
                    type="text" class="form-control" 
                    id="modelDesc"
                    name="commentDesc"
                    #commentDesc="ngModel"
                    [(ngModel)]="comment.commentDesc" 
                  >
                  </textarea>
                </div>
                <div class="modal-footer">
                  <button type="button" #closebutton7 class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                  <button type="submit"class="btn btn-primary" id="closeModal">Guardar Cambios</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
  
      <!-- EDIT COMMENT -->
      <div class="modal fade" id="editComment" tabindex="-1" role="dialog" aria-labelledby="editCommentLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="editCommentLabel">Editar Comentario</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form *ngIf="commentToUpdate">
                  <input type="hidden" id="userID">
                <div class="form-group">
                  <label for="modelDesc" class="col-form-label">Comentario</label>
                  <textarea 
                    type="text" class="form-control" 
                    id="modelDesc"
                    name="commentDesc"
                    #commentDesc="ngModel"
                    [(ngModel)]="commentToUpdate.commentDesc" 
                  >
                </textarea>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" #closebutton8 data-dismiss="modal">Cerrar</button>
              <button type="button" class="btn btn-primary" (click)="UpdateComment()">Guardar Cambios</button>
            </div>
          </div>
        </div>
      </div>
  
      <!-- DELETE COMMENT -->
      <div class="modal fade" id="deleteComment" tabindex="-1" role="dialog" aria-labelledby="deleteCommentLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteCommentLabel">Eliminar Comentario</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>¿Estás seguro de eliminar este comentario?</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" (click)="DeleteComment()" >Eliminar</button>
              <button type="button" class="btn btn-primary" #closebutton9 data-dismiss="modal">Salir</button>
            </div>
          </div>
        </div>
      </div>
      <!-- MODALES PARA COMMENTS -->


      <!-- ELIMINAR AMENIDAD -->
      <div class="modal fade" id="deleteAmenity" tabindex="-1" role="dialog" aria-labelledby="deleteAmenityLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteAmenityLabel">Eliminar Amenidad</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>¿Estás seguro de eliminar esta amenidad ?</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" (click)="DeleteAmenityProperty()">Eliminar</button>
              <button type="button" class="btn btn-primary" #closebutton10 data-dismiss="modal">Salir</button>
            </div>
          </div>
        </div>
      </div>
      <!-- ELIMINAR AMENIDAD -->

      <!-- ELIMINAR Caracter&iacute;stica -->
      <div class="modal fade" id="deleteFeature" tabindex="-1" role="dialog" aria-labelledby="deleteFeatureLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteFeatureLabel">Eliminar Caracter&iacute;stica</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>¿Estás seguro de eliminar esta Caracter&iacute;stica ?</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" (click)="DeleteFeatureProperty()">Eliminar</button>
              <button type="button" class="btn btn-primary" #closebutton14 data-dismiss="modal">Salir</button>
            </div>
          </div>
        </div>
      </div>
      <!-- ELIMINAR Caracter&iacute;stica -->

      <!-- ENVIAR CORREO -->
      <div class="modal fade" id="createEmail" tabindex="-1" role="dialog" aria-labelledby="createEmailLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="createEmailLabel">Crear Envío</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form #sendEmail="ngForm" #sendEmail="ngForm" (ngSubmit)="SendEmail(sendEmail)">
                  <div *ngIf="statusEmail=='success' && showEmail" class="alert alert-success" role="alert">El envío se ha realizado exitosamente.<button type="button" class="close" (click)="closeEmail()" aria-label="Close"><span aria-hidden="true">&times;</span></button> </div>
  
                  <div *ngIf="statusEmail=='error' && showEmail" class="alert alert-danger" role="alert">El envío no se ha podido realizar, intenta nuevamente<button type="button" class="close" (click)="closeEmail()" aria-label="Close"><span aria-hidden="true">&times;</span></button> </div>
  
                <div class="form-group">
                  <label for="emailReceiver">Email del Receptor:</label>
                  <input type="email" class="form-control" name="receiver" #receiver="ngModel"
                  [(ngModel)]="invite.receiver"  id="emailReceiver">
                </div>
  
                <div class="form-group">
                  <label for="emailSubject">Asunto:</label>
                  <input type="email" class="form-control"  name="subject" #subject="ngModel"
                  [(ngModel)]="invite.subject"  id="emailSubject">
                </div>
  
                <div class="form-group">
                  <label for="emailMessage">Mensaje:</label>
                  <textarea rows="4"  class="form-control"  name="message" #message="ngModel"
                  [(ngModel)]="invite.message" id="emailMessage"></textarea>
                </div>
  
                <div class="modal-footer">
                  <button type="button" #closebutton15 class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                  <button type="submit" class="btn btn-primary">Enviar</button>
                </div>
              </form>
            </div>
            
          </div>
        </div>
      </div>
      <!-- ENVIAR CORREO -->

      <!-- CREAR CONTACTO -->
    <div class="modal fade" id="createContact" tabindex="-1" role="dialog" aria-labelledby="createContactLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="createContactLabel">Crear Propietario</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form *ngIf="newContact">
              <div class="form-group">
                <label for="userName" class="col-form-label"> <label class="text-danger">*</label> Nombre:</label>
                <input 
                  type="text" 
                  class="form-control" 
                  id="userName" 
                  name="name"
                  #name="ngModel"
                  [(ngModel)]="newContact.name"
                >
              </div>

              <div class="form-group">
                <label for="nit" class="col-form-label">NIT:</label>
                <input type="text" class="form-control" id="nit" 
                name="nit"
                #nit="ngModel"
                [(ngModel)]="newContact.nit">
              </div>

              <div class="form-group">
                <label for="userEmail" class="col-form-label">Correo electr&oacute;nico:</label>
                <input type="text" class="form-control" id="userEmail"
                name="email"
                #email="ngModel"
                [(ngModel)]="newContact.email"
                >
              </div>

              <div class="form-group">
                <label for="userPhone" class="col-form-label">Tel&eacute;fono:</label>
                <input type="text" class="form-control" id="userPhone"
                name="phone"
                #phone="ngModel"
                [(ngModel)]="newContact.phone"
                >
              </div>

              <div class="form-group">
                <label for="userRol" class="col-form-label"> <label class="text-danger">*</label>Asesor Asignado:</label>
                <select class="form-control" id="userRol" *ngIf="usersForContacts" 
                name="owner"
                #owner="ngModel"
                [(ngModel)]="newContact.owner" required>
                <option  *ngFor="let u of usersForContacts" value="{{u._id}}">{{u.name}} {{u.surname}}</option>
                </select>
              </div>

              <div class="form-group">
                <label for="origin" class="col-form-label">Origen:</label>
                <input type="text" class="form-control" id="origin" 
                name="origin"
                #origin="ngModel"
                [(ngModel)]="newContact.origin">
              </div>

            <div class="form-group">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="" id="isCompany" 
                  name="isCompany"
                  #isCompany="ngModel"
                  [(ngModel)]="newContact.isCompany">
                  <label class="form-check-label" for="isCompany">
                    Seleccionar si el contacto es desarrollador.
                  </label>
                </div>
             </div>

              <div class="form-group" *ngIf="newContact.isCompany">
                <label for="companyNameCreate">Nombre del Desarrollador:</label>
                <select style="width:100%;" class="form-control select2" id="companyNameCreate" 
                name="companyID"
                #companyID="ngModel"
                [(ngModel)]="newContact.companyID">
                    <option disabled selected value> -- Seleccionar -- </option>
                    <option *ngFor="let company of companies" value="{{company._id}}">{{company.label}}</option>
                </select>
              </div>

              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" #closebutton16 data-dismiss="modal">Cerrar</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="SaveNewContact()" >Crear Propietario</button>
              </div>

            </form>
          </div>
         
        </div>
      </div>
    </div>

<!-- </div> -->