export class DocProperty{
    constructor(
        public _id: String,
        public propertyID: String,
        public fileName: String,
        public fileSrc: String,
        public fileDate: String,
        public fileVisibility: Number,
        public status: String
    ){}
}