<!-- <div class="container-fluid"> -->

  <!-- DataTales Example -->
  <div class="card shadow mb-4">
      <div class="card-header py-3">
          <div class="row">
              <div class="col-md-12">
                  <div class="alert alert-success" role="alert" *ngIf="statusCreate == 'success' && showAlertCreateS">Se ha creado el contacto satisfactoriamente. <button type="button" class="close"  (click)="closeCrearS()"  aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
                  <div class="alert alert-success" role="alert" *ngIf="statusEdit == 'success' && showAlertEditS">Se ha editado el usuario satisfactoriamente. <button type="button" class="close" (click)="closeEditS()" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
                  <div class="alert alert-success" role="alert" *ngIf="statusDelete == 'success' && showAlertDeleteS">Se ha eliminado el usuario satisfactoriamente. <button type="button" class="close" (click)="closeDeleteS()" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
                  <div class="alert alert-danger" role="alert" *ngIf="statusCreate == 'error' && showAlertCreate">No se ha podido crear el contacto, intenta m&aacute;s tarde. <button type="button" class="close"  (click)="closeCrear()"  aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
                  <div class="alert alert-danger" role="alert" *ngIf="statusEdit == 'error' && showAlertEdit">No se ha podido editar el contacto, intenta m&aacute;s tarde. <button type="button" class="close" (click)="closeEdit()" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
                  <div class="alert alert-danger" role="alert" *ngIf="statusDelete == 'error' && showAlertDelete">No se ha podido eliminar el contacto, intenta m&aacute;s tarde. <button type="button" class="close" (click)="closeDelete()" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
                  <div class="alert alert-danger" *ngIf="statusCreate == 'repeated' && showAlertCreate" role="alert">El contacto no se ha podido crear, ya existe un contacto asociado al número de teléfono ingresado. <button type="button" class="close" (click)="closeCrear()" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
              </div>
          </div>

          <div class="row">
              <div class="col-md-10">
                  <h6 class="m-0 font-weight-bold text-primary">Listado de Contactos</h6>
                  <p>Todos los contactos del sistema.</p>
              </div>

              <div class="col-md-2 float-right">

                  <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#createContact">
                    <i class="fas fa-user"></i> Crear Contacto
                  </button>

                  <!-- Modal -->
                  <div class="modal fade" id="createContact" tabindex="-1" role="dialog" aria-labelledby="createContactLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="createContactLabel">Crear Contacto</h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <form>
                            <div class="form-group">
                              <label for="userName" class="col-form-label">Nombre y Apellido:</label>
                              <input 
                                type="text" 
                                class="form-control" 
                                id="userName"
                                name="name"
                                #name="ngModel"
                                [(ngModel)]="contact.name"
                              >
                                
                            </div>

                            <div class="form-group">
                              <label for="nit" class="col-form-label">NIT:</label>
                              <input 
                                type="text" 
                                class="form-control" 
                                id="nit"
                                name="nit"
                                #nit="ngModel"
                                [(ngModel)]="contact.nit"
                              > 
                            </div>

                            <div class="form-group">
                              <label for="userEmail" class="col-form-label">Correo electr&oacute;nico:</label>
                              <input 
                                type="text" 
                                class="form-control" 
                                id="userEmail"
                                name="email"
                                #email="ngModel"
                                [(ngModel)]="contact.email"
                              >
                            </div>

                            <div class="form-group">
                              <label for="userPhone" class="col-form-label">Tel&eacute;fono:</label>
                              <input 
                                type="text" 
                                class="form-control" 
                                id="userPhone"
                                name="phone"
                                #phone="ngModel"
                                [(ngModel)]="contact.phone"
                              >
                            </div>

                            <div class="form-group">
                              <label for="userRol" class="col-form-label">Asesor:</label>
                              <select 
                                class="form-control" 
                                id="userRol"
                                name="owner"
                                #owner="ngModel"
                                [(ngModel)]="contact.owner"
                              >
                                <option  *ngFor="let user of users" value="{{user._id}}">{{user.name}} {{user.surname}}</option>
                              </select>
                            </div>

                            <div class="form-group">
                              <label for="origin" class="col-form-label">Origen:</label>
                              <input 
                                type="text" 
                                class="form-control" 
                                id="origin"
                                name="origin"
                                #origin="ngModel"
                                [(ngModel)]="contact.origin"
                              >
                            </div>

                            <div class="form-group">
                              <div class="form-check">
                                <input 
                                  class="form-check-input" 
                                  type="checkbox"
                                  id="isCompany"
                                  name="isCompany"
                                  #isCompany="ngModel"
                                  [(ngModel)]="contact.isCompany"
                                >
                                <label class="form-check-label" for="isCompany">
                                  Seleccionar si el contacto es desarrollador.
                                </label>
                              </div>
                           </div>

                            <div class="form-group" *ngIf="contact.isCompany">
                              <label for="companyNameCreate">Nombre del Desarrollador:</label>
                              <select 
                                style="width:100%;" 
                                class="form-control select2" 
                                id="companyNameCreate"
                                name="companyID"
                                #companyID="ngModel"
                                [(ngModel)]="contact.companyID"
                              >
                                <option disabled selected value> -- Seleccionar -- </option>
                                <option *ngFor="let company of companies" value="{{company._id}}">{{company.label}}</option>
                              </select>
                            </div>

                            <div class="modal-footer">
                              <button type="button" #closebutton class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                              <button type="submit"class="btn btn-primary" id="closeModal"  data-dismiss="modal" (click)="onSubmit()" >Crear Contacto</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

              </div>
          </div>
      </div>
      <div class="card-body">
          <div class="table-responsive">
              <table class="table table-bordered" id="datatableexample" width="100%" cellspacing="0">
                  <thead>
                      <tr>
                          <th>Nombre</th>
                          <th>NIT</th>
                          <th>Correo</th>
                          <th>Tel&eacute;fono</th>
                          <th>Registro</th>
                          <th>Agente</th>
                          <th>Empresa</th>
                          <!-- <th>Origen</th>
                          <th>Estatus</th> -->
                          <th style="width:80px;"></th>
                      </tr>
                  </thead>
                  <tfoot>
                      <tr>
                          <th>Nombre</th>
                          <th>NIT</th>
                          <th>Correo</th>
                          <th>Tel&eacute;fono</th>
                          <th>Fecha de Registro</th>
                          <th>Agente Asignado</th>
                          <th>Empresa</th>
                          <!-- <th>Origen</th>
                          <th>Estatus</th> -->
                          <th style="width:80px;"></th>
                      </tr>
                  </tfoot>
                  <tbody>
                      <tr *ngFor="let contact of data">
                          <td data-toggle="modal" data-target="#otherData" (click)="getContactStat(contact._id)">{{ contact.name }}</td>
                          <td>{{ contact.nit}}</td>
                          <td>{{ contact.email}}</td>
                          <td>{{ contact.phone }}</td>
                          <td>{{ (contact.registration_date | amFromUnix) | amLocale:'es' | amTimeAgo}}</td>
                          <td>{{ contact.owner.name}}</td>
                          <td *ngIf="contact.companyID">{{contact.companyID.label}}</td>
                          <td *ngIf="!contact.companyID"> </td>
                          <!-- <td>{{ contact.origin}}</td>
                          
                          <td *ngIf="contact.status =='ACTIVE'"><span class="badge badge-success">Activo</span></td>
                          <td *ngIf="contact.status =='INACTIVE'"><span class="badge badge-secondary">Inactivo</span></td> -->
                          <td>
                              <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#editUser" (click)="getContact(contact._id)"><i class="fas fa-edit"></i></button>

                              <!-- Modal -->
                              <div class="modal fade" id="editUser" tabindex="-1" role="dialog" aria-labelledby="editUserLabel" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                  <div class="modal-content">
                                    <div class="modal-header">
                                      <h5 class="modal-title" id="editUserLabel">Editar Contacto</h5>
                                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                    <div class="modal-body">
                                      <form *ngIf="contactToUpdate"> 

                                        <div class="form-group">
                                          <label for="userName" class="col-form-label">Nombre y Apellido:</label>
                                          <input 
                                            type="text" 
                                            class="form-control" 
                                            id="userName"
                                            name="name"
                                            #name="ngModel"
                                            [(ngModel)]="contactToUpdate.name"
                                          >
                                            
                                        </div>
            
                                        <div class="form-group">
                                          <label for="nit" class="col-form-label">NIT:</label>
                                          <input 
                                            type="text" 
                                            class="form-control" 
                                            id="nit"
                                            name="nit"
                                            #nit="ngModel"
                                            [(ngModel)]="contactToUpdate.nit"
                                          > 
                                        </div>
            
                                        <div class="form-group">
                                          <label for="userEmail" class="col-form-label">Correo electr&oacute;nico:</label>
                                          <input 
                                            type="text" 
                                            class="form-control" 
                                            id="userEmail"
                                            name="email"
                                            #email="ngModel"
                                            [(ngModel)]="contactToUpdate.email"
                                          >
                                        </div>
            
                                        <div class="form-group">
                                          <label for="userPhone" class="col-form-label">Tel&eacute;fono:</label>
                                          <input 
                                            type="text" 
                                            class="form-control" 
                                            id="userPhone"
                                            name="phone"
                                            #phone="ngModel"
                                            [(ngModel)]="contactToUpdate.phone"
                                          >
                                        </div>
            
                                        <div class="form-group">
                                          <label for="userRol" class="col-form-label">Asesor:</label>
                                          <select 
                                            class="form-control" 
                                            id="userRol"
                                            name="owner"
                                            #owner="ngModel"
                                            [(ngModel)]="contactToUpdate.owner._id"
                                          >
                                            <option  *ngFor="let user of users" value="{{user._id}}">{{user.name}} {{user.surname}}</option>
                                          </select>
                                        </div>
            
                                        <div class="form-group">
                                          <label for="origin" class="col-form-label">Origen:</label>
                                          <input 
                                            type="text" 
                                            class="form-control" 
                                            id="origin"
                                            name="origin"
                                            #origin="ngModel"
                                            [(ngModel)]="contactToUpdate.origin"
                                          >
                                        </div>
                                        <div class="form-group">
                                          <div class="form-check">
                                            <input 
                                              class="form-check-input" 
                                              type="checkbox" 
                                              id="isCompany"
                                              name="isCompany"
                                              #isCompany="ngModel"
                                              [(ngModel)]="contactToUpdate.isCompany"
                                            >
                                            <label class="form-check-label" for="isCompany">
                                              Seleccionar si el contacto es desarrollador.
                                            </label>
                                          </div>
                                       </div>

                                        <div class="form-group" *ngIf="contactToUpdate.isCompany">
                                          <label for="companyNameCreate">Nombre del Desarrollador:</label>
                                          <select 
                                            style="width:100%;" 
                                            class="form-control select2" 
                                            id="companyNameCreate"
                                            name="companyID"
                                            #companyID="ngModel"
                                            [(ngModel)]="contactToUpdate.companyID"
                                          >
                                              <option disabled selected value> -- Seleccionar -- </option>
                                              <option *ngFor="let company of companies" value="{{company._id}}">{{company.label}}</option>
                                          </select>
                                        </div>

                                      </form>
                                    </div>
                                    <div class="modal-footer">
                                      <button type="button" class="btn btn-secondary" #closebutton2  data-dismiss="modal">Cerrar</button>
                                      <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="updateUser()">Guardar Cambios</button>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <button type="button" class="btn btn-danger" data-toggle="modal" data-target="#deleteContact" (click)="getCantactToDelete(contact._id)"><i class="far fa-trash-alt"></i></button>

                              <!-- Modal -->
                              <div class="modal fade" id="deleteContact" tabindex="-1" role="dialog" aria-labelledby="deleteContactLabel" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                  <div class="modal-content">
                                    <div class="modal-header">
                                      <h5 class="modal-title" id="deleteContactLabel">Eliminar Contacto</h5>
                                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                    <div class="modal-body">
                                      <p>¿Estás seguro de eliminar este contacto?</p>
                                    </div>
                                    <div class="modal-footer">
                                      <button type="button" class="btn btn-secondary" (click)="deleteContact()" data-dismiss="modal">Eliminar</button>
                                      <button type="button" class="btn btn-primary" #closebutton3  data-dismiss="modal">Salir</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
      </div>
  </div>

<!-- </div> -->





<!-- Modal -->
<div class="modal fade" id="otherData" tabindex="-1" role="dialog" aria-labelledby="otherDataLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="otherDataLabel">Otros datos del contacto</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p *ngIf="contador >= 1">Cantidad de Propiedades: <span class="badge badge-success">{{contador}}</span></p>
        <p *ngIf="contador == null || contador == 0">Cantidad de Propiedades: <span class="badge badge-success">0</span></p>
      </div>
    </div>
  </div>
</div>