<!-- Begin Page Content -->
<div class="container-fluid">

    <div class="row">
        <div class="col-md-12">
            <div class="alert alert-success" role="alert" *ngIf="statusCreate == 'success'  && showAlertCreate">Se ha
                creado el envío satisfactoriamente. <button type="button" class="close" (click)="closeCreateS()" aria-label="Close" ><span aria-hidden="true">&times;</span></button></div>
            <div class="alert alert-success" role="alert" *ngIf="statusEdit == 'success'  && showAlertEdit">Se ha
                editado el envío satisfactoriamente. <button type="button" class="close" (click)="closeEditS()" aria-label="Close" ><span aria-hidden="true">&times;</span></button></div>
            <!-- <div class="alert alert-success" role="alert" *ngIf="statusDelete == 'success'  && showAlertDelete">Se ha
                enviado satisfactoriamente. <button type="button" class="close" (click)="closeDeleteS()" aria-label="Close" ><span aria-hidden="true">&times;</span></button></div> -->
            <div class="alert alert-danger" role="alert" *ngIf="statusCreate == 'error'  && showAlertCreate">No se ha
                podido crear el envío, intenta m&aacute;s tarde. <button type="button" class="close" (click)="closeCreateS()" aria-label="Close" ><span aria-hidden="true">&times;</span></button></div>
            <div class="alert alert-danger" role="alert" *ngIf="statusEdit == 'error'  && showAlertEdit">No se ha podido
                editar el envío, intenta m&aacute;s tarde.  <button type="button" class="close" (click)="closeEditS()" aria-label="Close" ><span aria-hidden="true">&times;</span></button></div>
            <!-- <div class="alert alert-danger" role="alert" *ngIf="statusDelete == 'error'  && showAlertDelete">No se ha
                enviado, intenta m&aacute;s tarde.  <button type="button" class="close" (click)="closeDeleteS()" aria-label="Close" ><span aria-hidden="true">&times;</span></button></div> -->
        </div>
    </div>

    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 class="h3 mb-0 text-gray-800">Editar Envío</h1>
    </div>

    <div class="row">
        <div class="col-lg-8">

            <div class="card shadow mb-4">
                <div class="card-header py-3">
                    <div class="row">
                        <div class="col-md-12">
                            <h6 class="m-0 font-weight-bold text-primary">Datos Generales del envío</h6>
                            <p>Ingresa los valores correspondientes de este envío.</p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <form *ngIf="invite">
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label for="emailContact">Agente:</label>
                                <select id="emailContact" class="form-control" name="user" #user="ngModel"
                                    [(ngModel)]="invite.user">
                                    <option *ngFor="let user of users" value="{{user._id}}">{{user.name}}
                                        {{user.surname}} </option>
                                </select>
                            </div>

                            <div class="form-group col-md-8">
                                <label for="emailReceiver">Email del Receptor:</label>
                                <input type="email" class="form-control" name="receiver" #receiver="ngModel"
                                    [(ngModel)]="invite.receiver" id="emailReceiver">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="emailSubject">Asunto:</label>
                            <input type="email" class="form-control" name="subject" #subject="ngModel"
                            [(ngModel)]="invite.subject" id="emailSubject">
                          </div>

                          <div class="form-group">
                            <label for="emailMessage">Mensaje:</label>
                            <textarea rows="4"  class="form-control" name="message" #message="ngModel"
                            [(ngModel)]="invite.message" id="emailMessage"></textarea>
                          </div>
                    </form>
                </div>
            </div>

            <div class="card shadow mb-4">
                <div class="card-header py-3">
                    <div class="row">
                        <div class="col-md-8">
                            <h6 class="m-0 font-weight-bold text-primary">Propiedades a Enviar</h6>
                        </div>

                        <div class="col-md-4 float-right">

                            <button type="button" class="btn btn-primary" data-toggle="modal"
                                data-target="#createSharedProperty">
                                <i class="fas fa-user"></i> Agregar Propiedad
                            </button>

                            <div class="modal fade" id="createSharedProperty" tabindex="-1" role="dialog"
                                aria-labelledby="createSharedPropertyLabel" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="createSharedPropertyLabel">Agregar Propiedad
                                            </h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <form #saveForm="ngForm" #saveForm="ngForm" (ngSubmit)="onSubmit(saveForm)">
                                                <div class="form-group">
                                                    <label for="propertyModule">Seleccionar Propiedad:</label>
                                                    <select id="propertyModule" class="form-control" name="property"
                                                        #property="ngModel" [(ngModel)]="newProperty.property">
                                                        <option *ngFor="let p of propiedades" value="{{p._id}}">
                                                            {{p.propertyTitle}}</option>
                                                    </select>
                                                </div>

                                                <div class="modal-footer">
                                                    <button type="button" #closebutton class="btn btn-secondary"
                                                        data-dismiss="modal">Cerrar</button>
                                                    <button type="submit" class="btn btn-primary">Agregar
                                                        Propiedad</button>
                                                </div>

                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-bordered" id="datatableexample" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th style="width:120px;">Imagen</th>
                                    <th>Título</th>
                                    <th style="width:80px;"></th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th style="width:120px;">Imagen</th>
                                    <th>Descripción</th>
                                    <th style="width:80px;"></th>
                                </tr>
                            </tfoot>
                            <tbody>
                                <tr *ngFor="let p of data">
                                    <td *ngIf="p.property.propertyFeaturedImage"><img width="100"
                                            src="{{ url +'get-image-property/' + p.property.propertyFeaturedImage }}">
                                        <hr>
                                    </td>
                                    <td *ngIf="!p.property.propertyFeaturedImage"><img width="100"
                                            src="https://olgadetorrebiarte.com/wp-content/uploads/2019/04/2-1-835x467.jpg">
                                        <hr>
                                    </td>

                                    <td>{{p.property.propertyTitle}}</td>
                                    <td>
                                        <button type="button" class="btn btn-danger" data-toggle="modal"
                                            data-target="#deleteSharedProperty"
                                            (click)="getPropertyToDelete(p._id)"><i
                                                class="far fa-trash-alt"></i></button>

                                        <!-- Modal -->
                                        <div class="modal fade" id="deleteSharedProperty" tabindex="-1" role="dialog"
                                            aria-labelledby="deleteSharedPropertyLabel" aria-hidden="true">
                                            <div class="modal-dialog" role="document">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="deleteSharedPropertyLabel">Eliminar
                                                            Propiedad</h5>
                                                        <button type="button" class="close" data-dismiss="modal"
                                                            aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <p>¿Estás seguro de eliminar esta propiedad?</p>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button"  (click)="deleteProperty()"
                                                            class="btn btn-secondary">Eliminar</button>
                                                        <button type="button" #closebutton3 class="btn btn-primary"
                                                            data-dismiss="modal">Salir</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </td>
                                </tr>


                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>

        <div class="col-lg-4">

            <div class="card shadow mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 class="m-0 font-weight-bold text-primary">Acciones</h6>
                </div>
                <div class="card-body">

                    <button style="width:100%;" type="button" (click)="sendMail()" class="btn btn-warning">Enviar
                        Correo</button>

                    <hr>

                    <button style="width:100%;" type="button" (click)="updateInvite()" class="btn btn-primary">Guardar
                        Cambios</button>
                </div>
            </div>

        </div>

    </div>

</div>
<!-- /.container-fluid -->