import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { User } from '../../../../users/models/user';
import { UserService } from '../../../../users/services/user.service';
import { Property } from '../../../models/property';
import { PropertyService } from  '../../../services/property.service';
import { PropertyTypeService } from  '../../../../catalogues/components/propertyTypes/services/propertyTypes.service';
import { PropertyType } from  '../../../../catalogues/components/propertyTypes/models/propertyType';
import { Contact } from '../../../../contacts/models/contact';
import { ContactService } from  '../../../../contacts/services/contact.service';
import { Location } from '../../../../catalogues/components/locations/models/location';
import { LocationService } from '../../../../catalogues/components/locations/services/location.service'
import { Departament } from '../../../../catalogues/components/depatament/models/departament';
import { DepartamentService } from '../../../../catalogues/components/depatament/services/department.service'
import { Amenities } from '../../../../catalogues/components/amenities/models/amenities';
import { AmenitieseService } from '../../../../catalogues/components/amenities/services/amenities.service'
import { PropertyProject } from '../../../../catalogues/components/projects/models/project';
import { PropertyProjectService } from '../../../../catalogues/components/projects/services/project.service';
import { PropertyStatus } from '../../../../catalogues/components/status/models/status';
import { PropertyStatusService } from '../../../../catalogues/components/status/services/status.service';
import {Features} from '../../../../catalogues/components/features/models/features';
import {FeaturesService} from '../../../../catalogues/components/features/services/features.service';

import { GLOBAL } from '../../../../../services/global';

declare let $: any;


import { PhotoProperty } from '../../../models/propertyImages';
import { PhotoPropertyService } from  '../../../services/propertyImages.service';

import { DocProperty } from '../../../models/propertyDocs';
import { DocPropertyService } from  '../../../services//propertyDocs.service';

import { CommentProperty } from '../../../models/propertyComment';
import { CommentService } from  '../../../services/propertyComment.service';

import { AmenityProperty } from '../../../models/propertyAmenity';
import { PropertyAmenityService } from  '../../../services/propertyAmenity.service';

import { PropertyModel } from '../../../models/propertyModel';
import { ModelPropertyService } from '../../../services/propertyModel.service';

import {FeatureProperty} from '../../../models/propertyFeature';
import {PropertyFeaturesService} from '../../../services/property-features.service';

// import { formatCurrency } from '@angular/common';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic'; //editor

import { SharedProperty } from '../../../models/sharedProperties';
import { SendProperty } from '../../../models/sendProperties';
import { SharedPropertiesService } from '../../../services/shared-properties.service';
import { SendPropertiesService } from '../../../services/send-properties.service';

import { Company } from '../../../../catalogues/components/companies/models/company';
import { CompanyService } from  '../../../../catalogues/components/companies/services/company.service'

@Component({
    selector: 'propertyInternal',
    templateUrl: './propertyInternal.component.html',
    styleUrls: ['./propertyInternal.component.scss'],
        providers:[ UserService, 
                    PropertyService, 
                    PropertyTypeService, 
                    ContactService, 
                    LocationService,
                    PhotoPropertyService,
                    DocPropertyService,
                    CommentService,
                    PropertyAmenityService,
                    AmenitieseService,
                    PropertyProjectService,
                    PropertyStatusService,
                    DepartamentService,
                    ModelPropertyService,
                    PropertyFeaturesService,
                    FeaturesService,
                    SendPropertiesService, 
                    SharedPropertiesService,
                    CompanyService
                  ]
})

export class PropertyInternalComponent implements OnInit{
    @ViewChild('closebutton') closebutton;
    @ViewChild('closebutton2') closebutton2;
    @ViewChild('closebutton3') closebutton3;
    @ViewChild('closebutton4') closebutton4;
    @ViewChild('closebutton5') closebutton5;
    @ViewChild('closebutton6') closebutton6;
    @ViewChild('closebutton7') closebutton7;
    @ViewChild('closebutton8') closebutton8;
    @ViewChild('closebutton9') closebutton9;
    @ViewChild('closebutton10') closebutton10;
    @ViewChild('closebutton11') closebutton11;
    @ViewChild('closebutton12') closebutton12;
    @ViewChild('closebutton13') closebutton13;
    @ViewChild('closebutton14') closebutton14;
    @ViewChild('closebutton15') closebutton15;
    @ViewChild('closebutton15') closebutton16;
    public title: string;
    public IDPropertry: String;
    public property: Property;
    public propertyToUpdate: User;
    public propertyToDelete: User;
    public properties: Property[];
    public propertyTypes: PropertyType[];
    public contacts: Contact[];
    public locations: Location[];
    public departments: Departament[];
    public amenities: Amenities[];
    public features:Features[];
    public projects: PropertyProject[];
    public status: PropertyStatus[];
    public users: User[];
    public images: PhotoProperty[];
    public image: PhotoProperty;
    public imageToUpdate: PhotoProperty;
    public imageToDelete: PhotoProperty;
    public docs: DocProperty[];
    public doc: DocProperty;
    public docToUpdate: DocProperty;
    public docToDelete: DocProperty;
    public comments: CommentProperty[];
    public comment: CommentProperty;
    public commentToUpdate: CommentProperty;
    public commentToDelete: CommentProperty;
    public amentiyProperties: AmenityProperty[];
    public amentiyProperty: AmenityProperty;
    public amentiyPropertyToUpdate: AmenityProperty;
    public amentiyPropertyToDelete: AmenityProperty;
    public featureProperties: FeatureProperty[];
    public featureProperty: FeatureProperty;
    public featurePropertyToUpdate: FeatureProperty;
    public featurePropertyToDelete: FeatureProperty;
    public modelProperties: PropertyModel[];
    public modelProperty: PropertyModel;
    public modelPropertyToUpdate: PropertyModel;
    public modelPropertyToDelete: PropertyModel;
    public url: String;
    public identity;
    public token;
    public statusCreate: string;
    public statusEdit: string;
    public statusDelete: string;
    public statusGetProperties: string;
    public statusGetProperty: string;
    public statusGetContacts: string;
    public statusGetUsers: string;
    public statusGetImages: string;
    public statusGetDocs: string;
    public statusGetComments: string;
    public statusSaveImages: string;
    public statusEditImages: string;
    public statusDeleteImages: string;
    public statusGetImageToUpdate: string;
    public statusGetImageToDelete: string;
    public statusSaveDocs: string;
    public statusEditDocs: string;
    public statusDeleteDocs: string;
    public statusGetDocToUpdate: string;
    public statusGetDocToDelete: string;

    public statusSaveComment: string;
    public statusEditComment: string;
    public statusDeleteComment: string;
    public statusGetCommentToUpdate: string;
    public statusGetCommentToDelete: string;

    public statusSaveAmentiyProperty: string;
    public statusDeleteAmentiyProperty: string;
    public statusGetAmentiyPropertyToDelete: string;

    public statusSaveFeatureProperty: string;
    public statusDeleteFeatureProperty: string;
    public statusGetFeaturePropertyToDelete: string;

    public statusSaveModelProperty: string;
    public statusDeleteModelProperty: string;
    public statusGetModelPropertyToUpdate: String
    public statusGetModelPropertyToDelete: string;



    public hasContacts: Boolean;
    public hasPropertyOwner: Boolean;
    public haspropertyType: Boolean;
    public haspropertyLocation: Boolean;
    public haspropertyProject: Boolean;
    public haspropertyStatus: Boolean;

    public Editor=ClassicEditor;

    public invite: SendProperty;
    public newProperty: SharedProperty;

    public statusEmail:String;
    public showEmail:Boolean;

    public newContact:Contact;
    public statusNewContact:String;
    public showNewContact:Boolean;
    public companies: Company[];
    public usersForContacts:User[];

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _userService: UserService,
        private _propertyService: PropertyService,
        private _propertyTypeService: PropertyTypeService,
        private _contactService: ContactService,
        private _locationService: LocationService,
        private _amenitiesService: AmenitieseService,
        private _imagesServices: PhotoPropertyService,
        private _docServices: DocPropertyService,
        private _commentService: CommentService,
        private _amenityPropertyService: PropertyAmenityService,
        private _projectPropertyService: PropertyProjectService,
        private _projectStatusService: PropertyStatusService,
        private _departamentStatusService: DepartamentService,
        private _modelService: ModelPropertyService,
        private _featuresPropertyService:PropertyFeaturesService,
        private _featuresService:FeaturesService,
        private _inviteService: SendPropertiesService,
        private _invitePropertyService: SharedPropertiesService,
        private _companyService: CompanyService
    ){
        this.title = 'Listado de Usuarios';
        this.url = GLOBAL.url;
        this.property = new Property("","","",0,"","","","","","","",0,0,0,0,0,0,0,0,false,"",0,0,false,"","","",0,0,0,2,"",0,0,1,0,0,0,0,"","","");
        this.image = new PhotoProperty("","","","","","",0,"");
        this.doc = new DocProperty("","","","","",0,"");
        this.comment = new CommentProperty("","","","","","");
        this.amentiyProperty = new AmenityProperty("","","","");
        this.featureProperty = new FeatureProperty("","","","");
        this.modelProperty = new PropertyModel("","","","","","",0,0,0,0,"",0,"");
        this.identity = this._userService.getIdentity();
        this.token = this._userService.getToken();

        this.invite=new SendProperty("",this.identity._id,"","","","","","","");
        this.newContact= new Contact("","","","","","","","","",false,"");
        

        this.hasContacts = false;
        this.hasPropertyOwner = false;
        this.haspropertyType = false;
        this.haspropertyLocation = false;
        this.haspropertyProject = false
        this.haspropertyStatus = false
        
    }

    public exampleData: any;
    public Cvalue: String;

    ngOnInit(){

        

        //Select2 demo data
      this.exampleData = [
        {
          id: '6014e244c1a6030fc922962c',
          text: 'Witlab'
        },
        {
          id: '6014e2c1c1a6030fc922962d',
          text: 'BUSISSOFT'
        },
        {
          id: '6014e35ec1a6030fc922962e',
          text: 'Nuevo Contacto'
        }
      ];

      //console.log('data de prueba', this.exampleData )

        this.ObternerId();
        this.getProperties();
        this.getPropertyTypes();
        this.getContacts();
        this.getUsers();
        //this.getLocations();
        this.getDepartments();
        this.getAmenities();
        this.getProjects();
        this.getStatus();
        this.getFeatures();
        
        this.getImages();
        this.getDocs();
        this.getComments();
        this.getAmenityProperties();
        this.getModelProperties();
        this.getFeatureProperties();

        this.newProperty = new SharedProperty('', '', this.IDPropertry);
        this.getCompanies();
        this.getUsersForContacts();
        // this.validateSlug();

    }

    // validateSlug(){
    //     $('#propertySlug').bind('input', function() {
    //         let c = this.selectionStart,
    //             r = /[^a-z0-9 _-]/gi,
    //             v = $(this).val();
    //         if(r.test(v)) {
    //           $(this).val(v.replace(r, ''));
    //           c--;
    //         }
            
    //         this.setSelectionRange(c, c);
    //       });
    // }

    getCompanies(){
        this._companyService.getCompanies(this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetContacts = 'error';
                }else{
                    this.companies = response.data;
                    //console.log(this.companies);
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetContacts = 'error';
                }
            }
        );
    }

    getUsersForContacts(){
        this._userService.getUsers().subscribe(
            response => {
                if(!response.users){
                    this.statusGetContacts = 'error';
                }else{
                    this.usersForContacts = response.users;
                   // console.log(this.users);
                }
            },
            error => {
                var errorMessage = <any>error;
               // console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetContacts = 'error';
                }
            }
        );
    }
    


    ObternerId(){
        this._route.params.subscribe(params =>{
            let id = params['id'];
            this.IDPropertry = id;

            this.getProperty(id);
        })
    }


    getProperty(id:String){
        this._propertyService.getProperty(id, this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetProperty = 'error';
                }else{
            
                    this.property = response.data;
                    if(!this.property.iusiCurrency){this.property.iusiCurrency=1}
                    if(!this.property.maintenanceCurrency){this.property.maintenanceCurrency=1}
                    if(!this.property.propertyAreaType){this.property.propertyAreaType=2}
                    if(!this.property.propertyConstructionAreaType){this.property.propertyConstructionAreaType=1}
                    //console.log(this.property);

                    if(response.data.contact){
                        this.hasContacts = true;
                        this.Cvalue=response.data.contact._id;
                    }

                    if(response.data.propertyOwner){
                        this.hasPropertyOwner = true;
                    }

                    if(response.data.propertyType){
                        this.haspropertyType = true;
                    }

                    if(response.data.propertyLocation){
                        this.haspropertyLocation = true;
                    } 

                    if(response.data.propertyProject){
                        this.haspropertyProject = true;
                    } 

                    if(response.data.propertyStatus){
                        this.haspropertyStatus = true;
                    } 

                    if(response.data.propertyState){
                        this.getLocations(response.data.propertyState);
                    }
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
        
                if(errorMessage != null){
                    this.statusGetProperty = 'error';
                }
            }
        );

    }


    getProperties(){
        this._propertyService.getProperties(this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetProperties = 'error';
                }else{
                    this.properties = response.data;
                    //console.log(this.properties);
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetProperties = 'error';
                }
            }
        );
    }

    getPropertyTypes(){
        this._propertyTypeService.getPropertyTypes(this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetProperties = 'error';
                }else{
                    this.propertyTypes = response.data;
                    //console.log(this.propertyTypes);
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetProperties = 'error';
                }
            }
        );
    }

    getContacts(){
        this._contactService.getContactsToSelect(this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetContacts = 'error';
                }else{
                    this.contacts = response.data;
                    //this.exampleData= response.data
                    //(this.contacts);
                    //console.log('Contactos reales ', this.contacts )
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetContacts = 'error';
                }
            }
        );
    }

    public changed(e: any): void {
  
       //console.log("cambio", e)
       this.Cvalue = e;
    }    
   
    getUsers(){
        this._userService.getUserSaleman().subscribe(
            response => {
                if(!response.users){
                    this.statusGetUsers = 'error';
                }else{
                    this.users = response.users;
                    //console.log(this.users);
                }
            },
            error => {
                var errorMessage = <any>error;
               // console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetUsers = 'error';
                }
            }
        );
    }

    getLocations(departament:String){
        // console.log(departament)
        this._locationService.getLocationsByParent(departament, this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetContacts = 'error';
                }else{
                    this.locations = response.data;
                    // console.log(this.locations);
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetContacts = 'error';
                }
            }
        );
    }

    getDepartments(){
        this._departamentStatusService.getDepartaments(this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetContacts = 'error';
                }else{
                    this.departments = response.data;
                    //console.log('depatamentos', this.departments);
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetContacts = 'error';
                }
            }
        );
    }

    public changedDepartament(e: any): void {
        this.getLocations(this.property.propertyState);
     }    



    getAmenities(){
        this._amenitiesService.getAmenities(this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetContacts = 'error';
                }else{
                    this.amenities = response.data;
                    //console.log(this.amenities);
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetContacts = 'error';
                }
            }
        );
    }

    getFeatures(){
        this._featuresService.getFeatures(this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetContacts = 'error';
                }else{
                    this.features = response.data;
                   // console.log(this.features);
                }
            },
            error => {
                var errorMessage = <any>error;
                console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetContacts = 'error';
                }
            }
        );
    }

    getProjects(){
        this._projectPropertyService.getPropertyProjects(this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetContacts = 'error';
                }else{
                    this.projects = response.data;
                    //console.log('Projects:', this.projects);
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetContacts = 'error';
                }
            }
        );
    }

    getStatus(){
        this._projectStatusService.getPropertyAllStatus(this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetContacts = 'error';
                }else{
                    this.status = response.data;
                    //console.log('Status:', this.status);
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetContacts = 'error';
                }
            }
        );
    }
    
    onSubmit() {
        // this.closebutton.nativeElement.click();
        //console.log(this.property);
        //this.property.contact = this.Cvalue;
        this._propertyService.updateProperty(this.property, this.token).subscribe(
            response => {
                console.log(response);
                if(!response.data){
                    this.statusCreate = 'error';
                    this.showAlertEditF = true;
                   // console.log('Error al guardar el token');
                }else{
                  this.statusCreate = 'success';
                  this.showAlertEdit = true;
                  //console.log("response");
                  //console.log(response);
                  this.ObternerId();
                }

                //SUBIDA DE IMAGEN
                if(this.filesToUpload && this.filesToUpload.length)
                {
                   console.log(676);
                    this._propertyService.makeFileRequest(this.url+'upload-image-porperty/'+response.data._id,[], this.filesToUpload, this.token, 'img')
                                    .then((result: any) =>{
                                        console.log(result,679);
                                            // this.property.propertyFeaturedImage = result.property.propertyFeaturedImage;
                                            this._propertyService.makeFileRequest(this.url+'upload-thumbnail-porperty/'+response.data._id,[], this.filesToUpload, this.token, 'img')
                                                    .then((result: any) =>{
                                                        console.log(result,683);
                                                        this.ObternerId();
                                                        this.statusCreate = 'success';
                                                    });
                                            
                                            //localStorage.setItem('identity', JSON.stringify(this.user));
                                    });
                }else{
                    //localStorage.setItem('identity', JSON.stringify(this.user));
                    //this._router.navigate(['/home']);
                }

                //SUBIDA DE ARCHIVO
                if(this.filesToUpload2 && this.filesToUpload2.length)
                {
                    //console.log(response.data._id);
                    this._propertyService.makeFileRequest2(this.url+'upload-property-doc/'+response.data._id,[], this.filesToUpload2, this.token, 'img')
                                    .then((result: any) =>{
                                       // console.log(result);
                                            // this.property.propertyFeaturedImage = result.property.propertyFeaturedImage;
                                            this.ObternerId();
                                            this.statusCreate = 'success';
                                            //localStorage.setItem('identity', JSON.stringify(this.user));
                                    });
                }else{
                    //localStorage.setItem('identity', JSON.stringify(this.user));
                    //this._router.navigate(['/home']);
                }
  
            },error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
  
                if(errorMessage != null){
                    this.statusCreate = 'error';
                }
            }
        );
    }

    public filesToUpload: Array<File>;
    fileChangeEvent(fileInput: any){
        this.filesToUpload = <Array<File>>fileInput.target.files;
        //console.log(this.filesToUpload)
    }

    public filesToUpload2: Array<File>;
    fileChangeEvent2(fileInput: any){
        this.filesToUpload2 = <Array<File>>fileInput.target.files;
        //console.log(this.filesToUpload2)
    }

    getPropertyToDelete(id:String){
        this._propertyService.getProperty(id, this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetProperty = 'error';
                }else{
            
                    this.propertyToDelete = response.data;
                    //console.log(this.propertyToDelete);                   
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
        
                if(errorMessage != null){
                    this.statusGetProperty = 'error';
                }
            }
        );

    }

    updateUser(){
        this.closebutton2.nativeElement.click();
        this._propertyService.updateProperty(this.propertyToUpdate, this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusEdit = 'error';
                }else{
                    this.getProperties();
                    this.statusEdit = 'success';
                    //console.log(this.propertyToUpdate);
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
        
                if(errorMessage != null){
                    this.statusEdit = 'error';
                }
            }
        );
       
    }

    deleteProperty(){
        this.closebutton3.nativeElement.click();
        this._propertyService.deleteProperty(this.propertyToDelete._id, this.token).subscribe(
            response => {
               // this.identity = response.data;
                //console.log(response);
                if(!response.data){
                    this.statusDelete = 'error';
                }else{
                  //console.log("response");
                  //console.log(response);
                  this.statusDelete = 'success';
                  this.getProperties();
                }
      
            },error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
      
                if(errorMessage != null){
                    this.statusDelete = 'error';
                }
            }
        );
        
    }




    //=========================== IMAGES ===================
    getImages(){
        this._imagesServices.getPhotoProperties(this.IDPropertry ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetImages = 'error';
                }else{
                    this.images = response.data;
                    //console.log(this.images);
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetImages = 'error';
                }
            }
        );
    }

    SaveImage(creteImage:any){
        this.closebutton.nativeElement.click();
        this.image.propertyID = this.IDPropertry;
        this._imagesServices.savePhotoProperty(this.image ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusSaveImages = 'error';
                }else{
                    //console.log(this.image);
                    this.getImages();
                    creteImage.reset();


                    //SUBIDA DE ARCHIVO
                if(this.filesToUpload3 && this.filesToUpload3.length)
                {

                    this._imagesServices.makeFileRequest(this.url+'upload-photo/'+response.data._id,[], this.filesToUpload3, this.token, 'img')
                                    .then((result: any) =>{
                                       
                                        this.getImages();
                                        this.statusSaveImages = 'success';

                                    });
                    this._imagesServices.makeFileRequest(this.url+'upload-thumbnail-photo/'+response.data._id,[], this.filesToUpload3, this.token, 'img')
                                    .then((result: any) =>{
                                        // console.log(result);
                                        // this.property.propertyFeaturedImage = result.property.propertyFeaturedImage;
                                        this.getImages();
                                        this.statusSaveImages = 'success';
                                        // this.image = new PhotoProperty("","","","","","",0,"");
                                        //localStorage.setItem('identity', JSON.stringify(this.user));
                                    });

                }else{
                    //localStorage.setItem('identity', JSON.stringify(this.user));
                    //this._router.navigate(['/home']);
                }
                }
            },
            error => {
                var errorMessage = <any>error;
                // console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusSaveImages = 'error';
                }
            }
        );
    }

    public filesToUpload3: Array<File>;
    fileChangeEvent3(fileInput: any){
        this.filesToUpload3 = <Array<File>>fileInput.target.files;
        //console.log(this.filesToUpload3)
    }

    getImageToUpdate(id:string){
        this._imagesServices.getPhotoProperty(id ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetImageToUpdate = 'error';
                }else{
                    this.imageToUpdate = response.data;
                    //console.log(this.imageToUpdate);
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetImageToUpdate = 'error';
                }
            }
        );
    }

    getImageToDelete(id:string){
        this._imagesServices.getPhotoProperty(id ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetImageToDelete = 'error';
                }else{
                    this.imageToDelete = response.data;
                    //console.log(this.imageToDelete);
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetImageToDelete = 'error';
                }
            }
        );
    }



    UpdateImage(){
        this.closebutton2.nativeElement.click();
        this.imageToUpdate.propertyID = this.IDPropertry;
        this._imagesServices.updatePhotoProperty(this.imageToUpdate ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusEditImages = 'error';
                }else{
                    //console.log(this.image);
                    this.getImages();
                    
                    //SUBIDA DE ARCHIVO
                    if(this.filesToUpload4 && this.filesToUpload4.length)
                    {

                        this._imagesServices.makeFileRequest(this.url+'upload-photo/'+response.data._id,[], this.filesToUpload4, this.token, 'img')
                                        .then((result: any) =>{
                                            
                                            this.getImages();
                                            this.statusEditImages = 'success';

                                        });

                        this._imagesServices.makeFileRequest(this.url+'upload-thumbnail-photo/'+response.data._id,[], this.filesToUpload4, this.token, 'img')
                                .then((result: any) =>{
                                    //console.log(result);
                                        // this.property.propertyFeaturedImage = result.property.propertyFeaturedImage;
                                        this.getImages();
                                        this.statusEditImages = 'success';
                                        //localStorage.setItem('identity', JSON.stringify(this.user));
                                });
                    }else{
                        //localStorage.setItem('identity', JSON.stringify(this.user));
                        //this._router.navigate(['/home']);
                    }
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusSaveImages = 'error';
                }
            }
        );
    }

    public filesToUpload4: Array<File>;
    fileChangeEvent4(fileInput: any){
        this.filesToUpload4 = <Array<File>>fileInput.target.files;
        //console.log(this.filesToUpload4)
    }

    DeleteImage(){
        this.closebutton3.nativeElement.click();
        this.image.propertyID = this.IDPropertry;
        this._imagesServices.deletePhotoProperty(this.imageToDelete._id ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusDeleteImages = 'error';
                }else{
                    //console.log(this.imageToDelete);
                    this.getImages();
                    
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusDeleteImages = 'error';
                }
            }
        );
    }

    //=========================== IMAGES ===================

    //=========================== DOCUMENTOS ===================
    getDocs(){
        this._docServices.getDocProperties(this.IDPropertry ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetDocs = 'error';
                }else{
                    this.docs = response.data;
                    //console.log(this.docs);
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetDocs = 'error';
                }
            }
        );
    }

    SaveDoc(creteDoc:any){
        this.closebutton4.nativeElement.click();
        this.doc.propertyID = this.IDPropertry;
        this._docServices.saveDocProperty(this.doc ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusSaveDocs = 'error';
                }else{
                    //(this.image);
                    this.getDocs();
                    creteDoc.reset();


                    //SUBIDA DE ARCHIVO
                if(this.filesToUpload5 && this.filesToUpload5.length)
                {

                    this._imagesServices.makeFileRequest(this.url+'upload-doc/'+response.data._id,[], this.filesToUpload5, this.token, 'img')
                                    .then((result: any) =>{
                                        //console.log(result);
                                            // this.property.propertyFeaturedImage = result.property.propertyFeaturedImage;
                                            this.getDocs();
                                            this.statusSaveDocs = 'success';
                                            //localStorage.setItem('identity', JSON.stringify(this.user));
                                    });
                }else{
                    //localStorage.setItem('identity', JSON.stringify(this.user));
                    //this._router.navigate(['/home']);
                }
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusSaveDocs = 'error';
                }
            }
        );
    }

    public filesToUpload5: Array<File>;
    fileChangeEvent5(fileInput: any){
        this.filesToUpload5 = <Array<File>>fileInput.target.files;
        //console.log(this.filesToUpload5)
    }

    getDocToUpdate(id:string){
        this._docServices.getDocProperty(id ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetImageToUpdate = 'error';
                }else{
                    this.docToUpdate = response.data;
                   // console.log(this.docToUpdate);
                }
            },
            error => {
                var errorMessage = <any>error;
               // console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetImageToUpdate = 'error';
                }
            }
        );
    }

    getDocToDelete(id:string){
        this._docServices.getDocProperty(id ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetImageToDelete = 'error';
                }else{
                    this.docToDelete = response.data;
                    //console.log(this.docToDelete);
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetImageToDelete = 'error';
                }
            }
        );
    }

    UpdateDoc(){
        this.closebutton5.nativeElement.click();
        this.docToUpdate.propertyID = this.IDPropertry;
        this._docServices.updateDocProperty(this.docToUpdate ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusEditImages = 'error';
                }else{
                    //console.log(this.image);
                    this.getDocs();
                    
                    //SUBIDA DE ARCHIVO
                    if(this.filesToUpload6 && this.filesToUpload6.length)
                    {

                        this._docServices.makeFileRequest(this.url+'upload-doc/'+response.data._id,[], this.filesToUpload6, this.token, 'img')
                                        .then((result: any) =>{
                                           // console.log(result);
                                                // this.property.propertyFeaturedImage = result.property.propertyFeaturedImage;
                                                this.getDocs();
                                                this.statusEditImages = 'success';
                                                //localStorage.setItem('identity', JSON.stringify(this.user));
                                        });
                    }else{
                        //localStorage.setItem('identity', JSON.stringify(this.user));
                        //this._router.navigate(['/home']);
                    }
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusSaveImages = 'error';
                }
            }
        );
    }

    public filesToUpload6: Array<File>;
    fileChangeEvent6(fileInput: any){
        this.filesToUpload6 = <Array<File>>fileInput.target.files;
        //console.log(this.filesToUpload6)
    }

    DeleteDoc(){
        this.closebutton6.nativeElement.click();
        this.docToDelete.propertyID = this.IDPropertry;
        this._docServices.deleteDocProperty(this.docToDelete._id ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusDeleteDocs = 'error';
                }else{
                   // console.log(this.docToDelete);
                    this.getDocs();
                    
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusDeleteDocs = 'error';
                }
            }
        );
    }

    //=========================== DOCUMENTOS ===================

    //=========================== Comments ===================
    getComments(){
        this._commentService.getCommentbyProperty(this.IDPropertry ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetComments = 'error';
                }else{
                    this.comments = response.data;
                   // console.log(this.comments);
                }
            },
            error => {
                var errorMessage = <any>error;
               // console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetComments = 'error';
                }
            }
        );
    }

    SaveComment(creteComment:any){
        this.closebutton7.nativeElement.click();
        this.comment.propertyID = this.IDPropertry;
        this.comment.commentAuthor = this.identity._id;
        this._commentService.saveComment(this.comment ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusSaveComment = 'error';
                }else{
                    //console.log(this.comment);
                    this.getComments();
                    creteComment.reset();
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusSaveComment = 'error';
                }
            }
        );
    }

    getCommentToUpdate(id:string){
        this._commentService.getComment(id ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetCommentToUpdate = 'error';
                }else{
                    this.commentToUpdate = response.data;
                    //console.log(this.commentToUpdate);
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetCommentToUpdate = 'error';
                }
            }
        );
    }

    getCommentToDelete(id:string){
        this._commentService.getComment(id ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetCommentToDelete = 'error';
                }else{
                    this.commentToDelete = response.data;
                    //console.log(this.commentToDelete);
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetCommentToDelete = 'error';
                }
            }
        );
    }

    UpdateComment(){
        this.closebutton8.nativeElement.click();
        this.commentToUpdate.propertyID = this.IDPropertry;
        this._commentService.updateComment(this.commentToUpdate ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusEditComment = 'error';
                }else{
                   // console.log(this.commentToUpdate);
                    this.getComments();
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusEditComment = 'error';
                }
            }
        );
    }

    DeleteComment(){
        this.closebutton9.nativeElement.click();
        this.commentToDelete.propertyID = this.IDPropertry;
        this._commentService.deleteComment(this.commentToDelete._id ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusDeleteComment = 'error';
                }else{
                   // console.log(this.commentToDelete);
                    this.getComments();
                    
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusDeleteComment = 'error';
                }
            }
        );
    }

    //=========================== Comments ===================

    //=========================== Amenity Property  ===================
    getAmenityProperties(){
        this._amenityPropertyService.getPropretyAmenitybyProperty(this.IDPropertry ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetProperties = 'error';
                }else{
                    let tempData = response.data;

                    this.amentiyProperties = tempData.sort( function( a, b ) {
                        return a.propertyAmenity.name < b.propertyAmenity.name ? -1 : a.propertyAmenity.name > b.propertyAmenity.name ? 1 : 0;
                    });
                    
                    
                     
                    
                   // console.log('Amenidades: ', tempData);
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetProperties = 'error';
                }
            }
        );
    }

    SaveAmenityProperty(){
        this.amentiyProperty.propertyID = this.IDPropertry;
        this._amenityPropertyService.savePropretyAmenity(this.amentiyProperty ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusSaveComment = 'error';
                }else{
                    //console.log(this.comment);
                    this.getAmenityProperties();
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusSaveComment = 'error';
                }
            }
        );
    }

    
    getAmenityPropertiToDelete(id:string){
        this._amenityPropertyService.getPropretyAmenity(id ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetAmentiyPropertyToDelete= 'error';
                }else{
                    this.amentiyPropertyToDelete = response.data;
                   // console.log(this.amentiyPropertyToDelete);
                }
            },
            error => {
                var errorMessage = <any>error;
               // console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetAmentiyPropertyToDelete = 'error';
                }
            }
        );
    }

   
    DeleteAmenityProperty(){
        this.closebutton10.nativeElement.click();
        this._amenityPropertyService.deletePropretyAmenity(this.amentiyPropertyToDelete._id ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusDeleteAmentiyProperty = 'error';
                }else{
                    //console.log(this.commentToDelete);
                    this.getAmenityProperties();
                    
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusDeleteAmentiyProperty = 'error';
                }
            }
        );
    }

    //=========================== Amenity Property ===================

    //=========================== Features Property ===================

    getFeatureProperties(){
        this._featuresPropertyService.getPropretyFeaturebyProperty(this.IDPropertry ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetProperties = 'error';
                }else{
                    let tempData = response.data;
                   // console.log(1313,'Caracteristicas: ', tempData);
                    this.featureProperties = tempData.sort( function( a, b ) {
                        return a.propertyFeature.name < b.propertyFeature.name ? -1 : a.propertyFeature.name > b.propertyFeature.name ? 1 : 0;
                    });
                    
                    
                     
                    
                    //console.log('Caracteristicas: ', tempData);
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetProperties = 'error';
                }
            }
        );
    }

    SaveFeatureProperty(){
        this.featureProperty.propertyID = this.IDPropertry;
        this._featuresPropertyService.savePropretyFeature(this.featureProperty ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusSaveComment = 'error';
                }else{
                   // console.log(this.comment);
                    this.getFeatureProperties();
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusSaveComment = 'error';
                }
            }
        );
    }

    
    getFeaturePropertyToDelete(id:string){
        this._featuresPropertyService.getPropretyFeature(id ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetFeaturePropertyToDelete= 'error';
                }else{
                    this.featurePropertyToDelete = response.data;
                    //console.log(this.featurePropertyToDelete);
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetFeaturePropertyToDelete = 'error';
                }
            }
        );
    }

   
    DeleteFeatureProperty(){
        this.closebutton14.nativeElement.click();
        this._featuresPropertyService.deletePropretyFeature(this.featurePropertyToDelete._id ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusDeleteFeatureProperty = 'error';
                }else{
                    this.getFeatureProperties();
                    
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusDeleteFeatureProperty = 'error';
                }
            }
        );
    }


    //=========================== Features Property ===================

    //=========================== Model Property  ===================
    getModelProperties(){
        this._modelService.getModelProperties(this.IDPropertry ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetProperties = 'error';
                }else{
                    this.modelProperties = response.data;        
                   // console.log('Modelos: ', this.modelProperties);
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetProperties = 'error';
                }
            }
        );
    }

    SaveModelProperty(creteModel:any){
       // console.log("Modeeel")
        this.closebutton11.nativeElement.click();
        this.modelProperty.propertyID = this.IDPropertry;
        this._modelService.saveModelProperty(this.modelProperty ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusSaveModelProperty = 'error';
                }else{
                    //console.log(this.modelProperty);
                    creteModel.reset();
                    this.getModelProperties();

                     //SUBIDA DE ARCHIVO
                     if(this.filesToUpload7 && this.filesToUpload7.length)
                     {
 
                         this._docServices.makeFileRequest(this.url+'upload-model/'+response.data._id,[], this.filesToUpload7, this.token, 'modelSrc')
                                         .then((result: any) =>{
                                             
                                            this._docServices.makeFileRequest(this.url+'upload-thumbnail-model/'+response.data._id,[], this.filesToUpload7, this.token, 'modelSrc')
                                                    .then((result: any) =>{
                                                        //console.log(result);
                                                            // this.property.propertyFeaturedImage = result.property.propertyFeaturedImage;
                                                            this.getModelProperties();
                                                            this.statusEditImages = 'success';
                                                            //localStorage.setItem('identity', JSON.stringify(this.user));
                                                    });

                                         });
                     }else{
                         //localStorage.setItem('identity', JSON.stringify(this.user));
                         //this._router.navigate(['/home']);
                     }
                }
            },
            error => {
                var errorMessage = <any>error;
               // console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusSaveModelProperty = 'error';
                }
            }
        );
    }

    public filesToUpload7: Array<File>;
    fileChangeEvent7(fileInput: any){
        this.filesToUpload7 = <Array<File>>fileInput.target.files;
        //console.log(this.filesToUpload7)
    }


    getModelToUpdate(id:string){
        this._modelService.getModelProperty(id ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetModelPropertyToUpdate = 'error';
                }else{
                    this.modelPropertyToUpdate = response.data;
                    //console.log(this.modelPropertyToUpdate);
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetModelPropertyToUpdate = 'error';
                }
            }
        );
    }



    UpdateModel(){
        this.closebutton12.nativeElement.click();
        this.modelPropertyToUpdate.propertyID = this.IDPropertry;
        this._modelService.updateModelProperty(this.modelPropertyToUpdate ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusEditComment = 'error';
                }else{
                    //console.log(this.modelPropertyToUpdate);
                    this.getModelProperties();

                    //SUBIDA DE ARCHIVO
                    if(this.filesToUpload8 && this.filesToUpload8.length)
                    {

                        this._docServices.makeFileRequest(this.url+'upload-model/'+response.data._id,[], this.filesToUpload8, this.token, 'modelSrc')
                                        .then((result: any) =>{
                                            this._docServices.makeFileRequest(this.url+'upload-thumbnail-model/'+response.data._id,[], this.filesToUpload8, this.token, 'modelSrc')
                                            .then((result: any) =>{
                                               // console.log(result);
                                                    // this.property.propertyFeaturedImage = result.property.propertyFeaturedImage;
                                                    this.getModelProperties();
                                                    this.statusEditImages = 'success';
                                                    //localStorage.setItem('identity', JSON.stringify(this.user));
                                            });
                                        });
                    }else{
                        //localStorage.setItem('identity', JSON.stringify(this.user));
                        //this._router.navigate(['/home']);
                    }
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusEditComment = 'error';
                }
            }
        );
    }

    public filesToUpload8: Array<File>;
    fileChangeEvent8(fileInput: any){
        this.filesToUpload8 = <Array<File>>fileInput.target.files;
        //console.log(this.filesToUpload8)
    }

    
    getModelPropertiToDelete(id:string){
        this._modelService.getModelProperty(id ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetModelPropertyToDelete= 'error';
                }else{
                    this.modelPropertyToDelete = response.data;
                   // console.log(this.modelPropertyToDelete);
                }
            },
            error => {
                var errorMessage = <any>error;
               // console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetModelPropertyToDelete = 'error';
                }
            }
        );
    }

   
    DeleteModelProperty(){
        this.closebutton13.nativeElement.click();
        this._modelService.deleteModelProperty(this.modelPropertyToDelete._id ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusDeleteModelProperty = 'error';
                }else{
                    //console.log(this.modelPropertyToDelete);
                    this.getModelProperties();
                    
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusDeleteModelProperty = 'error';
                }
            }
        );
    }

    //=========================== Model Property ===================



    SendEmail(sendEmail: any) {
        // this.closebutton15.nativeElement.click();
    
        this._inviteService.saveInvite(this.token, this.invite).subscribe(
          (response) => {
            if (!response.data) {
              // sendEmail.reset();
              this.statusEmail = 'error';
              this.showEmail = true;
              this.invite.receiver="";
              this.invite.subject="";
              this.invite.message="";
            } else {
              this.statusEmail = 'success';
              this.showEmail = true;
              let idInvite = response.data._id;
              this.newProperty.emailID=idInvite;
              // sendEmail.reset();
              // this.getInvites(true);
              // this._router.navigate(['/shared-properties/' + response.data._id]);
              this._invitePropertyService
                .saveInviteProp(this.token, this.newProperty)
                .subscribe(
                  (response) => {
                    if (!response.data) {
                      // invitationForm.reset();
                      this.statusEmail = 'error';
                      this.showEmail = true;
                    } else {
                      this.statusEmail = 'success';
                      this.showEmail = true;
                      // invitationForm.reset();
    
                      this._inviteService
                        .sendEmail(this.token, { obsjectId: idInvite })
                        .subscribe(
                          (response) => {
                            if (!response.data) {
                              this.statusEmail = 'error';
                              this.showEmail = true;
                            } else {
                              this.statusEmail = 'success';
                              this.showEmail = true;
                            }
                          },
                          (error) => {
                            var errorMessage = <any>error;
                            //console.log(errorMessage);
    
                            if (errorMessage != null) {
                              this.statusEmail = 'error';
                              this.showEmail = true;
    
                              // console.log(357,errorMessage);
                            }
                          }
                        );
                    }
                    this.invite.receiver="";
                    this.invite.subject="";
                    this.invite.message="";
                  },
                  (error) => {
                    var errorMessage = <any>error;
    
                    if (errorMessage != null) {
                      this.statusEmail = 'error';
                      this.showEmail = true;
                    }
                  }
                );
            }
          },
          (error) => {
            var errorMessage = <any>error;
    
            if (errorMessage != null) {
              this.statusEmail = 'error';
              this.showEmail = true;
            }
          }
        );
    
        // console.log(this.identity);
        // console.log("Invite",this.invite);
        // console.log("property",this.newProperty);
        // sendEmail.reset();
      }

      SaveNewContact(){
        this.closebutton16.nativeElement.click();
        //console.log("enviando");
        this._contactService.saveContact(this.newContact, this.token).subscribe(
            response => {
                // console.log(response);
                if(!response.data){
                    //saveForm.reset();
                    this.newContact = new Contact("","","","","","","","","",false,"");
                    if(response.message && response.message.includes("ya existe")){
                        this.statusNewContact = 'repeated';
                    }else{
                        this.statusNewContact = 'error';
                    }
                    
                    this.showNewContact = true;
                    // console.log('Error al guardar el token');
                }else{
                    console.log('contacto selecciondo', response.data._id)
                 
                  this.statusNewContact = 'success';
                  this.showNewContact = true;
                  this.newContact = new Contact("","","","","","","","","",false,"");
                  this.getContacts();   
                  this.property.contact = response.data._id; 
                  this.onSubmit()
                }

               
  
            },error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
  
                if(errorMessage != null){
                    this.statusNewContact = 'error';
                }
            }
        );
    }

    public showAlertEdit: Boolean
    closeEdit(){
        this.showAlertEdit = false;
    }

    public showAlertEditF: Boolean
    closeDelete(){
        this.showAlertEditF = false;
    }

    closeEmail(){
        this.showEmail=false;
    }

    closeContact(){
        this.showNewContact=false;
    }


}
