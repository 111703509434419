import {Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GLOBAL } from '../../../services/global';
import { Role } from '../models/role';

@Injectable()
export class RoleService{
    public url:string;
    public identity:any;
    public token:any;
 

    constructor(public _http: HttpClient){
        this.url = GLOBAL.url;
    }


    getRoles(token:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

                                       
        return this._http.get(this.url+'roles/', {headers: headers});
    }

    

    // updateUser(user: User): Observable<any>{
    //     let params = JSON.stringify(user);
    //     let headers = new HttpHeaders().set('Content-Type','application/json')
    //                                    .set('Authorization',this.getToken());
    //     return this._http.put(this.url+'update-user/'+user.id, params, {headers: headers})

    // }

    // updateUserPass(user: User): Observable<any>{
    //     let params = JSON.stringify(user);
    //     let headers = new HttpHeaders().set('Content-Type','application/json')
    //                                    .set('Authorization',this.getToken());
    //     return this._http.put(this.url+'update-user-pass/'+user.id, params, {headers: headers})

    // }

    // getUser(id):Observable<any>{
    //     let headers = new HttpHeaders().set('Content-Type','application/json')
    //                                    .set('Authorization',this.getToken());

                                       
    //     return this._http.get(this.url+'user/'+id, {headers: headers});
    // }
}