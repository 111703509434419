
<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.11.2/css/all.css">
<link href="https://cdnjs.cloudflare.com/ajax/libs/select2/4.0.3/css/select2.min.css" rel="stylesheet" />
<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/select2/4.0.3/js/select2.min.js"></script>

 
<body id="page-top">
  <!-- Page Wrapper -->
  <div id="wrapper">

      <!-- Sidebar -->
      <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar" *ngIf="identity">

          <!-- Sidebar - Brand -->
          <a class="sidebar-brand d-flex align-items-center justify-content-center" href="index.html" *ngIf="identity">
              <div class="sidebar-brand-text mx-3"><img width="100" src="assets/img/logo-odt-white.png" /></div>
          </a>

          <!-- Divider -->
          <hr class="sidebar-divider my-0" *ngIf="identity">

          <!-- Nav Item - Dashboard -->
          <li class="nav-item active" *ngIf="identity">
              <a routerLink="/"class="nav-link">
                  <i class="fas fa-fw fa-tachometer-alt"></i>
                  <span>Dashboard</span></a>
          </li>

          <!-- Divider -->
          <hr class="sidebar-divider" *ngIf="identity">

          <!-- Heading -->
          <div class="sidebar-heading" *ngIf="identity">
              M&oacute;dulos
          </div>

          <!-- Nav Item - Pages Collapse Menu -->
          <li class="nav-item" *ngIf="identity">
              <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo"
                  aria-expanded="true" aria-controls="collapseTwo">
                  <i class="fas fa-fw fa-cog"></i>
                  <span>Usuarios</span>
              </a>
              <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar" *ngIf="identity">
                  <div class="bg-white py-2 collapse-inner rounded">
                      <h6 class="collapse-header">Secciones:</h6>
                      <a routerLink="/user/list" class="collapse-item">Lista de Usuarios</a>
                      <!-- <a class="collapse-item" href="cards.html">Roles</a> -->
                  </div>
              </div>
          </li>

          <!-- Divider -->
          <hr class="sidebar-divider d-none d-md-block">

          <li class="nav-item">
            <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseContacts"
                aria-expanded="true" aria-controls="collapseContacts">
                <i class="fas fa-fw fa-cog"></i>
                <span>Propietarios</span>
            </a>
            <div id="collapseContacts" class="collapse" aria-labelledby="headingContact" data-parent="#accordionSidebar">
                <div class="bg-white py-2 collapse-inner rounded">
                    <h6 class="collapse-header">Secciones:</h6>
                    <a routerLink="/contacts" class="collapse-item">Lista de Contactos</a>
                    <a routerLink="/companies" class="collapse-item">Lista de Empresas</a>
                </div>
            </div>
        </li>
        <!-- Divider -->
        <hr class="sidebar-divider d-none d-md-block">

        <li class="nav-item">
            <a class="nav-link collapsed" href="#" data-toggle="collapse"
                aria-expanded="true" aria-controls="collapseContacts">
                <i class="fas fa-fw fa-cog"></i>
                <span routerLink="/send-properties">Envíos de Email</span>
            </a>
            
        </li>

        <!-- Divider -->
        <hr class="sidebar-divider d-none d-md-block">

        <li class="nav-item">
            <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseProperties"
                aria-expanded="true" aria-controls="collapseContacts">
                <i class="fas fa-fw fa-cog"></i>
                <span>Propiedades</span>
            </a>
            <div id="collapseProperties" class="collapse" aria-labelledby="headingProperty" data-parent="#accordionSidebar">
                <div class="bg-white py-2 collapse-inner rounded">
                    <h6 class="collapse-header">Secciones:</h6>
                    <a routerLink="/properties" class="collapse-item">Lista de Propiedades</a>
                    <a routerLink="/archive-properties" class="collapse-item">Archivo de Propiedades</a>
                </div>
            </div>
        </li>

        <!-- Divider -->

        <!-- Divider -->
        <hr class="sidebar-divider d-none d-md-block">

        <li class="nav-item">
            <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseCatalogues"
                aria-expanded="true" aria-controls="collapseCatalogues">
                <i class="fas fa-fw fa-cog"></i>
                <span>Cat&aacute;logos</span>
            </a>
            <div id="collapseCatalogues" class="collapse" aria-labelledby="headingCatalogue" data-parent="#accordionSidebar">
                <div class="bg-white py-2 collapse-inner rounded">
                    <h6 class="collapse-header">Secciones:</h6>
                    <a routerLink="/property-types" class="collapse-item" >Tipos de Propiedad</a>
                    <a routerLink="/amenities" class="collapse-item">Amenidades</a>
                    <a routerLink="/departments" class="collapse-item">Departamentos</a>
                    <a routerLink="/locations" class="collapse-item">Ubicaciones</a>
                    <a routerLink="/property-projects" class="collapse-item" >Proyectos</a>
                    <a routerLink="/property-status" class="collapse-item" >Estatus</a>
                    <a routerLink="/features" class="collapse-item" >Caracter&iacute;sticas</a>
                </div>
            </div>
        </li>

        <!-- Divider -->
        <hr class="sidebar-divider d-none d-md-block">


          <!-- Sidebar Toggler (Sidebar) -->
          <div class="text-center d-none d-md-inline">
              <button class="rounded-circle border-0" id="sidebarToggle"></button>
          </div>

      </ul>
      <!-- End of Sidebar -->

      <!-- Content Wrapper -->
      <div id="content-wrapper" class="d-flex flex-column">

          <!-- Main Content -->
          <div id="content">

              <!-- Topbar -->
              <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow" *ngIf="identity">

                  <!-- Sidebar Toggle (Topbar) -->
                  <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
                      <i class="fa fa-bars"></i>
                  </button>

                  <!-- Topbar Navbar -->
                  <ul class="navbar-nav ml-auto">

                      <!-- Nav Item - Search Dropdown (Visible Only XS) -->
                      <li class="nav-item dropdown no-arrow d-sm-none">
                          <a class="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button"
                              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="fas fa-search fa-fw"></i>
                          </a>
                          <!-- Dropdown - Messages -->
                          <div class="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                              aria-labelledby="searchDropdown">
                              <form class="form-inline mr-auto w-100 navbar-search">
                                  <div class="input-group">
                                      <input type="text" class="form-control bg-light border-0 small"
                                          placeholder="Search for..." aria-label="Search"
                                          aria-describedby="basic-addon2">
                                      <div class="input-group-append">
                                          <button class="btn btn-primary" type="button">
                                              <i class="fas fa-search fa-sm"></i>
                                          </button>
                                      </div>
                                  </div>
                              </form>
                          </div>
                      </li>


                      <!-- Nav Item - User Information -->
                      <li class="nav-item dropdown no-arrow">
                          <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <span class="mr-2 d-none d-lg-inline text-gray-600 small">{{identity.name}} {{identity.surname}}</span>
                              <img *ngIf="identity.img" class="img-profile rounded-circle"src="{{ url +'get-image-user/' + identity.img }}">
                              <img *ngIf="!identity.img" class="img-profile rounded-circle"src="assets/img/undraw_profile.svg">
                          </a>
                          <!-- Dropdown - User Information -->
                          <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                              aria-labelledby="userDropdown">
                              <!-- <a class="dropdown-item" href="#">
                                  <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                  Profile
                              </a>
                              <a class="dropdown-item" href="#">
                                  <i class="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                                  Settings
                              </a>
                              <a class="dropdown-item" href="#">
                                  <i class="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                                  Activity Log
                              </a> -->
                              <div class="dropdown-divider"></div>
                              <a class="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal">
                                  <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                  Cerrar Sesión
                              </a>
                          </div>
                      </li>

                  </ul>

              </nav>
              <!-- End of Topbar -->

              <!-- Begin Page Content -->
              <div class="container-fluid">

                  <!-- Page Heading -->
                  <!-- <div class="d-sm-flex align-items-center justify-content-between mb-4"> -->
                      <router-outlet></router-outlet>
                  <!-- </div> -->

              </div>
              <!-- /.container-fluid -->

          </div>
          <!-- End of Main Content -->

          

          <!-- Footer -->
          <footer class="sticky-footer bg-white" *ngIf="identity">
              <div class="container my-auto">
                  <div class="copyright text-center my-auto">
                      <span>Development by Witlab Company</span>
                  </div>
              </div>
          </footer>
          <!-- End of Footer -->

      </div>
      <!-- End of Content Wrapper -->

  </div>
  <!-- End of Page Wrapper -->

  <!-- Scroll to Top Button-->
  <a class="scroll-to-top rounded" href="#page-top" *ngIf="identity">
      <i class="fas fa-angle-up"></i>
  </a>

  <!-- Logout Modal-->
  <div class="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true" *ngIf="identity">
      <div class="modal-dialog" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">¿Quieres cerrar sesión?</h5>
                  <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                  </button>
              </div>
              <div class="modal-body">Selecciona "Cerrar Sesión" si quieres salir de tu sesión actual.</div>
              <div class="modal-footer">
                  <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancelar</button>
                  <a class="btn btn-primary" data-dismiss="modal" (click)="logout()">Cerrar Sesión</a>
              </div>
          </div>
      </div>
  </div>

  <!-- Bootstrap core JavaScript-->
  <script src="vendor/jquery/jquery.min.js"></script>
  <script src="vendor/bootstrap/js/bootstrap.bundle.min.js"></script>

  <!-- Core plugin JavaScript-->
  <script src="vendor/jquery-easing/jquery.easing.min.js"></script>

  <!-- Custom scripts for all pages-->
  <script src="js/sb-admin-2.min.js"></script>

  <!-- Page level plugins -->
  <script src="vendor/chart.js/Chart.min.js"></script>

  <!-- Page level custom scripts -->
  <script src="js/demo/chart-area-demo.js"></script>
  <script src="js/demo/chart-pie-demo.js"></script>

</body>
