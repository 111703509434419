<!-- Begin Page Content -->
<!-- <div class="container-fluid"> -->

<!-- DataTales Example -->
<div class="card shadow mb-4">
    <div class="card-header py-3">
        <div class="row">
            <div class="col-md-12">
                <div class="alert alert-success" role="alert" *ngIf="statusCreate == 'success'  && showAlertCreateS">Se ha creado la caracter&iacute;stica satisfactoriamente. <button type="button" class="close" (click)="closeCrearS()" aria-label="Close" ><span aria-hidden="true">&times;</span></button></div>
                <div class="alert alert-success" role="alert" *ngIf="statusEdit == 'success' && showAlertEditS">Se ha editado la caracter&iacute;stica satisfactoriamente.  <button type="button" class="close" (click)="closeEditS()" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
                <div class="alert alert-success" role="alert" *ngIf="statusDelete == 'success' && showAlertDeleteS">Se ha eliminado la caracter&iacute;stica satisfactoriamente. <button type="button" class="close" (click)="closeDeleteS()" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
                <!-- <div class="alert alert-success" role="alert" *ngIf="statusActivated == 'success'">Se ha activado la
                    caracter&iacute;stica satisfactoriamente.</div> -->
                <div class="alert alert-danger" role="alert" *ngIf="statusCreate == 'error' && showAlertCreate">No se ha podido crear la caracter&iacute;stica, intenta m&aacute;s tarde.<button type="button" class="close" (click)="closeCrear()" aria-label="Close" ><span aria-hidden="true">&times;</span></button></div>
                <div class="alert alert-danger" role="alert" *ngIf="statusEdit == 'error' && showAlertEdit">No se ha podido editar la caracter&iacute;stica, intenta m&aacute;s tarde. <button type="button" class="close" (click)="closeEdit()" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
                <div class="alert alert-danger" role="alert" *ngIf="statusDelete == 'error' && showAlertDelete">No se ha podido eliminar la caracter&iacute;stica, intenta m&aacute;s tarde. <button type="button" class="close" (click)="closeDelete()" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
                <!-- <div class="alert alert-danger" role="alert" *ngIf="statusActivated == 'error'">No se ha podido activar la
                    caracter&iacute;stica, intenta m&aacute;s tarde.</div> -->
            </div>
        </div>

        <div class="row">
            <div class="col-md-10">
                <h6 class="m-0 font-weight-bold text-primary">Listado de Caracter&iacute;sticas</h6>
                <p>Todas las Caracter&iacute;sticas del sistema.</p>
            </div>

            <div class="col-md-2 float-right">

                <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#createType">
                    <i class="fas fa-user"></i> Crear Caracter&iacute;stica
                </button>

                <!-- Modal -->
                <div class="modal fade" id="createType" tabindex="-1" role="dialog" aria-labelledby="createTypeLabel"
                    aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="createTypeLabel">Crear Caracter&iacute;stica</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form #saveForm="ngForm" #saveForm="ngForm" (ngSubmit)="onSubmit(saveForm)">
                                    <div class="form-group">
                                        <label for="propertyTypeName" class="col-form-label">Nombre: <small class="text-secondary">(Colocar en min&uacute;sculas, sin espacios ni tildes)</small> </label>
                                        <input type="text" class="form-control" id="propertyTypeName" name="name"
                                            #name="ngModel" [(ngModel)]="feature.name">

                                    </div>

                                    <div class="form-group">
                                        <label for="propertyTypeLabel" class="col-form-label">Label:</label>
                                        <input type="text" class="form-control" id="propertyTypeLabel" name="label"
                                            #label="ngModel" [(ngModel)]="feature.label">
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" #closebutton class="btn btn-secondary"
                                            data-dismiss="modal">Cerrar</button>
                                        <button type="submit" class="btn btn-primary" id="closeModal">Crear
                                            Caracter&iacute;stica</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-bordered" id="datatableexample" width="100%" cellspacing="0">
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Label</th>
                        <th>Estatus</th>
                        <th style="width:80px;"></th>
                    </tr>
                </thead>
                <tfoot>
                    <tr>
                        <th>Nombre</th>
                        <th>Label</th>
                        <th>Estatus</th>
                        <th style="width:80px;"></th>
                    </tr>
                </tfoot>
                <tbody>
                    <tr *ngFor="let feature of data">
                        <td>{{ feature.name }}</td>
                        <td>{{ feature.label }}</td>
                        <td *ngIf="feature.status =='ACTIVE'"><span class="badge badge-success">Activo</span></td>
                        <td *ngIf="feature.status =='INACTIVE'"><span class="badge badge-secondary">Inactivo</span></td>
                        <td>
                            <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#editUser"
                                (click)="getFeature(feature._id)"><i class="fas fa-edit"></i></button>

                            <!-- Modal -->
                            <div class="modal fade" id="editUser" tabindex="-1" role="dialog"
                                aria-labelledby="editUserLabel" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="editUserLabel">Editar Caracter&iacute;stica</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <form *ngIf="featureToUpdate">

                                                <div class="form-group">
                                                    <label for="propertyTypeName" class="col-form-label">Nombre:</label>
                                                    <input type="text" class="form-control" id="propertyTypeName"
                                                        name="name" #name="ngModel" [(ngModel)]="featureToUpdate.name"
                                                        readonly>

                                                </div>

                                                <div class="form-group">
                                                    <label for="propertyTypeLabel" class="col-form-label">Label:</label>
                                                    <input type="text" class="form-control" id="propertyTypeLabel"
                                                        name="label" #label="ngModel"
                                                        [(ngModel)]="featureToUpdate.label">
                                                </div>

                                            </form>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" #closebutton2
                                                data-dismiss="modal">Cerrar</button>
                                            <button type="button" class="btn btn-primary" data-dismiss="modal"
                                                (click)="updateFeature()">Guardar Cambios</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button type="button" class="btn btn-danger" data-toggle="modal"
                                data-target="#deletefeature" (click)="getFeatureToDelete(feature._id)"><i
                                    class="far fa-trash-alt"></i></button>

                            <!-- Modal -->
                            <div class="modal fade" id="deletefeature" tabindex="-1" role="dialog"
                                aria-labelledby="deletefeatureLabel" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="deletefeatureLabel">Eliminar
                                                Caracter&iacute;stica</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <p>¿Estás seguro de eliminar este Caracter&iacute;stica?</p>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" (click)="deleteFeature()"
                                                data-dismiss="modal">Eliminar</button>
                                            <button type="button" class="btn btn-primary" #closebutton3
                                                data-dismiss="modal">Salir</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- <button type="button" class="btn btn-success" data-toggle="modal"
                                data-target="#activeFeature" (click)="getFeatureToActivate(feature._id)"><i
                                    class="fas fa-check-square"></i></button> -->

                            <!-- Modal -->
                            <!-- <div class="modal fade" id="activeFeature" tabindex="-1" role="dialog"
                                aria-labelledby="activeFeatureLabel" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="activeFeatureLabel">Activar
                                                Caracter&iacute;stica</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <p>¿Estás seguro de Activar esta Caracter&iacute;stica?</p>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" (click)="activeFeature()"
                                                data-dismiss="modal">Activar</button>
                                            <button type="button" class="btn btn-primary" #closebutton3
                                                data-dismiss="modal">Salir</button>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<!-- </div> -->
<!-- /.container-fluid -->