export class PropertyToSearch{
    constructor(
        public propertyOperation: Number,
        public propertyType: String,
        public propertyState:string,
        public propertyLocation:string,
        public minPrice:any,
        public maxPrice:any,
        public stage:Number,
        public dorms:Number
        ){}
    }