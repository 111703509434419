export class SendProperty{
    constructor(
        public _id: String,
        public user: any,
        public subject:String,
        public message:String,
        public receiver: String,
        public sent: String,
        public status: String,
        public createdAt:String,
        public updatedAt:String,
    ){}
}