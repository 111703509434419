<!-- DataTales Example -->
<div class="card shadow mb-4">
    <div class="card-header py-3">
        <div class="row">
            <div class="col-md-10">
                <h6 class="m-0 font-weight-bold text-primary">Listado de Envíos</h6>
                <p>Todos los envíos del sistema.</p>
            </div>

            <div class="col-md-2 float-right">

                <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#createEmail">
                    <i class="fas fa-user"></i> Crear Envío
                </button>

                <div class="modal fade" id="createEmail" tabindex="-1" role="dialog" aria-labelledby="createEmailLabel"
                    aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="createEmailLabel">Crear Envío</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form #saveForm="ngForm" #saveForm="ngForm" (ngSubmit)="onSubmit(saveForm)">
                                    <div class="form-group">
                                        <label for="emailReceiver">Email del Receptor:</label>
                                        <input type="email" class="form-control" id="emailReceiver" name="receiver"
                                            #receiver="ngModel" [(ngModel)]="invite.receiver">
                                    </div>
                                    <div class="form-group">
                                        <label for="emailSubject">Asunto:</label>
                                        <input type="email" class="form-control" id="emailSubject" name="subject"
                                            #subject="ngModel" [(ngModel)]="invite.subject">
                                    </div>
                                    <div class="form-group">
                                        <label for="emailMessage">Mensaje:</label>
                                        <textarea rows="4" type="email" class="form-control" id="emailMessage" name="message"
                                            #message="ngModel" [(ngModel)]="invite.message"></textarea>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" #closebutton class="btn btn-secondary"
                                            data-dismiss="modal">Cerrar</button>
                                        <button type="submit" class="btn btn-primary">Continuar</button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-bordered" id="datatableexample" width="100%" cellspacing="0">
                <thead>
                    <tr>
                        <th>Agente</th>
                        <th>Receptor</th>
                        <th>Asunto</th>
                        <th>Mensaje</th>
                        <th>Status</th>
                        <th style="width:80px;"></th>
                    </tr>
                </thead>
                <tfoot>
                    <tr>
                        <th>Agente</th>
                        <th>Receptor</th>
                        <th>Asunto</th>
                        <th>Mensaje</th>
                        <th>Status</th>
                        <th style="width:80px;"></th>
                    </tr>
                </tfoot>
                <tbody>
                    <tr *ngFor="let invite of data">
                        <td>{{invite.user.name}} {{invite.user.surname}}</td>
                        <td>{{invite.receiver}}</td>
                        <td>{{invite.subject}} </td>
                        <td>{{invite.message}} </td>
                        <td *ngIf="!invite.sent"><span class="badge badge-secondary">No Enviado</span></td>
                        <td *ngIf="invite.sent"><span class="badge badge-primary">Enviado</span></td>
                        <td>
                            <button type="button" (click)="getInvite(invite._id)" class="btn btn-primary"><i
                                    class="fas fa-edit"></i></button>
                            <button type="button" class="btn btn-danger" data-toggle="modal" data-target="#deleteEmail"
                                (click)="getInviteToDelete(invite._id)"><i class="far fa-trash-alt"></i></button>

                            <!-- Modal -->
                            <div class="modal fade" id="deleteEmail" tabindex="-1" role="dialog"
                                aria-labelledby="deleteEmailLabel" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="deleteEmailLabel">Eliminar Envío</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <p>¿Estás seguro de eliminar este envío?</p>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" (click)="deleteInvite()"
                                                class="btn btn-secondary">Eliminar</button>
                                            <button type="button" #closebutton2 class="btn btn-primary"  data-dismiss="modal">Salir</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>