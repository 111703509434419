import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http'; //nuevo modulo para realzar peticciones ajax
import { routing, appRoutingProviders } from './app-routing.module';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import {DataTablesModule} from 'angular-datatables';
//import { LSelect2Module } from 'ngx-select2';
import { NgSelect2Module } from 'ng-select2';
//import { Select2Module } from 'ng2-select2';
//import { MomentModule } from 'angular2-moment';
// import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';


import { AppComponent } from './app.component';

import { UserService } from './components/users/services/user.service';
import { SecurityGuard } from './services/security.guard';

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/users/components/login/login.component';
import { ForgotPassword} from './components/users/components/forgotPassword/step-1/forgotPassword.component';
import { ForgotPasswordChange } from './components/users/components/forgotPassword/step-2/forgotPasswordChange.component';
import { RegisterComponent } from './components/users/components/register/components/register.component';
import { UserListComponent } from './components/users/components/usersList/userList.component';
import {  ContactComponent } from './components/contacts/components/contact.component';
import {  PropertyTypeComponent } from './components/catalogues/components/propertyTypes/components/propertyType.component';
import {  PropertyProjectComponent } from './components/catalogues/components/projects/components/project.component';
import {  PropertyStatusComponent } from './components/catalogues/components/status/components/status.component';
import {  LocationComponent } from './components/catalogues/components/locations/components/location.component';
import { AmenityTypeComponent  } from './components/catalogues/components/amenities/components/amenities.component';
import { PropertyComponent  } from './components/propieties/components/property.component';
import { PropertyInternalComponent  } from './components/propieties/components/propertyInternal/components/propertyInternal.component';
import {  CompanyComponent } from './components/catalogues/components/companies/components/company.component';
import {  DepartamentComponent } from './components/catalogues/components/depatament/components/departament.component';
import { FeaturesComponent } from './components/catalogues/components/features/components/features.component';
import { PropertiesArchiveComponent } from './components/propieties/components/propertiesArchive/components/properties-archive.component';
import { SendPropertiesComponent } from './components/propieties/components/sendProperties/components/send-properties.component';
import { SharedPropertiesComponent } from './components/propieties/components/sendProperties/components/sharedProperties/components/shared-properties.component';
import { ViewPropertyComponent } from './components/propieties/components/view-property/view-property.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    ForgotPassword,
    ForgotPasswordChange,
    RegisterComponent,
    UserListComponent,
    ContactComponent,
    PropertyTypeComponent,
    PropertyProjectComponent,
    PropertyStatusComponent,
    LocationComponent,
    AmenityTypeComponent,
    PropertyComponent,
    PropertyInternalComponent,
    CompanyComponent,
    DepartamentComponent,
    FeaturesComponent,
    PropertiesArchiveComponent,
    SendPropertiesComponent,
    SharedPropertiesComponent,
    ViewPropertyComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    routing,
    HttpClientModule,
    MomentModule,
    DataTablesModule,
    NgSelect2Module,
    CKEditorModule
    //Select2Module

  ],
  providers: [
    UserService,
    SecurityGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
