<body class="bg-gradient-primary">

    <div class="container">

        <!-- Outer Row -->
        <div class="row justify-content-center">

            <div class="col-xl-10 col-lg-12 col-md-9">

                <div class="card o-hidden border-0 shadow-lg my-5">
                    <div class="card-body p-0">
                        <!-- Nested Row within Card Body -->
                        <div class="row">
                            <div class="col-lg-6 d-none d-lg-block bg-login-image"></div>
                            <div class="col-lg-6">
                                <div class="p-5">
                                    <div class="text-center">
                                        <img style="margin-bottom:20px;" width="200" src="assets/img/logo-odt.png" />
                                        <h1 class="h4 text-gray-900 mb-4">¡Bienvenido de Nuevo!</h1>

                                        <div class="alert alert-danger" role="alert" *ngIf="status == 'error'">Usuario o contrase&ntilde;a incorrectos.</div>
                                    </div>
                                    <form #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm)" class="user">
                                        <div class="form-group">
                                            <input 
                                                type="email" 
                                                name="email"
                                                id="inputEmail" 
                                                aria-describedby="emailHelp"
                                                #email="ngModel"
                                                [(ngModel)]="user.email"
                                                class="form-control form-control-user"
                                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                                placeholder="Ingresa tu correo electr&oacute;nico..."
                                            >
                                        </div>
                                        <div class="form-group">
                                            <input 
                                                type="password" 
                                                name="password" 
                                                #password="ngModel" 
                                                [(ngModel)]="user.password"
                                                class="form-control form-control-user"
                                                id="inputPassword" 
                                                placeholder="Contrase&ntilde;a"
                                            >
                                        </div>
                                        <div class="form-group">
                                            <div class="custom-control custom-checkbox small">
                                                <input type="checkbox" class="custom-control-input" id="customCheck">
                                                <label class="custom-control-label" for="customCheck">Recordarme</label>
                                            </div>
                                        </div>
                                        <!-- <a href="index.html" class="btn btn-primary btn-user btn-block">
                                            Iniciar Sesi&oacute;n
                                        </a> -->
                                        <input type="submit" [disabled]="!loginForm.form.valid" class="btn btn-primary btn-user btn-block" value="Iniciar Sesi&oacute;n">
                                    </form>
                                    <hr>
                                    <div class="text-center">
                                        <a routerLink="/forgot-password" class="small">¿Has olvidado tu contrase&ntilde;a?</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>
</body>