<!-- Begin Page Content -->
<!-- <div class="container-fluid"> -->

    <!-- DataTales Example -->
    <div class="card shadow mb-4">
        <div class="card-header py-3">
            <div class="row">
                <div class="col-md-12">
                    <div class="alert alert-success" role="alert" *ngIf="statusCreate == 'success' && showAlertCreateS">Se ha creado la ubicaci&oacute;n satisfactoriamente.<button type="button" class="close" (click)="closeCrearS()" aria-label="Close" ><span aria-hidden="true">&times;</span></button></div>
                    <div class="alert alert-success" role="alert" *ngIf="statusEdit == 'success' && showAlertEditS">Se ha editado la ubicaci&oacute;n satisfactoriamente.<button type="button" class="close" (click)="closeEditS()" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
                    <div class="alert alert-success" role="alert" *ngIf="statusDelete == 'success' && showAlertDeleteS">Se ha eliminado la ubicaci&oacute;n satisfactoriamente. <button type="button" class="close" (click)="closeDeleteS()" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
                    <div class="alert alert-danger" role="alert" *ngIf="statusCreate == 'error' && showAlertCreate">No se ha podido crear la ubicaci&oacute;n, intenta m&aacute;s tarde. <button type="button" class="close" (click)="closeCrear()" aria-label="Close" ><span aria-hidden="true">&times;</span></button></div>
                    <div class="alert alert-danger" role="alert" *ngIf="statusEdit == 'error' && showAlertEdit">No se ha podido editar la ubicaci&oacute;n, intenta m&aacute;s tarde. <button type="button" class="close" (click)="closeEdit()" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
                    <div class="alert alert-danger" role="alert" *ngIf="statusDelete == 'error' && showAlertDelete">No se ha podido eliminar la ubicaci&oacute;n, intenta m&aacute;s tarde. <button type="button" class="close" (click)="closeDelete()" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-10">
                    <h6 class="m-0 font-weight-bold text-primary">Listado de Ubicaciones</h6>
                    <p>Todas las ubicaciones del sistema.</p>
                </div>

                <div class="col-md-2 float-right">

                    <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#createType">
                      <i class="fas fa-user"></i> Crear Ubicaci&oacute;n
                    </button>

                    <!-- Modal -->
                    <div class="modal fade" id="createType" tabindex="-1" role="dialog" aria-labelledby="createTypeLabel" aria-hidden="true">
                      <div class="modal-dialog" role="document">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="createTypeLabel">Crear Ubicaci&oacute;n</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <form form #saveForm="ngForm" #saveForm="ngForm" (ngSubmit)="onSubmit(saveForm)">
                              <div class="form-group">
                                <label for="locationName" class="col-form-label">Nombre:  <small class="text-secondary">(Colocar en min&uacute;sculas, sin espacios ni tildes)</small> </label>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    id="locationName"
                                    name="name"
                                    #name="ngModel"
                                    [(ngModel)]="location.name"
                                >
                              </div>

                              <div class="form-group">
                                <label for="locationLabel" class="col-form-label">Label:</label>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    id="locationLabel"
                                    name="label"
                                    #label="ngModel"
                                    [(ngModel)]="location.label"
                                >
                              </div>
                              <div class="form-group">
                                <label for="departmentLabel" class="col-form-label">Departamento:</label>
                                <select 
                                  id="departmentLabel" 
                                  class="form-control"
                                  name="parent"
                                  #parent="ngModel"
                                  [(ngModel)]="location.parent"
                                >
                                    <option *ngFor="let item of parents" value="{{item._id}}">{{item.label}}</option>
                                </select>
                              </div>
                              <div class="modal-footer">
                                <button type="button" #closebutton class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                                <button type="submit"class="btn btn-primary" id="closeModal">Crear Ubicaci&oacute;n</button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-bordered" id="datatableexample" width="100%" cellspacing="0">
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Label</th>
                            <th>Departamentos</th>
                            <th>Estatus</th>
                            <th style="width:80px;"></th>
                        </tr>
                    </thead>
                    <tfoot>
                        <tr>
                            <th>Nombre</th>
                            <th>Label</th>
                            <th>Departamentos</th>
                            <th>Estatus</th>
                            <th style="width:80px;"></th>
                        </tr>
                    </tfoot>
                    <tbody>
                        <tr *ngFor="let location of locations">
                            <td>{{ location.name}}</td>
                            <td data-toggle="modal" data-target="#otherData" (click)="getContactStat(location._id)">{{ location.label}}</td>
                            <td>{{ location.parent.label}}</td>
                            <td *ngIf="location.status =='ACTIVE'"><span class="badge badge-success">Activo</span></td>
                            <td *ngIf="location.status =='INACTIVE'"><span class="badge badge-secondary">Inactivo</span></td>
                            <td>
                                <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#editUser" (click)="getLocation(location._id)"><i class="fas fa-edit"></i></button>

                                <!-- Modal -->
                                <div class="modal fade" id="editUser" tabindex="-1" role="dialog" aria-labelledby="editUserLabel" aria-hidden="true">
                                  <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h5 class="modal-title" id="editUserLabel">Editar Ubicaci&oacute;n</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div class="modal-body">
                                        <form *ngIf="locationToUpdate">

                                            <div class="form-group">
                                                <label for="locationName" class="col-form-label">Nombre:</label>
                                                <input 
                                                    type="text" 
                                                    class="form-control" 
                                                    id="locationName"
                                                    name="name"
                                                    #name="ngModel"
                                                    [(ngModel)]="locationToUpdate.name"
                                                    readonly
                                                >
                                              </div>
                
                                              <div class="form-group">
                                                <label for="locationLabel" class="col-form-label">Label:</label>
                                                <input 
                                                    type="text" 
                                                    class="form-control" 
                                                    id="locationLabel"
                                                    name="label"
                                                    #label="ngModel"
                                                    [(ngModel)]="locationToUpdate.label"
                                                >
                                              </div>
                                              <div class="form-group">
                                                <label for="departmentLabel" class="col-form-label">Departamento:</label>
                                                <select 
                                                  id="departmentLabel" 
                                                  class="form-control"
                                                  name="parent"
                                                  #parent="ngModel"
                                                  [(ngModel)]="locationToUpdate.parent._id"
                                                >
                                                    <option *ngFor="let item of parents" value="{{item._id}}">{{item.label}}</option>
                                                </select>
                                              </div>

                                        </form>
                                      </div>
                                      <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" #closebutton2  data-dismiss="modal">Cerrar</button>
                                        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="updateLocation()">Guardar Cambios</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <button type="button" class="btn btn-danger" data-toggle="modal" data-target="#deletelocation" (click)="getLocationToDelete(location._id)"><i class="far fa-trash-alt"></i></button>

                                <!-- Modal -->
                                <div class="modal fade" id="deletelocation" tabindex="-1" role="dialog" aria-labelledby="deletelocationLabel" aria-hidden="true">
                                  <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h5 class="modal-title" id="deletelocationLabel">Eliminar Ubicaci&oacute;n</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div class="modal-body">
                                        <p>¿Estás seguro de eliminar este Ubicaci&oacute;n?</p>
                                      </div>
                                      <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" (click)="deleteLocation()" data-dismiss="modal">Eliminar</button>
                                        <button type="button" class="btn btn-primary" #closebutton3  data-dismiss="modal">Salir</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

<!-- </div> -->
<!-- /.container-fluid -->

<div class="modal fade" id="otherData" tabindex="-1" role="dialog" aria-labelledby="otherDataLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="otherDataLabel">Otros datos de la ubicación</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p *ngIf="contador >= 1">Cantidad de Propiedades: <span class="badge badge-success">{{contador}}</span></p>
        <p *ngIf="contador == null || contador == 0">Cantidad de Propiedades: <span class="badge badge-success">0</span></p>
      </div>
    </div>
  </div>
</div>