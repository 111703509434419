import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GLOBAL } from '../../../../../services/global';
import { Features } from '../models/features';

@Injectable()
export class FeaturesService {
  public url: string;
  public identity: any;
  public token: any;

  constructor(public _http: HttpClient) {
    this.url = GLOBAL.url;
  }

  getFeatures(token: any): Observable<any> {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', token);

    return this._http.get(this.url + 'features/', { headers: headers });
  }

  getFeature(addressType: any, token: any): Observable<any> {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', token);

    return this._http.get(this.url + 'feature/' + addressType, {
      headers: headers,
    });
  }

  saveFeature(addressType: any, token: any): Observable<any> {
    let params = JSON.stringify(addressType);
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', token);

    return this._http.post(this.url + 'feature', params, { headers: headers });
  }

  updateFeature(addressType: any, token: any): Observable<any> {
    let params = JSON.stringify(addressType);
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', token);

    return this._http.put(this.url + 'feature/' + addressType._id, params, {
      headers: headers,
    });
  }

  deleteFeature(addressType: any, token: any): Observable<any> {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', token);

    return this._http.delete(this.url + 'feature/' + addressType, {
      headers: headers,
    });
  }

  activeFeature(addressType: any, token: any): Observable<any> {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', token);
    console.log('activar');

    return this._http.post(this.url + 'Activate-feature', addressType, {
      headers: headers,
    });
  }
}
