import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { User } from '../../../users/models/user';
import { UserService } from '../../../users/services/user.service';
import { Property } from '../../models/property-view';
import { PropertyService } from  '../../services/property.service';
import { PropertyTypeService } from  '../../../catalogues/components/propertyTypes/services/propertyTypes.service';
import { PropertyType } from  '../../../catalogues/components/propertyTypes/models/propertyType';
import { Contact } from '../../../contacts/models/contact';
import { ContactService } from  '../../../contacts/services/contact.service';
import { Location } from '../../../catalogues/components/locations/models/location';
import { LocationService } from '../../../catalogues/components/locations/services/location.service'
import { Departament } from '../../../catalogues/components/depatament/models/departament';
import { DepartamentService } from '../../../catalogues/components/depatament/services/department.service'
import { Amenities } from '../../../catalogues/components/amenities/models/amenities';
import { AmenitieseService } from '../../../catalogues/components/amenities/services/amenities.service'
import { PropertyProject } from '../../../catalogues/components/projects/models/project';
import { PropertyProjectService } from '../../../catalogues/components/projects/services/project.service';
import { PropertyStatus } from '../../../catalogues/components/status/models/status';
import { PropertyStatusService } from '../../../catalogues/components/status/services/status.service';
import {Features} from '../../../catalogues/components/features/models/features';
import {FeaturesService} from '../../../catalogues/components/features/services/features.service';

import { GLOBAL } from '../../../../services/global';

declare let $: any;


import { PhotoProperty } from '../../models/propertyImages';
import { PhotoPropertyService } from  '../../services/propertyImages.service';

import { DocProperty } from '../../models/propertyDocs';
import { DocPropertyService } from  '../../services//propertyDocs.service';

import { CommentProperty } from '../../models/propertyComment';
import { CommentService } from  '../../services/propertyComment.service';

import { AmenityProperty } from '../../models/propertyAmenity';
import { PropertyAmenityService } from  '../../services/propertyAmenity.service';

import { PropertyModel } from '../../models/propertyModel';
import { ModelPropertyService } from '../../services/propertyModel.service';

import {FeatureProperty} from '../../models/propertyFeature';
import {PropertyFeaturesService} from '../../services/property-features.service';

import { SharedProperty } from '../../models/sharedProperties';
import { SendProperty } from '../../models/sendProperties';
import { SharedPropertiesService } from '../../services/shared-properties.service';
import { SendPropertiesService } from '../../services/send-properties.service';

import { Company } from '../../../catalogues/components/companies/models/company';
import { CompanyService } from  '../../../catalogues/components/companies/services/company.service'

@Component({
  selector: 'app-view-property',
  templateUrl: './view-property.component.html',
  styleUrls: ['./view-property.component.scss'],
  providers:[ UserService, 
    PropertyService, 
    PropertyTypeService, 
    ContactService, 
    LocationService,
    PhotoPropertyService,
    DocPropertyService,
    CommentService,
    PropertyAmenityService,
    AmenitieseService,
    PropertyProjectService,
    PropertyStatusService,
    DepartamentService,
    ModelPropertyService,
    PropertyFeaturesService,
    FeaturesService,
    SendPropertiesService, 
    SharedPropertiesService,
    CompanyService
  ]
})
export class ViewPropertyComponent implements OnInit {

  public title: string;
  public IDPropertry: String;
  public property: Property;

  public propertyTypes: PropertyType[];
    public contacts: Contact[];
    public locations: Location[];
    public departments: Departament[];
    public amenities: Amenities[];
    public features:Features[];
    public projects: PropertyProject[];
    public status: PropertyStatus[];
    public users: User[];
    public images: PhotoProperty[];
    public image: PhotoProperty;
    public imageToUpdate: PhotoProperty;
    public imageToDelete: PhotoProperty;
    public docs: DocProperty[];
    public doc: DocProperty;
    public docToUpdate: DocProperty;
    public docToDelete: DocProperty;
    public comments: CommentProperty[];
    public comment: CommentProperty;
    public commentToUpdate: CommentProperty;
    public commentToDelete: CommentProperty;
    public amentiyProperties: AmenityProperty[];
    public amentiyProperty: AmenityProperty;
    public amentiyPropertyToUpdate: AmenityProperty;
    public amentiyPropertyToDelete: AmenityProperty;
    public featureProperties: FeatureProperty[];
    public featureProperty: FeatureProperty;
    public featurePropertyToUpdate: FeatureProperty;
    public featurePropertyToDelete: FeatureProperty;
    public modelProperties: PropertyModel[];
    public modelProperty: PropertyModel;
    public modelPropertyToUpdate: PropertyModel;
    public modelPropertyToDelete: PropertyModel;
    public url: String;
    public identity;
    public token;
    public statusCreate: string;
    public statusEdit: string;
    public statusDelete: string;
    public statusGetProperties: string;
    public statusGetProperty: string;
    public statusGetContacts: string;
    public statusGetUsers: string;
    public statusGetImages: string;
    public statusGetDocs: string;
    public statusGetComments: string;
    public statusSaveImages: string;
    public statusEditImages: string;
    public statusDeleteImages: string;
    public statusGetImageToUpdate: string;
    public statusGetImageToDelete: string;
    public statusSaveDocs: string;
    public statusEditDocs: string;
    public statusDeleteDocs: string;
    public statusGetDocToUpdate: string;
    public statusGetDocToDelete: string;

    public statusSaveComment: string;
    public statusEditComment: string;
    public statusDeleteComment: string;
    public statusGetCommentToUpdate: string;
    public statusGetCommentToDelete: string;

    public statusSaveAmentiyProperty: string;
    public statusDeleteAmentiyProperty: string;
    public statusGetAmentiyPropertyToDelete: string;

    public statusSaveFeatureProperty: string;
    public statusDeleteFeatureProperty: string;
    public statusGetFeaturePropertyToDelete: string;

    public statusSaveModelProperty: string;
    public statusDeleteModelProperty: string;
    public statusGetModelPropertyToUpdate: String
    public statusGetModelPropertyToDelete: string;



    public hasContacts: Boolean;
    public hasPropertyOwner: Boolean;
    public haspropertyType: Boolean;
    public haspropertyLocation: Boolean;
    public haspropertyProject: Boolean;
    public haspropertyStatus: Boolean;

    public invite: SendProperty;
    public newProperty: SharedProperty;

    public statusEmail:String;
    public showEmail:Boolean;

    public newContact:Contact;
    public statusNewContact:String;
    public showNewContact:Boolean;
    public companies: Company[];
    public usersForContacts:User[];

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _userService: UserService,
        private _propertyService: PropertyService,
        private _propertyTypeService: PropertyTypeService,
        private _contactService: ContactService,
        private _locationService: LocationService,
        private _amenitiesService: AmenitieseService,
        private _imagesServices: PhotoPropertyService,
        private _docServices: DocPropertyService,
        private _commentService: CommentService,
        private _amenityPropertyService: PropertyAmenityService,
        private _projectPropertyService: PropertyProjectService,
        private _projectStatusService: PropertyStatusService,
        private _departamentStatusService: DepartamentService,
        private _modelService: ModelPropertyService,
        private _featuresPropertyService:PropertyFeaturesService,
        private _featuresService:FeaturesService,
        private _inviteService: SendPropertiesService,
        private _invitePropertyService: SharedPropertiesService,
        private _companyService: CompanyService
    ){
        this.title = 'Listado de Usuarios';
        this.url = GLOBAL.url;
        this.property = new Property("","","",0,"","","","","","","",0,0,0,0,0,0,0,0,false,"",0,0,false,"","","",0,0,0,0,"","","",0,0,0,0,0,0,0,"","","");
        this.image = new PhotoProperty("","","","","","",0,"");
        this.doc = new DocProperty("","","","","",0,"");
        this.comment = new CommentProperty("","","","","","");
        this.amentiyProperty = new AmenityProperty("","","","");
        this.featureProperty = new FeatureProperty("","","","");
        this.modelProperty = new PropertyModel("","","","","","",0,0,0,0,"",0,"");
        this.identity = this._userService.getIdentity();
        this.token = this._userService.getToken();

        this.invite=new SendProperty("",this.identity._id,"","","","","","","");
        this.newContact= new Contact("","","","","","","","","",false,"");
        

        this.hasContacts = false;
        this.hasPropertyOwner = false;
        this.haspropertyType = false;
        this.haspropertyLocation = false;
        this.haspropertyProject = false
        this.haspropertyStatus = false
        
    }

    public exampleData: any;
    public Cvalue: String;

    ngOnInit(){

        

        //Select2 demo data
      this.exampleData = [
        {
          id: '6014e244c1a6030fc922962c',
          text: 'Witlab'
        },
        {
          id: '6014e2c1c1a6030fc922962d',
          text: 'BUSISSOFT'
        },
        {
          id: '6014e35ec1a6030fc922962e',
          text: 'Nuevo Contacto'
        }
      ];

      //console.log('data de prueba', this.exampleData )

        this.ObternerId();
        this.getPropertyTypes();
        this.getContacts();
        this.getUsers();
        //this.getLocations();
        this.getDepartments();
        this.getAmenities();
        this.getProjects();
        this.getStatus();
        this.getFeatures();
        
        this.getImages();
        this.getDocs();
        this.getComments();
        this.getAmenityProperties();
        this.getModelProperties();
        this.getFeatureProperties();

        this.newProperty = new SharedProperty('', '', this.IDPropertry);
        this.getCompanies();
        this.getUsersForContacts();
        
        

    }

    getStateLabel(){
      if(this.departments && this.departments.length){
        // console.log(this.departments.length,this.property.propertyState);
      
        let res= this.departments.filter(d=>d._id== this.property.propertyState)
        let result= res.length>0?res[0].label:"";
        return result;
      }
      return "";
    }

    getCompanies(){
        this._companyService.getCompanies(this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetContacts = 'error';
                }else{
                    this.companies = response.data;
                    //console.log(this.companies);
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetContacts = 'error';
                }
            }
        );
    }

    getUsersForContacts(){
        this._userService.getUsers().subscribe(
            response => {
                if(!response.users){
                    this.statusGetContacts = 'error';
                }else{
                    this.usersForContacts = response.users;
                   // console.log(this.users);
                }
            },
            error => {
                var errorMessage = <any>error;
               // console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetContacts = 'error';
                }
            }
        );
    }
    


    ObternerId(){
        this._route.params.subscribe(params =>{
            let id = params['id'];
            this.IDPropertry = id;

            this.getProperty(id);
        })
    }


    getProperty(id:String){
        this._propertyService.getProperty(id, this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetProperty = 'error';
                }else{
            
                    this.property = response.data;
                    if(!this.property.iusiCurrency){this.property.iusiCurrency=1}
                    if(!this.property.maintenanceCurrency){this.property.maintenanceCurrency=1}
                    // console.log(this.property);

                    if(response.data.contact){
                        this.hasContacts = true;
                        this.Cvalue=response.data.contact._id;
                    }

                    if(response.data.propertyOwner){
                        this.hasPropertyOwner = true;
                    }

                    if(response.data.propertyType){
                        this.haspropertyType = true;
                    }

                    if(response.data.propertyLocation){
                        this.haspropertyLocation = true;
                    } 

                    if(response.data.propertyProject){
                        this.haspropertyProject = true;
                    } 

                    if(response.data.propertyStatus){
                        this.haspropertyStatus = true;
                    } 

                    if(response.data.propertyState){
                        this.getLocations(response.data.propertyState);
                    }
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
        
                if(errorMessage != null){
                    this.statusGetProperty = 'error';
                }
            }
        );

    }


    getPropertyTypes(){
        this._propertyTypeService.getPropertyTypes(this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetProperties = 'error';
                }else{
                    this.propertyTypes = response.data;
                    //console.log(this.propertyTypes);
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetProperties = 'error';
                }
            }
        );
    }

    getContacts(){
        this._contactService.getContactsToSelect(this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetContacts = 'error';
                }else{
                    this.contacts = response.data;
                    //this.exampleData= response.data
                    //(this.contacts);
                    //console.log('Contactos reales ', this.contacts )
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetContacts = 'error';
                }
            }
        );
    }

    public changed(e: any): void {
  
       //console.log("cambio", e)
       this.Cvalue = e;
    }    
   
    getUsers(){
        this._userService.getUserSaleman().subscribe(
            response => {
                if(!response.users){
                    this.statusGetUsers = 'error';
                }else{
                    this.users = response.users;
                    //console.log(this.users);
                }
            },
            error => {
                var errorMessage = <any>error;
               // console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetUsers = 'error';
                }
            }
        );
    }

    getLocations(departament:String){
        this._locationService.getLocationsByParent(departament, this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetContacts = 'error';
                }else{
                    this.locations = response.data;
                    //console.log(this.locations);
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetContacts = 'error';
                }
            }
        );
    }

    getDepartments(){
        this._departamentStatusService.getDepartaments(this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetContacts = 'error';
                }else{
                    this.departments = response.data;
                    //console.log('depatamentos', this.departments);
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetContacts = 'error';
                }
            }
        );
    }

    public changedDepartament(e: any): void {
        this.getLocations(this.property.propertyState);
     }    



    getAmenities(){
        this._amenitiesService.getAmenities(this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetContacts = 'error';
                }else{
                    this.amenities = response.data;
                    //console.log(this.amenities);
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetContacts = 'error';
                }
            }
        );
    }

    getFeatures(){
        this._featuresService.getFeatures(this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetContacts = 'error';
                }else{
                    this.features = response.data;
                   // console.log(this.features);
                }
            },
            error => {
                var errorMessage = <any>error;
                console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetContacts = 'error';
                }
            }
        );
    }

    getProjects(){
        this._projectPropertyService.getPropertyProjects(this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetContacts = 'error';
                }else{
                    this.projects = response.data;
                    //console.log('Projects:', this.projects);
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetContacts = 'error';
                }
            }
        );
    }

    getStatus(){
        this._projectStatusService.getPropertyAllStatus(this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetContacts = 'error';
                }else{
                    this.status = response.data;
                    //console.log('Status:', this.status);
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetContacts = 'error';
                }
            }
        );
    }
    
    onSubmit() {
        // this.closebutton.nativeElement.click();
        //console.log(this.property);
        //this.property.contact = this.Cvalue;
        this._propertyService.updateProperty(this.property, this.token).subscribe(
            response => {
                //console.log(response);
                if(!response.data){
                    this.statusCreate = 'error';
                    this.showAlertEditF = true;
                   // console.log('Error al guardar el token');
                }else{
                  this.statusCreate = 'success';
                  this.showAlertEdit = true;
                  //console.log("response");
                  //console.log(response);
                  this.ObternerId();
                }

                //SUBIDA DE IMAGEN
                if(this.filesToUpload && this.filesToUpload.length)
                {
                   // console.log(response.data._id);
                    this._propertyService.makeFileRequest(this.url+'upload-image-porperty/'+response.data._id,[], this.filesToUpload, this.token, 'img')
                                    .then((result: any) =>{
                                        //console.log(result);
                                            // this.property.propertyFeaturedImage = result.property.propertyFeaturedImage;
                                            this._propertyService.makeFileRequest(this.url+'upload-thumbnail-porperty/'+response.data._id,[], this.filesToUpload, this.token, 'img')
                                                    .then((result: any) =>{
                                                        this.ObternerId();
                                                        this.statusCreate = 'success';
                                                    });
                                            
                                            //localStorage.setItem('identity', JSON.stringify(this.user));
                                    });
                }else{
                    //localStorage.setItem('identity', JSON.stringify(this.user));
                    //this._router.navigate(['/home']);
                }

                //SUBIDA DE ARCHIVO
                if(this.filesToUpload2 && this.filesToUpload2.length)
                {
                    //console.log(response.data._id);
                    this._propertyService.makeFileRequest2(this.url+'upload-property-doc/'+response.data._id,[], this.filesToUpload2, this.token, 'img')
                                    .then((result: any) =>{
                                       // console.log(result);
                                            // this.property.propertyFeaturedImage = result.property.propertyFeaturedImage;
                                            this.ObternerId();
                                            this.statusCreate = 'success';
                                            //localStorage.setItem('identity', JSON.stringify(this.user));
                                    });
                }else{
                    //localStorage.setItem('identity', JSON.stringify(this.user));
                    //this._router.navigate(['/home']);
                }
  
            },error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
  
                if(errorMessage != null){
                    this.statusCreate = 'error';
                }
            }
        );
    }

    public filesToUpload: Array<File>;
    fileChangeEvent(fileInput: any){
        this.filesToUpload = <Array<File>>fileInput.target.files;
        //console.log(this.filesToUpload)
    }

    public filesToUpload2: Array<File>;
    fileChangeEvent2(fileInput: any){
        this.filesToUpload2 = <Array<File>>fileInput.target.files;
        //console.log(this.filesToUpload2)
    }


    //=========================== IMAGES ===================
    getImages(){
        this._imagesServices.getPhotoProperties(this.IDPropertry ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetImages = 'error';
                }else{
                    this.images = response.data;
                    //console.log(this.images);
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetImages = 'error';
                }
            }
        );
    }



    //=========================== DOCUMENTOS ===================
    getDocs(){
        this._docServices.getDocProperties(this.IDPropertry ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetDocs = 'error';
                }else{
                    this.docs = response.data;
                    //console.log(this.docs);
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetDocs = 'error';
                }
            }
        );
    }

    //=========================== Comments ===================
    getComments(){
        this._commentService.getCommentbyProperty(this.IDPropertry ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetComments = 'error';
                }else{
                    this.comments = response.data;
                   // console.log(this.comments);
                }
            },
            error => {
                var errorMessage = <any>error;
               // console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetComments = 'error';
                }
            }
        );
    }


    //=========================== Amenity Property  ===================
    getAmenityProperties(){
        this._amenityPropertyService.getPropretyAmenitybyProperty(this.IDPropertry ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetProperties = 'error';
                }else{
                    let tempData = response.data;

                    this.amentiyProperties = tempData.sort( function( a, b ) {
                        return a.propertyAmenity.name < b.propertyAmenity.name ? -1 : a.propertyAmenity.name > b.propertyAmenity.name ? 1 : 0;
                    });
                    
                    
                     
                    
                   // console.log('Amenidades: ', tempData);
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetProperties = 'error';
                }
            }
        );
    }


    //=========================== Features Property ===================

    getFeatureProperties(){
        this._featuresPropertyService.getPropretyFeaturebyProperty(this.IDPropertry ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetProperties = 'error';
                }else{
                    let tempData = response.data;
                   // console.log(1313,'Caracteristicas: ', tempData);
                    this.featureProperties = tempData.sort( function( a, b ) {
                        return a.propertyFeature.name < b.propertyFeature.name ? -1 : a.propertyFeature.name > b.propertyFeature.name ? 1 : 0;
                    });
                    
                    
                     
                    
                    //console.log('Caracteristicas: ', tempData);
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetProperties = 'error';
                }
            }
        );
    }


    //=========================== Model Property  ===================
    getModelProperties(){
        this._modelService.getModelProperties(this.IDPropertry ,this.token).subscribe(
            response => {
                if(!response.data){
                    this.statusGetProperties = 'error';
                }else{
                    this.modelProperties = response.data;        
                   // console.log('Modelos: ', this.modelProperties);
                }
            },
            error => {
                var errorMessage = <any>error;
                //console.log(errorMessage);
    
                if(errorMessage != null){
                    this.statusGetProperties = 'error';
                }
            }
        );
    }
    


    SendEmail(sendEmail: any) {
        // this.closebutton15.nativeElement.click();
    
        this._inviteService.saveInvite(this.token, this.invite).subscribe(
          (response) => {
            if (!response.data) {
              // sendEmail.reset();
              this.statusEmail = 'error';
              this.showEmail = true;
              this.invite.receiver="";
              this.invite.subject="";
              this.invite.message="";
            } else {
              this.statusEmail = 'success';
              this.showEmail = true;
              let idInvite = response.data._id;
              this.newProperty.emailID=idInvite;
              // sendEmail.reset();
              // this.getInvites(true);
              // this._router.navigate(['/shared-properties/' + response.data._id]);
              this._invitePropertyService
                .saveInviteProp(this.token, this.newProperty)
                .subscribe(
                  (response) => {
                    if (!response.data) {
                      // invitationForm.reset();
                      this.statusEmail = 'error';
                      this.showEmail = true;
                    } else {
                      this.statusEmail = 'success';
                      this.showEmail = true;
                      // invitationForm.reset();
    
                      this._inviteService
                        .sendEmail(this.token, { obsjectId: idInvite })
                        .subscribe(
                          (response) => {
                            if (!response.data) {
                              this.statusEmail = 'error';
                              this.showEmail = true;
                            } else {
                              this.statusEmail = 'success';
                              this.showEmail = true;
                            }
                          },
                          (error) => {
                            var errorMessage = <any>error;
                            //console.log(errorMessage);
    
                            if (errorMessage != null) {
                              this.statusEmail = 'error';
                              this.showEmail = true;
    
                              // console.log(357,errorMessage);
                            }
                          }
                        );
                    }
                    this.invite.receiver="";
                    this.invite.subject="";
                    this.invite.message="";
                  },
                  (error) => {
                    var errorMessage = <any>error;
    
                    if (errorMessage != null) {
                      this.statusEmail = 'error';
                      this.showEmail = true;
                    }
                  }
                );
            }
          },
          (error) => {
            var errorMessage = <any>error;
    
            if (errorMessage != null) {
              this.statusEmail = 'error';
              this.showEmail = true;
            }
          }
        );
    
        // console.log(this.identity);
        // console.log("Invite",this.invite);
        // console.log("property",this.newProperty);
        // sendEmail.reset();
      }

    public showAlertEdit: Boolean
    closeEdit(){
        this.showAlertEdit = false;
    }

    public showAlertEditF: Boolean
    closeDelete(){
        this.showAlertEditF = false;
    }

    closeEmail(){
        this.showEmail=false;
    }

    closeContact(){
        this.showNewContact=false;
    }


}
