import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GLOBAL } from '../../../services/global';
import {PropertyToSearch} from '../models/propertyToSearch';

@Injectable({
  providedIn: 'root',
})
export class PropertySearchService {
  public url: string;
  public identity: any;
  public token: any;

  constructor(public _http: HttpClient) {
    this.url = GLOBAL.url;
  }

  getPropretiesTypes(token: any): Observable<any> {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', token);

    return this._http.get(this.url + 'catalogue/propertyTypesList/', {
      headers: headers,
    });
  }

  getPropretiesLocations(token: any): Observable<any> {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', token);

    return this._http.get(this.url + 'catalogue/propertyLocationsList/', {
      headers: headers,
    });
  }
  getPropretiesStates(token: any): Observable<any> {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', token);

    return this._http.get(this.url + 'catalogue/propertyDepartmentsList/', {
      headers: headers,
    });
  }

  getSearchProperties(object: PropertyToSearch, token: any): Observable<any> {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', token);
    let toSearch = '';

    toSearch +=
      object.propertyOperation != 0
        ? '?action=' + object.propertyOperation
        : '';
    toSearch +=
      toSearch === '' && object.propertyType !== ''
        ? '?type=' + object.propertyType
        : '';
    toSearch +=
      toSearch === '' && object.propertyLocation !== ''
        ? '?location=' + object.propertyLocation
        : '';

    toSearch +=
      toSearch === '' && object.propertyState !== ''
        ? '?state=' + object.propertyState
        : '';
    
    toSearch +=
    toSearch === '' && object.maxPrice !== 0
      ? '?maxPrice=' + object.maxPrice
      : '';

    toSearch +=
    toSearch === '' && object.minPrice !== 0
      ? '?minPrice=' + object.minPrice
      : '';

      toSearch +=
      toSearch === '' && object.stage !== 0
        ? '?stage=' + object.stage
        : '';

    toSearch +=
    toSearch === '' && object.dorms !== 0
      ? '?dorms=' + object.dorms
      : '';

    toSearch +=
      toSearch !== '' &&
      object.propertyType !== '' &&
      !toSearch.includes('type=')
        ? '&type=' + object.propertyType
        : '';
    toSearch +=
      toSearch !== '' &&
      object.propertyLocation !== '' &&
      !toSearch.includes('location=')
        ? '&location=' + object.propertyLocation
        : '';

    toSearch +=
      toSearch !== '' &&
      object.propertyState !== '' &&
      !toSearch.includes('state=')
        ? '&state=' + object.propertyState
        : '';

    toSearch +=
    toSearch !== '' &&
    object.maxPrice !== 0 &&
    !toSearch.includes('maxPrice=')
      ? '&maxPrice=' + object.maxPrice
      : '';

    
    toSearch +=
    toSearch !== '' &&
    object.minPrice !== 0 &&
    !toSearch.includes('minPrice=')
      ? '&minPrice=' + object.minPrice
      : '';

    
    toSearch +=
    toSearch !== '' &&
    object.stage !== 0 &&
    !toSearch.includes('stage=')
      ? '&stage=' + object.stage
      : '';

    
    toSearch +=
    toSearch !== '' &&
    object.dorms !== 0 &&
    !toSearch.includes('dorms=')
      ? '&dorms=' + object.dorms
      : '';

    // console.log(toSearch);

    return this._http.get(
      this.url + 'propertySearch/' + toSearch,
      {
        headers: headers,
      }
    );
  }
}
