 <!-- Begin Page Content -->
 <!-- <div class="container-fluid"> -->
    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 class="h3 mb-0 text-gray-800">Ver Propiedad</h1>
    </div>

    <div class="row">

        <div class="col-lg-8">

            <div class="card shadow mb-4">
                <div class="card-header py-3">
                    <div class="row">

                        <div class="col-md-12">
                            <h6 class="m-0 font-weight-bold text-primary">Datos Generales de la Propiedad</h6>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <h5>M&oacute;dulo:</h5>
                    <p>{{property.propertyModule==1?"Propiedad":"Proyecto Nuevo"}}</p>

                    <hr>

                    <h5>Propietario:</h5>
                    <p>{{property.contact && property.contact.name?property.contact.name:""}}</p>

                    <hr>

                    <h5>Condominio / Edificio / Proyecto:</h5>
                    <p>{{property.propertyProject && property.propertyProject.label?property.propertyProject.label:""}}</p>

                    <hr>

                    <h5>Imagen Destacada:</h5>
                    <p *ngIf="!property.propertyFeaturedImage">Propiedad</p>
                    <img *ngIf="property.propertyFeaturedImage" width="240" src="{{url+ 'get-image-property/'+property.propertyFeaturedImage }}" />

                    <hr>
                    <hr _ngcontent-ybu-c67="">
                    <h5>Código</h5>
                    <p>{{property.code?property.code:""}}</p>

                    <h5>Título:</h5>
                    <p>{{property.propertyTitle}}</p>

                    <hr>

                    <h5>Descripción:</h5>
                    <div [innerHTML]="property.propertyDesc"></div>

                    <hr>

                    <h5>Descripción Buscador:</h5>
                    <p>{{property.propertyTagline}}</p>

                    <hr>

                    <h5>Vídeo de YouTube:</h5>
                    <p *ngIf="property.propertyYoutube"><a href="{{property.propertyYoutube}}" target="_blank">{{property.propertyYoutube}}</a></p>

                    <hr>

                    <h5>Presentación:</h5>
                    <a *ngIf="property.propertyPresentation" href="{{url+ 'get-property-doc/'+property.propertyPresentation }}" class="download-button">Descargar Presentaci&oacute;n</a>

                </div>
            </div>

            <div class="card shadow mb-4">
              <div class="card-header py-3">
                  <div class="row">
                      <div class="col-md-12">
                          <h6 class="m-0 font-weight-bold text-primary">&Aacute;reas Especiales</h6>
                      </div>
                  </div>
              </div>
              <div class="card-body">
                <h5>Dormitorios:</h5>
                <p>{{property.propertyBedrooms}}</p>

                <hr>

                <h5>Baños:</h5>
                <p>{{property.propertyBathrooms}}</p>

                <hr>


                <h5>Área Terreno:</h5>
                <p *ngIf="property.propertyArea">{{property.propertyArea + " " + (property.propertyAreaType==1?"m":property.propertyAreaType==2?"vrs":"Manzanas")}}<sup>2</sup></p>

                <hr>


                <h5>Área Construcción:</h5>
                <p *ngIf="property.propertyConstructionArea">{{property.propertyConstructionArea + " " + (property.propertyConstructionAreaType==1?"m":property.propertyConstructionAreaType==2?"vrs":"Manzanas")}}<sup>2</sup></p>


                <hr>


                <h5>Niveles:</h5>
                <p>{{property.levels}}</p>

                <hr>

                <h5>Parqueos:</h5>
                <p>{{property.parkinslots}}</p>

              </div>
            </div>

            <div class="card shadow mb-4">
                <div class="card-header py-3">
                    <div class="row">
                        <div class="col-md-12">
                            <h6 class="m-0 font-weight-bold text-primary">Datos Econ&oacute;micos de la Propiedad</h6>
                        </div>
                    </div>
                </div>
                <div class="card-body">

                    <h5>Precio de Renta:</h5>
                    <p *ngIf="property.propertyRentPrice">$ {{property.propertyRentPrice}}</p>

                    <hr>

                    <h5>Precio de Venta:</h5>
                    <p *ngIf="property.propertySalePrice">$ {{property.propertySalePrice}}</p>

                    <hr>

                    <h5>Precio Mínimo:</h5>
                    <p *ngIf="property.propertyMinPrice">$ {{property.propertyMinPrice}}</p>

                    <hr>

                    <h5>Precio Máximo:</h5>
                    <p *ngIf="property.propertyMaxPrice">$ {{property.propertyMaxPrice}}</p>

                    <hr>


                    <h5>IUSI:</h5>
                    <p *ngIf="property.propertyIusi">{{property.iusiCurrency==1?"Quetzales":"Dólares"}} - {{property.propertyIusi}} - {{property.propertyIusiType==1?"Trimestral":"Anual"}}</p>

                    <hr>

                    <h5>Mantenimiento:</h5>
                    <p *ngIf="property.propertyMaintenance">{{property.maintenanceCurrency==1?"Quetzales":"Dólares"}} - {{property.propertyMaintenance}}</p>

                    <hr>

                    <h5>Comisión:</h5>
                    <p *ngIf="property.propertyComision">{{property.propertyComision}} % </p>

                    <hr>

                    <h5>Incluye Impuestos:</h5>
                    <p>{{property.propertyTax?"Si":"No"}}</p>

                </div>
            </div>

            <div class="card shadow mb-4">
                <div class="card-header py-3">
                    <div class="row">
                        <div class="col-md-10">
                            <h6 class="m-0 font-weight-bold text-primary">Galer&iacute;a de Im&aacute;genes</h6>
                            <p>Todas las im&aacute;genes de la propiedad.</p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>Imagen</th>
                                    <th>T&iacute;tulo</th>
                                    <th>Fecha</th>
                                    <th>Visibilidad</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let image of images">
                                    <td *ngIf="image.imgThumbnail"><img width="100" src="{{ url +'get-thumbnail-photo/' + image.imgThumbnail }}"></td>
                                    <td *ngIf="image.imageSrc && !image.imgThumbnail"><img width="100" src="{{ url +'get-image-photo/' + image.imageSrc }}"></td>
                                    <td *ngIf="!image.imageSrc && !image.imgThumbnail"><img width="100" src="https://olgadetorrebiarte.com/wp-content/uploads/2019/04/2-1-835x467.jpg"></td>
                                    <td>{{ image.imageName }}</td>
                                    <td>{{ (image.imageDate | amFromUnix) | amLocale:'es' | amDateFormat:'DD/MM/YYYY'}}</td>
                                    <!-- <td>18/02/2021</td> -->
                                    <td *ngIf="image.imageVisibility ==1"><span class="badge badge-success">Público</span></td>
                                    <td *ngIf="image.imageVisibility ==2"><span class="badge badge-secondary">Privado</span></td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="card shadow mb-4">
                <div class="card-header py-3">
                    <div class="row">
                        <div class="col-md-10">
                            <h6 class="m-0 font-weight-bold text-primary">Manejo de Archivos</h6>
                            <p>Todos los archivos de la propiedad.</p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>Archivo</th>
                                    <th>T&iacute;tulo</th>
                                    <th>Fecha</th>
                                    <th>Visibilidad</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let doc of docs">
                                    <td><a href="{{url+ 'get-doc/'+doc.fileSrc }}" target="_blank">Ver Archivo</a></td>
                                    <td>{{ doc.fileName }}</td>
                                    <td>{{ (doc.fileDate | amFromUnix) | amLocale:'es' | amDateFormat:'DD/MM/YYYY'}}</td>
                                    <td *ngIf="doc.fileVisibility ==1"><span class="badge badge-success">Público</span></td>
                                    <td *ngIf="doc.fileVisibility ==2"><span class="badge badge-secondary">Privado</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="card shadow mb-4" *ngIf="property.propertyModule==2">
                <div class="card-header py-3">
                    <div class="row">
                        <div class="col-md-10">
                            <h6 class="m-0 font-weight-bold text-primary">Modelos del Proyecto</h6>
                            <p>Todos los modelos del proyecto.</p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>Planta</th>
                                    <th>T&iacute;tulo</th>
                                    <th>Fecha</th>
                                    <th>Visibilidad</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let modelProperty of modelProperties">
                                    <td *ngIf="modelProperty.imgThumbnail"><img width="100" src="{{ url +'get-thumbnail-model/' + modelProperty.imgThumbnail }}"></td>
                                    <td *ngIf="modelProperty.modelSrc && !modelProperty.imgThumbnail"><img width="100" src="{{ url +'get-image-model/' + modelProperty.modelSrc }}"></td>
                                    <td *ngIf="!modelProperty.modelSrc && !modelProperty.imgThumbnail"><img width="100" src="https://olgadetorrebiarte.com/wp-content/uploads/2019/04/2-1-835x467.jpg"></td>
                                    <td>
                                        {{modelProperty.modelName}}<br/>
                                        <span style="font-size:11px;">
                                          {{modelProperty.modelDesc}}.<br/>
                                            <b>Precio</b>: ${{modelProperty.modelPrice}}<br/>
                                            <b>Espacios</b>: {{modelProperty.modelDorms}}<br/>
                                            <b>Parqueos</b>: {{modelProperty.modelParkingSlots}}
                                        </span>
                                    </td>
                                    <td>{{ (modelProperty.modelDate | amFromUnix) | amLocale:'es' | amDateFormat:'DD/MM/YYYY'}}</td>
                                    <td *ngIf="modelProperty.modelVisibility ==1"><span class="badge badge-success">Público</span></td>
                                    <td *ngIf="modelProperty.modelVisibility ==2"><span class="badge badge-secondary">Privado</span></td>
                                    
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="card shadow mb-4">
                <div class="card-header py-3">
                    <div class="row">
                        <div class="col-md-10">
                            <h6 class="m-0 font-weight-bold text-primary">Comentarios de la Propiedad</h6>
                            <p>Todos los comentarios de la propiedad.</p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>Comentario</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let comment of comments">
                                    <td>
                                        <span class="badge badge-success">{{ comment.commentAuthor.name}} {{ comment.commentAuthor.surname}}</span>
                                        <span style="font-size:12px;">
                                            <b>Fecha</b>: {{ (comment.commentDate | amFromUnix) | amLocale:'es' | amDateFormat:'DD/MM/YYYY'}}<br/>
                                                {{ comment.commentDesc}}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>

        <div class="col-lg-4">

            <div class="card shadow mb-4">
                <div
                    class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 class="m-0 font-weight-bold text-primary">Estatus y Visibilidad</h6>
                </div>
                <div class="card-body">
                    <b>Creada: </b> {{ (property.createdAt | amFromUnix) | amLocale:'es-mx' | amDateFormat:'LL'}}<br/>
                    <hr>
                    <b>Actualizada: </b>{{ (property.updatedAt | amFromUnix) | amLocale:'es-mx' | amDateFormat:'LL'}}<br/>
                    <hr>
                    <b>Ingresado por Agente: </b> {{property.propertyOwner?(property.propertyOwner.name + " "+property.propertyOwner.surname):""}}<br/>
                    <hr>
                    <b>Tipo de Operación: </b> {{property.propertyOperation==1?"Venta":property.propertyOperation==2?"Renta":"Venta y renta"}}<br/>
                    <hr>
                    <b>Estatus: </b> {{property.propertyStatus && property.propertyStatus.label?property.propertyStatus.label:""}}<br/>
                    <hr>
                    <b>Visibilidad: </b> {{property.propertyVisibility==1?"Pública":"Privada"}}<br/>
                    <hr>
                    <b>Tengo la Propiedad En: </b> {{property.typeofownership==1?"Propiedades en Directo":"Propiedades Compartidas"}}<br/>
                    <hr>
                    <b>Propiedad Destacada: </b> {{property.propertyFeatured?"Si":"No"}}<br/>
                </div>
            </div>

            <div class="card shadow mb-4">
              <div
                  class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 class="m-0 font-weight-bold text-primary">Enlaces Para Compartir</h6>
              </div>
              <div class="card-body">
                  <a class="btn btn-sm btn-social btn-fb" href="https://www.facebook.com/sharer/sharer.php?u=https://olgadetorrebiarte.com/propiedad/{{property.propertyType.name+'/'+property.propertySlug}}" target="_blank" title="Comparte esta propiedad en Facebook">
                      <i class="fab fa-facebook-square"></i> Share
                  </a>
                  <a href="target_blank" title="Comparte esta propiedad en Linkedin" class="btn btn-sm btn-social btn-tw" href="http://www.linkedin.com/shareArticle?mini=true&url=https://olgadetorrebiarte.com/propiedad/{{property.propertyType.name+'/'+property.propertySlug}}&title=Comparte esta propiedad en Linkedin&summary={{property.propertyTagline}}&source=olgadetorrebiarte.com"><i _ngcontent-wfx-c67="" class="fab fa-linkedin"></i> Linkedin</a>
                  <!-- <a class="btn btn-sm btn-social btn-tw" href="https://twitter.com/intent/tweet?text={{property.propertyTitle}}&amp;url=https://olgadetorrebiarte.com/propiedad/{{property.propertyType.name+'/'+property.propertySlug}}" target="_blank" title="Comparte esta propiedad en Twitter">
                      <i class="fab fa-twitter"></i> Tweet
                  </a> -->
                  <a class="btn btn-sm btn-social btn-wa" href="https://api.whatsapp.com/send?text=Hola%2C%20creo%20que%20podr%C3%ADa%20interesarte%20esta%20propiedad%3A%20{{property.propertyTitle}}%20https%3A%2F%2Folgadetorrebiarte.com%2Fpropiedad%2F{{property.propertyType.name}}%2F{{property.propertySlug}}" target="_blank" title="Comparte esta propiedad en WhatsApp">
                      <i class="fab fa-whatsapp"></i> WhatsApp
                  </a>
                  <a class="btn btn-sm btn-social btn-email" href="#createEmail" data-toggle="modal" data-target="#createEmail">
                    <i class="fas fa-at"></i> Enviar por Correo
                </a>
              </div>
          </div>

            <div class="card shadow mb-4">
                <div
                    class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 class="m-0 font-weight-bold text-primary">Tipo de Propiedad</h6>
                </div>
                <div class="card-body">
                  <b>{{property.propertyType && property.propertyType.label? property.propertyType.label:""}}</b>
                </div>
            </div>

            <div class="card shadow mb-4">
              <div
                  class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 class="m-0 font-weight-bold text-primary">Amenidades</h6>
              </div>
              <div class="card-body">
                  
                  <div id="addedAmenities">
                      <p *ngFor="let amentiyProperty of amentiyProperties" class="addedAmenity">{{amentiyProperty.propertyAmenity.label}}</p>
                  </div>
              </div>
          </div>

          <div class="card shadow mb-4">
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary">Caracter&iacute;sticas</h6>
            </div>
            <div class="card-body">
                <div id="addedFeatures">
                    <p *ngFor="let featureProperty of featureProperties" class="addedFeature">{{featureProperty.propertyFeature.label}}</p>
                </div>
            </div>
        </div>

            <div class="card shadow mb-4">
                <div
                    class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 class="m-0 font-weight-bold text-primary">Ubicaci&oacute;n</h6>
                </div>
                <div class="card-body">

                    <b>Departamento: </b> {{getStateLabel()}} <br/>
                    <hr>
                    <b>Ubicación: </b>{{property.propertyLocation && property.propertyLocation.label?property.propertyLocation.label:""}} <br/>

                  
                </div>
            </div>

        </div>

    </div>


      <!-- ENVIAR CORREO -->
      <div class="modal fade" id="createEmail" tabindex="-1" role="dialog" aria-labelledby="createEmailLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="createEmailLabel">Crear Envío</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form #sendEmail="ngForm" #sendEmail="ngForm" (ngSubmit)="SendEmail(sendEmail)">
                  <div *ngIf="statusEmail=='success' && showEmail" class="alert alert-success" role="alert">El envío se ha realizado exitosamente.<button type="button" class="close" (click)="closeEmail()" aria-label="Close"><span aria-hidden="true">&times;</span></button> </div>
  
                  <div *ngIf="statusEmail=='error' && showEmail" class="alert alert-danger" role="alert">El envío no se ha podido realizar, intenta nuevamente<button type="button" class="close" (click)="closeEmail()" aria-label="Close"><span aria-hidden="true">&times;</span></button> </div>
  
                <div class="form-group">
                  <label for="emailReceiver">Email del Receptor:</label>
                  <input type="email" class="form-control" name="receiver" #receiver="ngModel"
                  [(ngModel)]="invite.receiver"  id="emailReceiver">
                </div>
  
                <div class="form-group">
                  <label for="emailSubject">Asunto:</label>
                  <input type="email" class="form-control"  name="subject" #subject="ngModel"
                  [(ngModel)]="invite.subject"  id="emailSubject">
                </div>
  
                <div class="form-group">
                  <label for="emailMessage">Mensaje:</label>
                  <textarea rows="4"  class="form-control"  name="message" #message="ngModel"
                  [(ngModel)]="invite.message" id="emailMessage"></textarea>
                </div>
  
                <div class="modal-footer">
                  <button type="button" #closebutton15 class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                  <button type="submit" class="btn btn-primary">Enviar</button>
                </div>
              </form>
            </div>
            
          </div>
        </div>
      </div>
      <!-- ENVIAR CORREO -->


<!-- </div> -->
