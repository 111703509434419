import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/users/components/login/login.component';
import { ForgotPassword} from './components/users/components/forgotPassword/step-1/forgotPassword.component';
import { ForgotPasswordChange } from './components/users/components/forgotPassword/step-2/forgotPasswordChange.component';
import { RegisterComponent } from './components/users/components/register/components/register.component';
import { UserListComponent } from './components/users/components/usersList/userList.component';
import {  ContactComponent } from './components/contacts/components/contact.component';
import {  PropertyTypeComponent } from './components/catalogues/components/propertyTypes/components/propertyType.component';
import {  PropertyProjectComponent } from './components/catalogues/components/projects/components/project.component';
import {  PropertyStatusComponent } from './components/catalogues/components/status/components/status.component';
import {  LocationComponent } from './components/catalogues/components/locations/components/location.component';
import { AmenityTypeComponent  } from './components/catalogues/components/amenities/components/amenities.component';
import { PropertyComponent  } from './components/propieties/components/property.component';
import { PropertyInternalComponent  } from './components/propieties/components/propertyInternal/components/propertyInternal.component';
import {  CompanyComponent } from './components/catalogues/components/companies/components/company.component';
import {  DepartamentComponent } from './components/catalogues/components/depatament/components/departament.component';
import {  FeaturesComponent } from './components/catalogues/components/features/components/features.component';
import { PropertiesArchiveComponent  } from './components/propieties/components/propertiesArchive/components/properties-archive.component';
import {SendPropertiesComponent} from './components/propieties/components/sendProperties/components/send-properties.component';
import {SharedPropertiesComponent} from './components/propieties/components/sendProperties/components/sharedProperties/components/shared-properties.component';
import {ViewPropertyComponent} from './components/propieties/components/view-property/view-property.component';

import { SecurityGuard } from './services/security.guard';

const appRoutes: Routes = [
  {path: '', component: DashboardComponent, canActivate:[SecurityGuard]},
  {path: 'dashboard', component: DashboardComponent, canActivate:[SecurityGuard]},
  {path: 'login', component: LoginComponent},
  {path: 'forgot-password', component: ForgotPassword},
  {path: 'forgot-password-step-2', component: ForgotPasswordChange},
  {path: 'register', component: RegisterComponent},

  {path: 'user/list', component: UserListComponent, canActivate:[SecurityGuard]},
  {path: 'contacts', component: ContactComponent, canActivate:[SecurityGuard]},
  {path: 'property-types', component: PropertyTypeComponent, canActivate:[SecurityGuard]},
  {path: 'property-projects', component: PropertyProjectComponent, canActivate:[SecurityGuard]},
  {path: 'property-status', component: PropertyStatusComponent, canActivate:[SecurityGuard]},
  {path: 'locations', component: LocationComponent, canActivate:[SecurityGuard]},
  {path: 'amenities', component: AmenityTypeComponent, canActivate:[SecurityGuard]},
  {path: 'features', component: FeaturesComponent, canActivate:[SecurityGuard]},
  {path: 'properties', component: PropertyComponent, canActivate:[SecurityGuard]},
  {path: 'archive-properties', component: PropertiesArchiveComponent, canActivate:[SecurityGuard]},
  {path: 'send-properties', component: SendPropertiesComponent, canActivate:[SecurityGuard]},
  {path: 'shared-properties/:id', component: SharedPropertiesComponent, canActivate:[SecurityGuard]},
  {path: 'property/:id', component: PropertyInternalComponent, canActivate:[SecurityGuard]},
  {path: 'companies', component: CompanyComponent, canActivate:[SecurityGuard]},
  {path: 'departments', component: DepartamentComponent, canActivate:[SecurityGuard]},
  {path: 'view-property/:id', component: ViewPropertyComponent, canActivate:[SecurityGuard]},



  {path: '**', component: DashboardComponent, canActivate:[SecurityGuard]}
  
];

export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders<any> = RouterModule.forRoot(appRoutes);
