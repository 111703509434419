import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { User } from '../../../../../../users/models/user';
import { UserService } from '../../../../../../users/services/user.service';

import { GLOBAL } from '../../../../../../../services/global';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

import { SharedProperty } from '../../../../../models/sharedProperties';
import { SendProperty } from '../../../../../models/sendProperties';
import { SharedPropertiesService } from '../../../../../services/shared-properties.service';
import { SendPropertiesService } from '../../../../../services/send-properties.service';

import {Property} from '../../../../../models/property';
import {PropertyService} from '../../../../../services/property.service';

@Component({
  selector: 'app-shared-properties',
  templateUrl: './shared-properties.component.html',
  styleUrls: ['./shared-properties.component.scss'],
  providers: [UserService, SendPropertiesService, SharedPropertiesService,PropertyService],
})
export class SharedPropertiesComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton3') closebutton3;

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  //   dtOptions: DataTables.Settings = {};
  isDtInitialized: boolean = false;
  dtTrigger: Subject<any> = new Subject();

  public statusEdit: string;
  public statusDelete: string;
  public statusCreate: string;
  public statusGetUsers: string;
  public showAlertEdit: Boolean;
  public showAlertDelete: Boolean;
  public showAlertCreate: Boolean;

  public url: String;

  public data: SharedProperty[];
  public users: User[];
  public propiedades:Property[];

  public newProperty: SharedProperty;
  public propertyToUpdate: SharedProperty;
  public propertyToDelete: SharedProperty;

  public idInvite: String;

  public invite: SendProperty;
  public token;

  constructor(
    private _userService: UserService,
    private _inviteService: SendPropertiesService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _invitePropertyService: SharedPropertiesService,
    private _propertyService:PropertyService
  ) {
    this.url = GLOBAL.url;
    this.newProperty = new SharedProperty('', '', '');
    this.propertyToDelete = new SharedProperty('', '', '');
    this.invite=new SendProperty("","","","","","","","","");
    this.token = this._userService.getToken();
  }

  ngOnInit(): void {
    this.getInvite();
    console.log(this.invite,74);
    
    this.getProperties(false);
    this.getAgents();
    this.getAllProperties();
    this.newProperty.emailID = this.idInvite;
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  getProperties(updated: boolean) {
    this._invitePropertyService.getInviteProps(this.token, this.idInvite).subscribe(
      (response) => {
        if (!response.data) {
          this.statusGetUsers = 'error';
        } else {
          this.data = response.data;
          console.log(this.data,107);
          if (updated) {
            // this.rerender();
            // this.ngAfterViewInit();

            var table = $('#datatableexample').DataTable();
            table.destroy();
            setTimeout(() => {
              $('#datatableexample').DataTable({
                dom: 'lpftrip',
                pagingType: 'full_numbers',
                pageLength: 20,
                processing: true,
                lengthMenu: [20, 50, 100],
                order: [[1, 'desc']],
                language: {
                  processing: 'Procesando...',
                  search: 'Buscar:',
                  lengthMenu: 'Mostrar _MENU_ &eacute;l&eacute;ments',
                  info: 'Mostrando desde _START_ al _END_ de _TOTAL_ elementos',
                  infoEmpty: 'Mostrando ningún elemento.',
                  infoFiltered: '(filtrado _MAX_ elementos total)',
                  infoPostFix: '',
                  loadingRecords: 'Cargando registros...',
                  zeroRecords: 'No se encontraron registros',
                  emptyTable: 'No hay datos disponibles en la tabla',
                  paginate: {
                    first: 'Primero',
                    previous: 'Anterior',
                    next: 'Siguiente',
                    last: 'Último',
                  },
                  aria: {
                    sortAscending:
                      ': Activar para ordenar la tabla en orden ascendente',
                    sortDescending:
                      ': Activar para ordenar la tabla en orden descendente',
                  },
                },
              });
            }, 1);
            //this.ngOnInit();
          } else {
            setTimeout(() => {
              $('#datatableexample').DataTable({
                dom: 'lpftrip',
                pagingType: 'full_numbers',
                pageLength: 20,
                processing: true,
                lengthMenu: [20, 50, 100],
                order: [[1, 'desc']],
                language: {
                  processing: 'Procesando...',
                  search: 'Buscar:',
                  lengthMenu: 'Mostrar _MENU_ Elementos',
                  info: 'Mostrando desde _START_ al _END_ de _TOTAL_ elementos',
                  infoEmpty: 'Mostrando ningún elemento.',
                  infoFiltered: '(filtrado _MAX_ elementos total)',
                  infoPostFix: '',
                  loadingRecords: 'Cargando registros...',
                  zeroRecords: 'No se encontraron registros',
                  emptyTable: 'No hay datos disponibles en la tabla',
                  paginate: {
                    first: 'Primero',
                    previous: 'Anterior',
                    next: 'Siguiente',
                    last: 'Último',
                  },
                  aria: {
                    sortAscending:
                      ': Activar para ordenar la tabla en orden ascendente',
                    sortDescending:
                      ': Activar para ordenar la tabla en orden descendente',
                  },
                },
              });
            }, 1);
          }
        }
      },
      (error) => {
        var errorMessage = <any>error;
        // console.log(errorMessage);

        if (errorMessage != null) {
          this.statusGetUsers = 'error';
        }
      }
    );
  }

  getInvite() {
    this._route.params.subscribe((params) => {
      let id = params['id'];
      this.idInvite = id;
      this._inviteService.getInvite( this.token,id).subscribe(
        (response) => {
          if (!response.data) {
            this.statusGetUsers = 'error';
          } else {
            this.invite = response.data;
            // console.log(this.invite,206);
            
          }
        },
        (error) => {
          var errorMessage = <any>error;

          if (errorMessage != null) {
            this.statusGetUsers = 'error';
          }
        }
      );
    });
  }

  getAgents() {
    // console.log(220);
    
    this._userService.getUsers().subscribe(
      (response) => {
        // console.log(224,response);
        
        if (!response.users) {
          this.statusGetUsers = 'error';
        } else {
          this.users = response.users;
          // console.log(this.users);
          
        }
      },
      (error) => {
        var errorMessage = <any>error;

        if (errorMessage != null) {
          this.statusGetUsers = 'error';
        }
      }
    );
  }

  getAllProperties() {
    this._propertyService.getProperties(this.token).subscribe(
      (response) => {
        if (!response.data) {
          this.statusGetUsers = 'error';
        } else {
          this.propiedades = response.data.filter(p=>p.propertyVisibility=='1');
          // console.log(this.propiedades);
          
        }
      },
      (error) => {
        var errorMessage = <any>error;

        if (errorMessage != null) {
          this.statusGetUsers = 'error';
        }
      }
    );
  }

  onSubmit(invitationForm: any) {
    this.closebutton.nativeElement.click();
    console.log('enviando');

    this._invitePropertyService.saveInviteProp(this.token, this.newProperty).subscribe(
      (response) => {
        if (!response.data) {
          invitationForm.reset();
          this.statusEdit = 'error';
          this.showAlertEdit = true;
        } else {
          this.statusEdit = 'success';
          this.showAlertEdit = true;
          invitationForm.reset();
          this.getProperties(true);
        }
      },
      (error) => {
        var errorMessage = <any>error;

        if (errorMessage != null) {
          this.statusEdit = 'error';
          this.showAlertEdit = true;
        }
      }
    );
  }

  getPropertyToDelete(idUser: String) {
    this.propertyToDelete._id = idUser;
  }

  deleteProperty() {
    this.closebutton3.nativeElement.click();
    this._invitePropertyService
      .deleteInviteProp(this.token, this.propertyToDelete._id)
      .subscribe(
        (response) => {
          console.log(305,response);
          
          if (!response.data) {
            this.statusDelete = 'error';
            this.showAlertDelete = true;
          } else {
            this.statusDelete = 'success';
            this.showAlertDelete = true;

            this.getProperties(true);
          }
        },
        (error) => {
          var errorMessage = <any>error;
          if (errorMessage != null) {
            this.statusDelete = 'error';
            this.showAlertDelete = true;
          }
        }
      );
  }

  closeCreateS() {
    this.showAlertCreate = false;
  }
  closeEditS() {
    this.showAlertEdit = false;
  }
  closeDeleteS() {
    this.showAlertDelete = false;
  }
  updateInvite() {
    this._inviteService.updateInvite(this.token, this.invite).subscribe(
      (response) => {
        if (!response.data) {
          this.statusEdit = 'error';
          this.showAlertEdit = true;
        } else {
          this.statusEdit = 'success';
          this.showAlertEdit = true;
        }
      },
      (error) => {
        var errorMessage = <any>error;
        //console.log(errorMessage);

        if (errorMessage != null) {
          this.statusEdit = 'error';
          this.showAlertEdit = true;

          // console.log(357,errorMessage);
        }
      }
    );
  }
  sendMail() {
    this._inviteService
      .sendEmail(this.token, { obsjectId: this.idInvite })
      .subscribe(
        (response) => {
          if (!response.data) {
            this.statusCreate = 'error';
            this.showAlertCreate = true;
          } else {
            this.statusCreate = 'success';
            this.showAlertCreate = true;
          }
        },
        (error) => {
          var errorMessage = <any>error;
          //console.log(errorMessage);

          if (errorMessage != null) {
            this.statusCreate = 'error';
            this.showAlertCreate = true;

            // console.log(357,errorMessage);
          }
        }
      );
  }
}
