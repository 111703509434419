import {Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GLOBAL } from '../../../../../services/global';
import { Location } from '../models/location';

@Injectable()
export class LocationService{
    public url:string;
    public identity:any;
    public token:any;
 

    constructor(public _http: HttpClient){
        this.url = GLOBAL.url;
    }


    getLocations(token:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

                                       
        return this._http.get(this.url+'locations/', {headers: headers});
    }

    getLocation(object:any, token:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

                                       
        return this._http.get(this.url+'location/'+object, {headers: headers});
    }

    getLocationsByParent(object:any, token:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

                                       
        return this._http.get(this.url+'parent-location/'+object, {headers: headers});
    }

    getLocationStat(object:any, token:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

                                       
        return this._http.get(this.url+'location-count/'+object, {headers: headers});
    }

    saveLocation(object:any,token:any ): Observable<any>{
        
        let params = JSON.stringify(object);
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                       .set('Authorization',token);

        return this._http.post(this.url+'location',params, {headers:headers});
    }

    updateLocation(object:any,token:any ): Observable<any>{
        
        let params = JSON.stringify(object);
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                       .set('Authorization',token);

        return this._http.put(this.url+'location/'+object._id,params, {headers:headers});
    }

    deleteLocation(object:any,token:any ): Observable<any>{
        
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                        .set('Authorization',token);

        return this._http.delete(this.url+'location/'+object, {headers:headers});
    }

    activeLocation(object:any,token:any ): Observable<any>{
        
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                       .set('Authorization',token);

        return this._http.post(this.url+'location'+object._id, {headers:headers});
    }

}