import {Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GLOBAL } from '../../../services/global';
import { User } from '../models/user';

@Injectable()
export class UserService{
    public url:string;
    public identity:any;
    public token:any;
 

    constructor(public _http: HttpClient){
        this.url = GLOBAL.url;
    }

    register(user:any): Observable<any>{
        let params = JSON.stringify(user);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.post(this.url+'signup', params, {headers:headers});
    }

    signup(user:any, gettoken:any = null): Observable<any>{
        if(gettoken != null){
            user.gettoken = gettoken;
        }

        let params = JSON.stringify(user);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.post(this.url+'login',params, {headers:headers});
    }


    forgotPassword(user:any): Observable<any>{
        
        let params = JSON.stringify(user);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.post(this.url+'forgot-password',params, {headers:headers});
    }

    getIdentity(){
        let identity = JSON.parse(localStorage.getItem('identity'));

        if(identity != "undefined"){
            this.identity = identity;
        }else{
            this.identity = null;
        }

        return this.identity;
    }

    getToken(){
        let token = localStorage.getItem('token');

        if(token != undefined){
            this.token = token;
        }else{
            this.token = null;
        }

        return this.token;
    }

    

    forgotPasswordUpdate(user:any): Observable<any>{
        
        let params = JSON.stringify(user);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.post(this.url+'forgot-password-update',params, {headers:headers});
    }

    getUsers():Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',this.getToken());

                                       
        return this._http.get(this.url+'users/', {headers: headers});
    }

    getUserSaleman():Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',this.getToken());

                                       
        return this._http.get(this.url+'user-saleman/', {headers: headers});
    }

    inactiveUser(user:any): Observable<any>{
        
        let params = user;
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                    .set('Authorization',this.getToken());

        return this._http.delete(this.url+'user/'+params, {headers:headers});
    }

    getUser(user:any):Observable<any>{
        let params = user;
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',this.getToken());

                                       
        return this._http.get(this.url+'user/'+params, {headers: headers});
    }


    updateUser(user:any): Observable<any>{
        
        let params = JSON.stringify(user);
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                        .set('Authorization',this.getToken());

        return this._http.put(this.url+'user/'+user._id, params, {headers:headers});
    }
    

    makeFileRequest(url: string, params: Array<string>, files: Array<File>, token: string, name: string){
        return new Promise(function(resolve, reject){
            var formData: any = new FormData();
            var xhr = new XMLHttpRequest();

            for(var i = 0; i < files.length; i++){
                formData.append(name, files[i], files[i].name);
            }

            xhr.onreadystatechange = function(){
                if(xhr.readyState == 4){
                    if(xhr.status == 200){
                        resolve(JSON.parse(xhr.response));
                    }else{
                        reject(xhr.response);
                    }  
                }
            }

            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', token);
            xhr.send(formData);
        });
    }
}