import {Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GLOBAL } from '../../../services/global';

@Injectable()
export class PropertyAmenityService{
    public url:string;
    public identity:any;
    public token:any;
 

    constructor(public _http: HttpClient){
        this.url = GLOBAL.url;
    }


    getPropretyAmenities(token:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

                                       
        return this._http.get(this.url+'property-amenities/', {headers: headers});
    }

    getPropretyAmenity(object:any, token:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

                                       
        return this._http.get(this.url+'property-amenity/'+object, {headers: headers});
    }

    getPropretyAmenitybyProperty(object:any, token:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

                                       
        return this._http.get(this.url+'amenity-property/'+object, {headers: headers});
    }

    savePropretyAmenity(object:any,token:any ): Observable<any>{
        
        let params = JSON.stringify(object);
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                       .set('Authorization',token);

        return this._http.post(this.url+'property-amenity',params, {headers:headers});
    }

    updatePropretyAmenity(object:any,token:any ): Observable<any>{
        
        let params = JSON.stringify(object);
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                       .set('Authorization',token);

        return this._http.put(this.url+'property-amenity/'+object._id,params, {headers:headers});
    }

    deletePropretyAmenity(object:any,token:any ): Observable<any>{
        
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                        .set('Authorization',token);

        return this._http.delete(this.url+'property-amenity/'+object, {headers:headers});
    }

    activePropretyAmenity(object:any,token:any ): Observable<any>{
        
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                       .set('Authorization',token);

        return this._http.post(this.url+'property-amenity'+object._id, {headers:headers});
    }

}