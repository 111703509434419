import {Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GLOBAL } from '../../../services/global';
// import { Invitation } from '../models/invitation';

@Injectable()
export class InvitationService{
    public url:string;
    public identity:any;
    public token:any;
 

    constructor(public _http: HttpClient){
        this.url = GLOBAL.url;
    }


    // getRoles(token:any):Observable<any>{
    //     let headers = new HttpHeaders().set('Content-Type','application/json')
    //                                    .set('Authorization',token);

                                       
    //     return this._http.get(this.url+'roles/', {headers: headers});
    // }

    sendInvitation(invitation:any,token:any ): Observable<any>{
        
        let params = JSON.stringify(invitation);
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                       .set('Authorization',token);

        return this._http.post(this.url+'invitation',params, {headers:headers});
    }

}