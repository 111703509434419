import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { User } from '../../../../users/models/user';
import { UserService } from '../../../../users/services/user.service';
import { GLOBAL } from '../../../../../services/global';

@Component({
    selector: 'register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    providers: [UserService]
})

export class RegisterComponent implements OnInit{
    public title: string;
    public user: User;
    public url: String;
    public identity;
    public token;
    public status: string;
    public statusToken: string;
    public statusPassword: string;

    public PasswordConfirm: string;

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _userService: UserService
    ){
        this.title = 'Registro';
        this.url = GLOBAL.url;
        this.PasswordConfirm = '';
        this.user = new User("","","","","","",false,"","","","","");
        this.identity = this._userService.getIdentity();
        this.token = this._userService.getToken();
    }

    ngOnInit(){

    }

    onSubmit(registerForm:any){
        console.log(this.PasswordConfirm)

        if(this.PasswordConfirm != this.user.password){
            this.statusPassword = 'error';
        }else{
            this._userService.register(this.user).subscribe(
            response => {
                this.identity = response.data;
                console.log(response);
                if(!this.identity || !this.identity.email){
                    this.status = 'error';
                }else{
                    console.log('cambia')
                    this._router.navigate(['/']);
        
                }
        
            },error => {
                var errorMessage = <any>error;
                console.log(errorMessage);
        
                if(errorMessage != null){
                    this.status = 'error';
                }
            }
            );
        }
        
    
    }

    


}
