import {Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GLOBAL } from '../../../services/global';

@Injectable()
export class CommentService{
    public url:string;
    public identity:any;
    public token:any;
 

    constructor(public _http: HttpClient){
        this.url = GLOBAL.url;
    }


    getComments(token:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

                                       
        return this._http.get(this.url+'comments/', {headers: headers});
    }

    getComment(object:any, token:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

                                       
        return this._http.get(this.url+'comment/'+object, {headers: headers});
    }

    getCommentbyProperty(object:any, token:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json')
                                       .set('Authorization',token);

                                       
        return this._http.get(this.url+'comment-property/'+object, {headers: headers});
    }

    saveComment(object:any,token:any ): Observable<any>{
        
        let params = JSON.stringify(object);
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                       .set('Authorization',token);

        return this._http.post(this.url+'comment',params, {headers:headers});
    }

    updateComment(object:any,token:any ): Observable<any>{
        
        let params = JSON.stringify(object);
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                       .set('Authorization',token);

        return this._http.put(this.url+'comment/'+object._id,params, {headers:headers});
    }

    deleteComment(object:any,token:any ): Observable<any>{
        
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                        .set('Authorization',token);

        return this._http.delete(this.url+'comment/'+object, {headers:headers});
    }

    activeComment(object:any,token:any ): Observable<any>{
        
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
                                       .set('Authorization',token);

        return this._http.post(this.url+'comment'+object._id, {headers:headers});
    }

}